export default {
  install: async (app) => {
    const features = {};
    const configVar = app.config.globalProperties.$configVars;

    const flags = [
      'FEATURE_FLAG_WORK_ORDER_PRIORITY_FRONTEND',
      'FEATURE_FLAG_WORK_ORDER_DUPLICATE_FRONTEND',
      'FEATURE_FLAG_AI_COMMENT_ENHANCEMENT',
      'FEATURE_FLAG_AI_WORK_ORDER_SUGGESTIONS',
      'FEATURE_FLAG_PLANNED_WORK_TEMPLATE_ASSET_TYPE',
    ];

    flags.forEach((flag) => {
      if (configVar[flag]) {
        // Remove FEATURE_FLAG_ prefix
        const propertyName = flag.substring(13);

        features[propertyName] = configVar[flag].value ?? configVar[flag].default_value ?? features[propertyName];
      }
    });

    // eslint-disable-next-line no-param-reassign
    app.config.globalProperties.$featureFlag = features;
  },
};
