/* eslint-disable max-len */
const EN = {
  common: {
    demoMode: 'DEMO MODE',
    currency: 'Currency',
    tree: 'Tree',
    search: 'Search',
    searching: 'Searching',
    total: 'Total',
    select: 'Select',
    selectable: 'Selectable',
    selected: 'Selected',
    filter: 'Filter',
    enabled: 'Enabled',
    dateCreated: 'Created',
    dateUpdated: 'Updated',
    due: 'Due:',
    retry: 'Retry',
    daysSuffix: 'd',
    hoursSuffix: 'h',
    minutesSuffix: 'm',
    secondsSuffix: 's',
    daysLongSuffix: 'days',
    hoursLongSuffix: 'hours',
    minutesLongSuffix: 'minutes',
    secondsLongSuffix: 'seconds',
    logoAltText: 'Logo',
    click: 'Click',
    here: 'here',
    by: 'by',
    is: 'is',
    are: 'are',
    returnToList: 'to return to list',
    systemUser: 'SYSTEM',
    currentUser: 'Current User',
    unassigned: 'Unassigned',
    notFound: 'Not Found',
    searchPlaceholder: 'Search...',
    searchResults: 'Search Results',
    notApplicable: 'N/A',
    importantPrefix: 'Important:',
    next: 'Next',
    back: 'Back',
    date: 'Date',
    event: 'Event',
    description: 'Description',
    causer: 'Causer',
    have: 'have',
    has: 'has',
    cost: 'cost',
    costs: 'costs',
    user: 'User',
    yes: 'Yes',
    no: 'No',
    showMore: 'Show {number} more',
    closed: 'Closed',
    copy: 'Copy',
    view: 'View',
    download: 'Download',
    preview: 'Preview',
    previewNotAvailable: 'Preview Not Available',
    change: 'Change',
    location: 'Location',
    locations: 'Locations',
    noResultsFound: 'No results found',
    stepOf: 'Step {step} of {steps}',
    confirm: 'Confirm',
    filters: {
      empty: 'Empty',
      notEmpty: 'Not Empty',
    },
    crud: {
      updated: 'updated',
      created: 'created',
      deleted: 'deleted',
    },
    daysOfTheWeek: {
      monday: 'Monday',
      tuesday: 'Tuesday',
      wednesday: 'Wednesday',
      thursday: 'Thursday',
      friday: 'Friday',
      saturday: 'Saturday',
      sunday: 'Sunday',
    },
    forms: {
      day: 'Day',
      time: 'Time',
      pending: 'Pending',
      name: 'Name',
      approve: 'Approve',
      requiredField: ', Required',
      approving: 'Approving...',
      cancelling: 'Cancelling...',
      checking: 'Checking...',
      loading: 'Loading...',
      saving: 'Saving...',
      submitting: 'Submitting...',
      deleting: 'Deleting...',
      save: 'Save',
      submit: 'Submit',
      resubmit: 'Resubmit',
      cancel: 'Cancel',
      close: 'Close',
      add: 'Add',
      edit: 'Edit',
      delete: 'Delete',
      deleteConfirmation: 'Are you sure you want to delete this {entityType}?',
      reject: 'Reject',
      actions: 'Actions',
      export: 'Export',
      all: 'All',
      yes: 'Yes',
      no: 'No',
      expandAll: 'Expand All',
      collapseAll: 'Collapse All',
      unknown: '<unknown>',
      none: '<none>',
      removed: '<removed>',
      noneLabel: 'None',
      attachments: 'Attachments',
      addDocument: 'Add Document',
      noOptionsLeft: 'There are no options left',
      emptyOption: 'Please select',
      anyOption: 'Any',
      selectAnOption: 'Please select an option',
      selectUser: 'Select a User',
      clear: 'Clear',
      search: 'Search {label}',
      errorTitle: 'Error',
      successTitle: 'Success',
      redirecting: 'Redirecting ...',
      from: 'From',
      to: 'To',
      summary: 'Summary',
      startTime: 'Start Time',
      endTime: 'End Time',
      date: 'Date',
      description: 'Description',
      reference: 'Reference',
      dateTime: 'Date/Time',
      notApplicable: 'N/A',
      editFieldModalError: 'An error occurred:',
      editFieldModalTokens: 'Available Tokens',
      uploaded: 'Uploaded',
      message: 'Message',
      create: 'Create',
      optional: 'Optional',
      validation: {
        dateAlreadyExcluded: '{field} has already been excluded',
        required: '{field} is required.',
        timeFormat: '{field} must be input as HH:mm.',
        sameAs: '{field} must be the same as {sameField}.',
        email: '{field} must be a valid email address.',
        minLength: '{field} must be at least {min} characters long',
        minValue: '{field} must be greater than or equal to {min}.',
        maxValue: '{field} must be less than or equal to {max}.',
        maxDecimalPrecision: '{field} must only have a maximum of {max} decimal places.',
        between: '{field} must be between {min} and {max}.',
        integer: '{field} must be an integer.',
        isAfter: '{field} must be after {isAfterField}.',
        isBefore: '{field} must be before {isBeforeField}.',
        decimal: '{field} must be a number.',
        etaAfterDue: 'You do not have permission to set the ETA date past the due by date.',
        unique: '{field} must not have duplicate values.',
        minGeofencePoints: '{field} must have a minimum of 3 points',
        maxAreaGeofence: 'The area of the geofence must be less than 1,000,000m\u00B2',
        firstFieldMoreThanOrEqualToSecondField:
          '{field1} must be greater than or equal to {field2}',
        maxNumberOfFiles: 'Maximum of {files} {field} allowed.',
      },
    },

    session: {
      sessionExpired: 'Your session has expired. Please log in again',
    },

    unsaved: 'You have some unsaved content. Are you sure you want to leave?',

    errors: {
      requestFailed: 'Request Failed',
    },

    serviceWorker: {
      appNeedsRefreshTitle: 'App Update Available',
      appNeedsRefreshDescription: 'A new version of the app is available, please save your work and reload the page.',
      appIsOfflineTitle: 'Offline Mode',
      appIsOfflineDescription: 'No internet connection found. App is running in offline mode.',
    }
  },
  utility: {
    isEntityOverdue: {
      dueToday: 'Due in {quantity}{unit}',
      overdue: 'Overdue',
      minute: 'm',
      hour: 'h',
    },
  },
  router: {
    routes: {
      workflows: '/workflow',
      workOrder: '/work-order',
      workOrderCalendar: '/work-order-calendar',
      dashboard: '/',
      dashboards: '/dashboards',
      report: '/reports',
      documentTypes: 'document-types',
      documents: '/documents',
      recurringWorkOrder: '/recurring-work-order',
      serviceRequests: '/service-requests',
      costRequests: '/cost-requests',
      settings: '/settings',
      users: 'users',
      sites: '/sites',
      contractor: '/contractor',
      costCodes: 'cost-codes',
      audits: 'audits',
      workOrderTypes: 'work-order-types',
      servicePriorities: 'service-priorities',
      suppliers: 'suppliers',
      roles: 'roles',
      notifications: 'notifications',
      assetCategories: 'asset-categories',
      serviceTypes: 'service-types',
      assetSuppliers: 'asset-suppliers',
      assetModels: 'asset-models',
      budgets: 'budgets',
      passwordChange: '/password-change',
      costProposals: '/cost-proposals',
      invoices: '/invoices',
      requestPasswordReset: '/request-password-reset',
      login: '/login',
      noPermission: '/no-permission',
      landlords: 'landlords',
      tags: 'tags',
      tagTypes: 'tag-types',
      financialPeriods: 'financial-periods',
      maintainableEntity: '/maintainable-entity',
      view: 'view',
      create: 'create',
      organisations: 'organisations',
    },

    viewAll: 'View All',
    create: 'Create',
  },

  services: {
    entities: {
      serviceType: 'Service Type',
      serviceTypePlural: 'Service Types',
      documentTypePlural: 'Document Types',
      audit: 'Audit',
      auditPlural: 'Audits',
      budget: 'Budget',
      budgetPlural: 'Budgets',
      building: 'Building',
      contractor: 'Contractor',
      contractorPlural: 'Contractors',
      costCode: 'Cost Code',
      maintainableEntity: 'Maintainable Entities',
      buildingType: 'Building Types',
      conditionGrades: 'Condition Grades',
      costCodePlural: 'Cost Codes',
      costRequest: 'Quote Request',
      costProposal: 'Quote',
      countryPlural: 'Countries',
      expense: 'Expense',
      expensePlural: 'Expenses',
      floor: 'Floor',
      landlord: 'Landlord',
      landlordPlural: 'Landlords',
      notification: 'Notification',
      notificationPlural: 'Notifications',
      recurringWorkOrder: 'Recurring Work Order',
      requestForService: 'Request for Service',
      requestType: 'Request Type',
      role: 'Role',
      rolePlural: 'Roles',
      site: 'Site',
      sitePlural: 'Sites',
      siteTypePlural: 'Site Types',
      space: 'Space',
      supplier: 'Supplier',
      supplierPlural: 'Suppliers',
      user: 'User',
      workOrder: 'Work Order',
      workOrderType: 'Work Order Type',
      workOrderTypePlural: 'Work Order Types',
      workOrderRequestTypePlural: 'Work Order Request Types',
      workOrderStatesPlural: 'Work Order States',
      servicePriority: 'Contractor SLA',
      servicePriorityPlural: 'Contractor SLAs',
      workOrderAvailableServices: 'Work Order Available Services',
    },
    genericErrors: {
      create: 'An error occurred while creating this {type}. Please try again',
      read: 'An error occurred while fetching this {type}. Please try again',
      update: 'An error occurred while updating this {type}. Please try again',
      delete: 'An error occurred while deleting this {type}. Please try again',
    },
    loginError: 'An error occurred while logging in. Please try again',
    refreshTokenError: 'An error occurred while refreshing your access token. Please try again',
  },

  views: {
    InvoiceGroups: {
      title: 'Invoice Groups',
      add: 'Add Invoice Group',
      name: 'Invoice Group Name',
      invoiceAddress: 'Invoice Address',
      invoiceInstructions: 'Invoice Instructions',
      standaloneDefaultRef: 'Standalone Invoice Default Reference',
      standaloneDefaultDesc: 'Standalone Invoice Default Description',
      consolidatedDefaultRef: 'Consolidated Invoice Default Reference',
      consolidatedDefaultDesc: 'Consolidated Invoice Default Description',
    },
    RecentPartOrders: {
      noRecentOrders: 'You have no recent orders',
    },
    PartsOrder: {
      emptyPage: 'Home',
      leaveGuard:
        "You have items in your basket. If you leave the page, you will lose it's contents. Are you sure you want to leave?",
      title: 'Parts Ordering',
      deliveryNoteEmpty: 'Delivery time not defined by supplier.',
      includingDelivery: 'including delivery',
      includingDeliveryWithAmount: 'including {{amount}} delivery',
      exVAT: 'ex. VAT',
      modals: {
        amendOrderModal: {
          title: 'Amend Order',
          unitPrice: 'Unit Price',
          totalDelivery: 'Total Delivery',
          quantity: 'Quantity',
          subtotal: 'Subtotal ({items} items)',
          delivery: 'Delivery',
          addSurchargeButton: 'Add Surcharge',
          amendmentReason: 'Amendment Reason',
          actionButton: 'Amend Order',
          summaryString: '{items} items: Total (including delivery)',
          canNotRemoveSingleItem: 'You may not remove items from the order if there is only one remaining part. Please cancel order instead.',
          unitDeliveryFee: 'Unit Delivery Fee',
          supplierDeliveryFee: 'Supplier Delivery Fee',
          surchargeFees: 'Surcharges',
          totalPrice: 'Total (ex. Tax)',
        },
        addSurchargeModal: {
          description: 'Surcharge Description',
          price: 'Unit Price',
          actionButton: 'Add Surcharge',
        },
        dispatchModal: {
          title: 'Dispatch Items',
          quantity: 'Quantity to dispatch',
          deliveryDetails: 'Delivery Details',
          dispatchItems: 'Dispatch {items} item(s)',
        },
        markDeliveredModal: {
          title: 'Mark Delivered',
          quantity: 'Quantity delivered',
          comments: 'Comments',
          markDelivered: 'Mark {items} Items Delivered',
        },
      },
    },
    PartsOrderPart: {
      Basket: {
        title: 'Your Basket',
        siteFieldPrompt: 'Site to be delivered to',
        empty: 'Empty',
        addToBasket: 'Add to Basket',
        createOrder: 'Create Order Request',
        orderConfirmation: 'Order Confirmation',
        thanksForOrder: 'Thank you for your orders',
        goBack: 'Go Back',
        close: 'Close',
        confirmOrder: 'Confirm Order',
        creatingOrder: 'Creating orders...',
        content: {
          unitPriceLabel: 'Unit Price',
          deliveryLabel: 'Total Delivery',
          unitDeliveryFeeLabel: 'Unit Delivery Fee',
          contractorDeliveryLabel: 'Supplier Delivery Fee',
          subtotalLabel: 'Subtotal (ex. Tax)',
          itemsLabel: 'items',
          deliveryInstructionsLabel: 'Delivery instructions:',
          totalDeliveryLabel: 'Total Delivery (ex. Tax)',
          orderSummary: '{items} items from {suppliers} suppliers: Total (including delivery)',
          pleaseConfirmSummary:
            'Please confirm your order request for {items} items from {suppliers} suppliers with a total of {price} (including delivery).',
          emptyBasket: 'Your basket is empty.',
        },
        errors: {
          limitReached: 'Failed adding part to basket. Maximum basket limit reached.',
          differentCurrency:
            'Failed adding part to basket. This item is using a different currency than {basketCurrency}.',
        },
      },
      actions: {
        manageCategories: 'Manage Categories',
        createPart: 'Add Part',
        editPart: 'Edit Part',
      },
      sortOptions: {
        name: 'Name',
        price: 'Price',
        created_at: 'Created at',
        enabled: 'Enabled',
        supplier: 'Supplier',
        barcode: 'Barcode',
        partNumber: 'Part Number',
      },
      filters: {
        name: 'Name',
        enabled: 'Enabled',
        supplier: 'Supplier',
        barcode: 'Barcode',
        partNumber: 'Part Number',
        sites: 'Sites',
        categories: 'Categories',
      },
      fields: {
        name: 'Name',
        price: 'Price',
        created_at: 'Created at',
        enabled: 'Enabled',
        supplier: 'Supplier',
        description: 'Description',
        partNumber: 'Part Number',
        barcode: 'Barcode',
        delivery: 'Delivery Fee',
        category: 'Category',
        categories: 'Manage Categories',
        images: 'Images',
        supplierDeliveryFee: 'Supplier Delivery Fee',
        supplierDeliveryTime: 'Supplier Delivery Time',
        taxRate: 'Tax Rate',
        priceExTax: 'Price (ex. Tax)',
      },
      sections: {
        Details: {
          paneId: 'details',
          title: 'Details',
        },
        Categories: {
          paneId: 'categories',
          title: 'Categories',
        },
        Images: {
          paneId: 'images',
          title: 'Images',
        },
        Sites: {
          paneId: 'sites',
          title: 'Sites',
        },
      },
      imageUpload: {
        save: 'Upload Images',
        saving: 'Saving...',
      },
      Display: {
        deleteConfirm: 'Are you sure you want to delete this Part?',
      },
      viewAllParts: 'View All Parts',
      showCategories: 'Show Categories',
    },
    PartsOrderCategory: {
      fields: {
        name: 'Name',
        enabled: 'Enabled',
        created_at: 'Created at',
        icon: 'Icon',
        parts: 'Manage Parts',
      },
      filters: {
        parent_id: 'Parent Category',
        parentIdOptions: {
          all: 'All',
          topLevel: 'Top Level Only',
        },
      },
      sortOptions: {
        name: 'Name',
        dateCreated: 'Created at',
      },
      buttons: {
        add: 'Add Category',
        manageParts: 'Manage Parts',
      },
      sections: {
        Details: {
          paneId: 'details',
          title: 'Details',
        },
        Parts: {
          paneId: 'parts',
          title: 'Parts',
        },
      },
      Display: {
        delete: 'Delete',
        deleteConfirm: 'Are you sure you want to delete this category?',
        enabledStates: {
          enabled: 'Enabled',
          disabled: 'Disabled',
        },
      },
      AddModal: {
        parentLabel: 'Parent Category',
        iconLabel: 'Icon',
        nameLabel: 'Name',
      },
    },
    accountSettings: {
      details: 'Details',
      roleSpecificDetails: 'Role Specific Details',
    },
    releasePage: {
      featuresAndEnhancements: 'Features and Enhancements',
      fixes: 'Fixes',
      currentRelease: 'Current Version',
      noReleaseNotesForVersion: 'Release notes are pending for this version.',
    },
    bulkCostApproval: {
      selectContractorPlaceHolder: 'Please select a contractor',
      selectOrganisationPlaceHolder: 'Please select an organisation',
      markedForStandalone: 'Work Orders marked for Standalone Invoice',
      assignToStandalone: 'Assign to Standalone Invoice',
      assignToConsolidated: 'Assign to Consolidated Invoice',
      consolidatedInvoice: 'Consolidated Invoice',
      standaloneInvoice: 'Standalone Invoice',
      numberSelected: '{number} work orders selected',
      table: {
        costCode: 'Cost Code',
        budget: 'Budget',
        workOrderId: 'WO #',
        workOrder: 'Work Order',
        subTotal: 'Subtotal',
        consolidated: 'Consolidated',
        workOrderType: 'WO Type',
        site: 'Site',
        dateSubmitted: 'Date Submitted',
        autoSubmitCosts: 'Auto Submit Costs',
      },
      modal: {
        consolidatedInvoiceInformation:
          'This action will approve {count} work order(s) to a single Consolidated Invoice.',
        invoiceWarning: 'Note: {count} is currently marked as {otherInvoiceType}:',
        invoiceWarning_plural: 'Note: {count} are currently marked as {otherInvoiceType}:',
        consolidatedInvoiceConfirmation:
          'Do you wish to approve the costs associated with these work orders to a single Consolidated Invoice?',
        standaloneInvoiceInformation:
          'This action will approve {numberToApprove} work order(s) and create a standalone invoice for each.',
        standaloneInvoiceConfirmation:
          'Do you wish to approve the costs associated to these work orders and create a standalone invoice for each?',
        standaloneInvoice: 'Standalone Invoicing',
      },
    },
    flowchart: {
      StateModal: {
        state: 'State',
        title: 'Workflow State',
        save: 'Save',
        saving: 'Saving',
        bgColor: 'Background Color',
      },
      TransitionModal: {
        transition: 'Transition',
        title: 'Workflow Transition',
        save: 'Save',
        saving: 'Saving',
      },
    },
    external: {
      ExternalWorkOrderForm: {
        info: 'For urgent requests, please call the helpdesk on ext. 123',
        fullName: 'Full Name',
        email: 'Email Address',
        site: 'Site',
        location: 'Location',
        summary: 'Summary',
        description: 'Description',
        send: 'Send',
        successMsg: 'Thanks for submitting your Work Order.',
        formLoadError: 'An error occurred while loading this form. Please try again.',
        workOrderFormRequestType: 'Request Type',
        asset: 'Asset',
        assetDescription: '(optional)',
      },
    },
    manageWorkOrderForm: {
      ManageWorkOrderForm: {
        title: 'Manage Work Order Forms',
        workOrderRequestType: 'Work Order Request Type',
        allowLocationSelection: 'Allow Location Selection',
        forceLocationSelection: 'Force Location Selection',
        notifySubmitter: 'Notify Submitter?',
        notificationBody: 'Notification Body',
        notificationSubject: 'Notification Subject',
        allowAttachments: 'Allow Attachments',
        allowAssets: 'Allow Assets',
        allowPrioritySelection: 'Allow Priority Selection',
        allowPrioritySelectionHelpText: 'Note: Will only show on the form if enabled in the system configuration',
        allowRequestTypeSelection: 'Allow Request Type Selection',
        workOrderFormRequestType: 'Default Request Type',
        workOrderFormRequestTypes: 'Available Request Types',
        logoUrl: 'Logo URL',
        instructions: 'Instructions',
        customCSS: 'Custom CSS',
        enabled: 'Enabled',
        add: 'Add Work Order Form',
        name: 'Name',
        genericError: 'There was an error with this Work Order Form',
        accessToken: 'Access Token',
        formLink: 'Form Link',
        viewLink: 'View Link',
        qrPoster: 'QR Poster',
        qrPosterView: 'View QR Poster',
        qrImage: 'QR Image',
        qrImageView: 'View QR Image',
      },
    },
    reports: {
      ConsolidatedInvoice: {
        selectFieldsToSeeReportMessage:
          'You must select a contractor and period in order to view the report.',
        emptyFinancialPeriodDivisionOption: 'Period',
        emptyContractorOption: 'Contractor',
        print: 'Print',
        invoiceTo: 'Invoice To',
        invoiceDetails: 'Invoice Details',
        approvalStatus: 'Approval Status',
        approvalStatusApproved: 'Approved',
        approvalStatusPending: 'Pending Approval',
        reference: 'Reference',
        contractor: 'Contractor',
        account: 'Account',
        billablePeriod: 'Billable Period',
        totalExTax: 'Total (ex. Tax)',
        instructions: 'Instructions',
        InvoiceTable: {
          workOrderId: 'WO #',
          invoiceId: 'Inv #',
          site: 'Site',
          description: 'Description',
          costCode: 'Cost Code',
          workOrderStatus: 'WO Status',
          workOrderCompletedDate: 'WO Completed',
          totalExTax: 'Total (ex. Tax)',
          invoiceApprovedDate: 'Date Approved',
        },
      },
      WorkOrderDemand: {
        WorkOrdersCreated: 'Work Orders Created & Current Status',
        WorkOrderByType: 'Work Orders by Type',
        WorkOrderByPriority: 'Work Orders by Contractor SLA',
        ContractorUtilisation: 'Work Orders By Contractor',
        SiteUtilisation: 'Work Orders By Site',
        LocationUtilisation: 'Work Orders By Location',
        ServiceTypeUtilisation: 'Work Orders By Service Type',
        WorkOrders: 'Work Orders',
        WorkOrdersViewList: 'View In List',
        WorkOrdersExportCsv: 'Export As CSV',
        clearFilters: 'Clear Filters',
        WorkOrdersFields: {
          id: 'WO #',
          created_at: 'Date Created',
          summary: 'Summary',
          site: 'Site',
          contractor: 'Contractor',
          invoicedTotal: 'Invoiced (ex. Tax)',
          location: 'Location',
          serviceType: 'Service Type',
          invoiced: 'Invoiced',
          count: 'Count',
        },
        countBar: {
          created: 'Created',
          open: 'Open',
          closed: 'Closed',
          invoiced: 'Invoiced',
          aov: 'AOV',
        },
      },
      SLAPerformance: {
        timeToRespond: 'Time To Respond SLA',
        timeToAttend: 'Time To Attend SLA',
        timeToFix: 'Time To Fix SLA',
        SlaPerformanceByPriority: 'SLA Performance By Contractor SLA',
      },
    },
    dashboards: {
      Dashboard: {
        pivotTableConfiguration: {
          metrics: 'Metrics',
          invoiced: 'Invoiced',
          endPoint: 'Endpoint',
          rowLink: 'Row Link',
          refreshValue: 'Refresh Value',
          dimension: 'Dimension',
          dimensionTitle: 'Title',
          dimensionGroupBy: 'Group By',
          dimenstionRelatedDataKey: 'Related Data Key',
        },
        title: 'Dashboard',
        save: 'Save',
        deleteWidgetConfirm: 'Are you sure you want to delete this widget?',
        deleteDashboardConfirm: 'Are you sure you want to delete this dashboard?',
        saving: 'Saving',
        addWidget: 'Add Widget',
        type: 'Type',
        frontend_config: 'Config',
        widgetsTitle: 'Widget',
        add: 'Add Dashboard',
        updated: 'Updated',
        roles: 'Roles',
        financialWidgets: 'Financial Widgets',
        countWidgets: 'Count Widgets',
        pivotWidgets: 'Pivot Widgets',
        spendByCostType: 'Current Spend',
        spendByPeriod: 'Spend By Period',
        name: 'Name',
        configTitle: 'Title',
        icon: 'Icon',
        iconColor: 'Icon Color',
        endpoint: 'Endpoint',
        yes: 'Yes',
        refreshInterval: 'Refresh Interval',
        countTextColor: 'Count Text Color',
        link: {
          path: 'Path',
          internal: 'Internal?',
          openInNewWindow: 'Open In New Window?',
        },
        sortOptions: {
          name: 'Name',
        },
        availableFilters: {
          label: 'Available Filters (optional)',
          site: 'Site',
          contractor: 'Contractor',
          owner: 'Owner',
        },
        availableTokensHelp: 'Available tokens: ',
      },
    },
    documents: {
      DocumentList: {
        sortOptions: {
          name: 'Name',
        },
        filters: {
          name: 'Name',
          type: 'Type',
          validFrom: 'Valid From',
          validUntil: 'Valid Until',
          location: 'Location',
          workOrder: 'Work Order',
          contractor: 'Contractor',
          associatedName: 'Search entity',
          associatedEntity: 'Related to entity',
          associatedEntityOptions: {
            contractor: 'Contractor',
            workOrder: 'Work Order',
            site: 'Site',
            building: 'Building',
            floor: 'Floor',
            system: 'System',
            space: 'Space',
            asset: 'Asset',
          },
        },
        fields: {
          name: 'Name',
          type: 'Type',
          validFrom: 'Valid From',
          validUntil: 'Valid Until',
          associations: 'Associations',
          created_at: 'Created At',
          updated_at: 'Updated At',
        },
      },
    },
    documentTypes: {
      DocumentType: {
        applicableEntities: 'Associated Entities',
        restrictByRole: 'Restrict By Role',
        roles: 'Associated Roles',
        add: 'Add Document Type',
        title: 'Document Type',
        name: 'Name',
        compliance: 'Compliance',
        isValidityRequired: 'Validity Period Required',
        enabled: 'Enabled',
        validityRequiredHelpText: 'Cannot be modified after creation',
        complianceHelpText:
          'Only allow compliance documents to be associated with this document type. ' +
          'Cannot be modified after creation.',
        sortOptions: {
          name: 'Name',
        },
        filters: {
          name: 'Name',
          compliance: 'Compliance',
          applicableEntity: 'Associated Entity',
          isValidityRequired: 'Validity Period Required',
          applicableEntities: {
            contractor: 'Contractor',
            building: 'Building',
            site: 'Site',
            floor: 'Floor',
            space: 'Space',
            system: 'System',
            asset: 'Asset',
            workOrder: 'Work Order',
          },
        },
      },
    },
    Home: {
      title: 'Expansive FM Dashboard',
    },
    NoPermission: {
      title: 'No Permission',
      body: 'You do not have permission to view this page.',
    },
    PageNotFound: {
      title: 'Page Not Found',
      body1: 'Click',
      body2: 'here',
      body3: 'to return to homepage.',
    },
    auth: {
      ResetPassword: {
        backToLogin: 'Back to Login',
        email: 'Email',
        passwordConfirmation: 'Password confirmation',
      },
      DemoLogin: {
        title: 'Demo Login',
      },
    },
    availableServices: {
      title: 'Contractor SLA',
      alternativeTitle: 'Service',
      none: 'None',
      showAll: 'Show All',
      showMore: 'Show {number} more',
      servicesAvailable: '{service} ({count} service available)',
      servicesAvailable_plural: '{service} ({count} services available)',
      noresult:
        "There are no services available, or you don't have the required permissions to see such services.",
      calloutFee: 'Callout Fee: {calloutFee}',
      calloutFeeOutOfHours: '({outOfHoursCalloutFee} out of hours)',
      hourlyRate: 'Hourly Rate: {hourlyRate}',
      hourlyRateOutOfHours: '({outOfHoursHourlyRate} out of hours)',
      fixedFee: 'Fixed Fee: {fixedFee}',
      perAssetCalloutFee: 'Callout Fee: {calloutFee}',
      perAssetFee: 'Per Asset Fee: {perAssetFee}',
      noBillingType: 'This service does not have a billing type.',
      noRateCardAdded: 'This service does not have a rate card.',
      noHoursAdded: 'No hours have been added',
      workingHours: {
        title: 'Working Hours',
        withOutOfHours: '{start} - {finish} today, out of hours service available.',
        noOutOfHours: '{start} - {finish} today, no out of hours service available.',
        outOfHoursOnly: 'Out of hours service available',
        closed: 'Closed',

        standardHours: 'Standard Hours: {start} - {finish}',
        standardHoursClosed: 'Standard Hours: Closed',
        outofHoursAvailable: 'Out of Hours: Available',
        outofHoursUnvailable: 'Out of Hours: Unavailable',
      },
    },
    settings: {
      description: 'Please select the settings you wish to view:',
      AssetCategories: {
        title: 'Asset Categories',
        subtitle: 'Manage asset categories',
      },
      AssetSuppliers: {
        title: 'Asset Suppliers',
        subtitle: 'Manage asset suppliers.',
      },
      PartsOrderCategories: {
        title: 'Parts Order Categories',
        subtitle: 'Manage categories of orderable parts.',
      },
      PartsOrderParts: {
        title: 'Parts',
        subtitle: 'Manage available orderable parts.',
      },
      Users: {
        title: 'Users',
        subtitle: 'Manage users and access levels.',
      },
      WorkOrderCreationRules: {
        title: 'Work Order Creation Rules',
        subtitle: 'Manage rules applied on work order creation.',
      },
      Dashboards: {
        title: 'Dashboards',
        subtitle: 'Manage dashboards.',
      },
      Roles: {
        title: 'Roles',
        subtitle: 'Manage roles.',
      },
      WorkOrderFormManage: {
        title: 'External Work Order Forms',
        subtitle: 'Manage external work order forms',
      },
      WorkOrderFormRequestTypeManage: {
        title: 'External Work Order Form Request Types',
        subtitle: 'Manage external work order form request types',
      },
      ServiceTypes: {
        title: 'Service Types',
        subtitle: 'Manage service types.',
      },
      Organisations: {
        title: 'Organisations',
        subtitle: 'Manage organisations.',
      },
      Budgets: {
        title: 'Budgets',
        subtitle: 'Manage budgets.',
      },
      BudgetTypes: {
        title: 'Budget Types',
        subtitle: 'Manage budgets types.',
      },
      CostCodes: {
        title: 'Cost Codes',
        subtitle: 'Manage cost codes.',
      },
      DocumentTypes: {
        title: 'Document Types',
        subtitle: 'Manage document types.',
      },
      Landlords: {
        title: 'Landlords',
        subtitle: 'Manage landlords.',
      },
      WorkOrderTypes: {
        title: 'Work Order Types',
        subtitle: 'Manage work order types.',
      },
      ServicePriorities: {
        title: 'Contractor SLAs',
        subtitle: 'Manage contractor SLAs.',
      },
      Suppliers: {
        title: 'Suppliers',
        subtitle: 'Manage suppliers',
      },
      StartWorkChecks: {
        title: 'Start Work Checks',
        subtitle: 'Manage start work check lists'
      },
      Notifications: {
        title: 'Notifications',
        subtitle: 'Manage notification schemes.',
      },
      Tags: {
        title: 'Tags',
        modalTitle: 'Manage Tags',
        subtitle: 'Manage tags.',
      },
      TagTypes: {
        title: 'Tag Types',
        subtitle: 'Manage tag types.',
      },
      FinancialPeriods: {
        title: 'Financial Periods',
        subtitle: 'Manage financial periods.',
      },
      Audits: {
        title: 'Audit Log',
        subtitle: 'View audit log.',
      },
      Workflows: {
        title: 'Workflows',
        subtitle: 'Manage workflows.',
      },
      InvoiceNamingConventions: {
        title: 'Invoice Naming Conventions',
        subtitle: 'Manage conventions for invoice references and descriptions.',
        invoiceType: 'Invoice Type',
        defaultReference: 'Default Reference',
        defaultDescription: 'Default Description',
        consolidated: 'Consolidated',
        standalone: 'Standalone',
      },
      InvoiceGroups: {
        title: 'Invoice Groups',
        subtitle: 'Manage Invoice Groups for invoice references and descriptions.',
      },
      Hazards: {
        title: 'Hazards',
        subtitle: 'Manage hazards.',
      },
      AssetManufacturers: {
        title: 'Asset Manufacturers',
        subtitle: 'Manage asset manufacturers.',
      },
      AssetTypes: {
        title: 'Asset Types',
        subtitle: 'Manage asset types.',
      },
      AssetModels: {
        title: 'Asset Models',
        subtitle: 'Manage asset models.',
        unknownManufacturer: 'Unknown Manufacturer',
      },
      workPermits: {
        title: 'Permit to Work Templates',
        subtitle: "Manage permit to work templates",
      }
    },
    landlords: {
      title: 'Landlords',
      add: 'Add Landlord',
      sortOptions: {
        name: 'Name',
      },
      landlord: {
        title: 'Landlord',
        name: 'Name',
        address: 'Address',
        telephone: 'Telephone',
        email: 'Email',
        notes: 'Notes',
      },
    },
    login: {
      error: 'Error',
    },
    requestPasswordReset: {
      save: 'Request Password Reset',
      backToLogin: 'Back to Login',
    },
    users: {
      UsersHome: {
        genericError: 'An error occurred while fetching Users. Please try again',
        sortOptions: {
          name: 'Name',
          dateCreated: 'Created',
        },

        fields: {
          name: 'Name',
          role: 'Role',
          site: 'Site',
          contractor: 'Contractor',
          engineer: 'Engineer',
          workOrderApprovalLimit: 'Work Order Approval Limit',
          invoiceApprovalLimit: 'Invoice Approval Limit',
          enabled: 'Enabled',
        },

        sites: 'sites',
        allSites: 'All Sites',
        siteTag: 'tag',
        siteTags: 'tags',
        invite: {
          buttonLabel: 'Send Welcome Emails',
          confirmationBody: 'You are about to trigger a welcome email series to {count} users. This will include a link to set their password (if they are not using Single Sign-On), then a confirmation email once this has been successfully set. Please confirm this action.',
          successTitle: 'Welcome Emails Triggered',
          successBody: 'A welcome email has been triggered to {count} users',
        },
      },
      Users: {
        filters: {
          name: 'Name',
          email: 'Email',
          enabled: 'Enabled',
          role: 'Role',
          userType: 'User Type',
          site: 'Site',
          contractor: 'Contractor',
          tag: 'Tag',
          createdAt: 'Created Date',
          updatedAt: 'Updated Date',
          datePasswordChanged: 'Password Changed Date',
          locked: 'Locked',
        },
        buttons: {
          add: 'Add User',
        },
      },
      UserDetail: {
        email: 'Email Address',
        username: 'Username',
        workplaceId: 'Facebook Workplace ID',
        name: 'Name',
        password: 'Password',
        userType: 'User Type',
        role: 'Role',
        associatedContractors: 'Associated Contractor',
        notification_scheme: 'Notification Scheme',
        receiveEmailNotifications: 'Receive Email Notifications?',
        receivePushNotifications: 'Receive Push Notifications?',
        receiveInvoiceApprovalNotifications: 'Receive Invoice Approval Notifications',
        receiveInvoiceSubmitNotifications: 'Receive Invoice Submission Notifications',
        receiveInvoiceApprovalNotificationsWarning:
          'This user will receive all invoice approval notifications \n' +
          'issued to their contractor (or every contractor for admin users), \n' +
          'regardless of their site access permissions.',
        receiveInvoiceSubmitNotificationsWarning:
          'This user will receive all invoice submission ' +
          'notifications issued to their contractor (or every contractor ' +
          'for admin users), regardless of their site access permissions.',
        internalOwner: 'Can Own Work Order?',
        enabled: 'Enabled?',
        isEngineer: 'Engineer?',
        engineerCanViewOtherWorkOrders: 'Engineer can view work orders not assigned to them?',
        forcePasswordChange: 'Force Password Change?',
        created: 'Created',
        lastUpdated: 'Last Updated',
        workOrderApprovalLimit: 'Work Order Approval Limit',
        invoiceApprovalLimit: 'Invoice Approval Limit',
        rateCard: 'Rate Card',
        switchUser: 'Switch User',
        setPassword: 'Set Password',
        unlock: 'Unlock',
        delete: 'Delete',
        timezone: 'Time Zone',
        invitationProcessing: 'Send Welcome Email?',
        invite: {
          buttonLabel: 'Send Welcome Email',
          confirmationTitle: 'Welcome Email Triggered',
          confirmationBody: 'A welcome email has been sent to {name}',
        },
      },
      SiteAccess: {
        siteAccessLabel: 'Site Access Permissions',
        locationTags: 'Location Tags',
        locationTagsDescription: 'If set, this user will be restricted to any locations with the following tags.',
        locationTagsSaved: 'Location Tags successfully saved.',
        locationTagsSaveFailed: 'Failed to save Location Tags.',
        locationTagsSavedTitle: 'Success',
      },

      UserDisplay: {
        deleteConfirm: 'Are you sure you want to delete this User?',
        unlockConfirm: 'Are you sure you want to unlock this User?',
        impersonateUserFailed: 'Impersonate user request failed',
      },

      sections: {
        Notifications: {
          paneId: 'preferences',
          title: 'Preferences & Notifications',
          emptyContractor: '<None>',
        },

        Details: {
          paneId: 'details',
          title: 'Details',
        },

        SiteAccess: {
          paneId: 'siteaccess',
          title: 'Site Access',
        },
      },
    },
    contractors: {
      ContractorsHome: {
        sortOptions: {
          name: 'Name',
        },

        fields: {
          name: 'Name',
          serviceCount: 'Services',
          userCount: 'Users',
          telephone: 'Telephone',
          enabled: 'Enabled',
        },
      },
      ContractorDetail: {
        name: 'Name',
        city: 'City',
        address: 'Address',
        county: 'County',
        country: 'Country',
        account: 'Account',
        telephone: 'Telephone',
        enabled: 'Enabled',
        email: 'Email Address',
        postcode: 'Postcode',
        emergency_contact_details: 'Emergency Contact Details',
        emergency_procedure: 'Emergency Procedure',
        default_tax_rate: 'Default Tax Rate',
        po_number_next: 'Next Sequence Number',
        po_number_prefix: 'PO Prefix',
        custom_po_enabled: 'Enable custom PO number for this contractor?',
        notes: 'Notes',
        sitesServed: 'Sites Served',
        fieldset: {
          details: 'Details',
          address: 'Address',
          po: 'Purchase Order Numbers',
          info: 'Information',
        },
        details: {
          paneId: 'details',
          title: 'Details',
        },

        alerts: {
          paneId: 'alerts',
          title: 'Alerts',
          immediateAlerts: 'Immediate Alerts',
          weeklyDigest: 'Weekly Digest',
          dailyDigest: 'Daily Digest',
        },
        rates: {
          paneId: 'rates',
          title: 'Rates',
          workingHours: 'Working Hours',
          name: 'Name',
          standardHours: 'Standard Hours',
          outOfHours: 'Out of Hours',
          outOfHoursHelp: 'Is there an out of hours service available for this contractor?',
          enabled: 'Enabled',
          rates: 'Rates',
          addRate: 'Add Rate',
          editRate: 'Edit Rate',
          calloutCharge: 'Callout Charge',
          hourlyRate: 'Hourly Rate',
          billingSettings: 'Billing Settings',
          minutesIncludedInCallout: 'Number of minutes included with callout charge',
          timeRoundingRule: 'Time rounding rule',
          allowConsolidatedInvoicing: 'Allow Consolidated Invoicing',
          engineerType: 'Engineer Type',
          engineerTypes: {
            main: 'Main Engineer',
            assistant: 'Assistant Engineer',
          },
        },
        hours: {
          paneId: 'hours',
          outOfHoursHelp: 'Is there an out of hours service available for this contractor?',
          title: 'Hours',
          workingHours: 'Working Hours',
          standardHours: 'Standard Hours',
          outOfHours: 'Out of Hours',
          day: 'Day',
          date: 'Date',
          start: 'Start',
          finish: 'Finish',
          selectDateToExclude: 'Select Date to Exclude',
          excludedDates: 'Excluded Dates',
          appliesToStandardHours: 'Applies to Standard Hours',
          appliesToOutOfHours: 'Applies to Out of Hours',
          addExcludedDate: 'Add Excluded Date',
        },
        documents: {
          paneId: 'documents',
          title: 'Documents',
        },
        users: {
          paneId: 'users',
          title: 'Users',
          associatedUsersIntro: 'The following users are associated with this contractor.',
          associatedUsersNone: 'No users are associated with this contractor.',
        },
        emergencyWo: {
          paneId: 'emergency-wo',
          title: 'Emergency WO',
          emergency_contact_details: 'Emergency Contact Details',
          emergency_procedure: 'Emergency Procedure',
        },
        partsOrderSettings: {
          paneId: 'parts-order-settings',
          title: 'Parts Order Settings',
          currency: 'Currency',
          deliveryTaxRate: 'Delivery Tax Rate',
          deliveryTime: 'Delivery Time',
          deliveryFee: 'Delivery Fee (ex. Tax)',
          deliveryTimeHelp: 'Standard handling and delivery timescale',
        },
        services: {
          add: 'Add Service',
          view: 'View Service',
          edit: 'Edit Service',
          clone: 'Clone Service',
          restrictions: 'Restrictions',
          saving: 'Saving Service',
          save: 'Save',
          workOrderType: 'Work Order Types',
          servicePriority: 'Contractor SLA',
          billingType: 'Billing Type',
          cost: 'Cost',
          baseCost: 'Base Cost',
          costPerAsset: 'Additional Amount Per Asset',
          sitesServed: 'Sites',
          hasRows: 'The following services are configured for this contractor:',
          hasNoRows: 'This contractor does not have any services.',
          paneId: 'services',
          title: 'Services',
          serviceTypes: 'Service Types',
          name: 'Name',
          delete: 'Are you sure you want to delete this service?',
          none: 'None',
          all: 'All',
          selected: 'Selected',
          fixed: 'Fixed',
          asset: 'Asset',
          timeBased: 'Time Based',
          perMinute: 'Per Minute',
          mainEngineerRate: 'Main Engineer Rate',
          assistantEngineerRate: 'Assistant Engineer Rate',
          serviceRates: {
            addTime: 'Add Time',
            delete: 'Are you sure you want to delete this service rate?',
            title: 'Service Rates',
            name: 'Name',
            save: 'Save',
            add: 'Add Service Rate',
            edit: 'Edit Service Rate',
            view: 'View Service Rate',
            noRows: 'None',
            calloutAmount: 'Callout Amount',
            unitAmount: 'Unit Amount',
            days: 'Days',
            MON: 'Monday',
            TUE: 'Tuesday',
            WED: 'Wednesday',
            THU: 'Thursday',
            FRI: 'Friday',
            SAT: 'Saturday',
            SUN: 'Sunday',
            times: 'Times',
            start: 'Start Time (hh:mm:ss)',
            end: 'End Time (hh:mm:ss)',
          },
        },
        rams: {
          paneId: 'rams',
          title: 'RAMS',
          noData: 'There are no RAMS associated with this contractor',
          uploadRams: 'Upload Rams',
        }
      },
      Contractors: {
        filters: {
          name: 'Name',
          enabled: 'Enabled',
        },
      },
      Contractor: {
        add: 'Add Contractor',
        delete: 'Delete Contractor',
        title: 'Contractor',
        entityName: 'Contractor',
        rate: {
          form: {
            entityType: 'Rate',
            addCurrency: 'Add Currency',
            noSupportingCurrencies: 'This rate card has no supporting currencies.',
          },
          currency: {
            card: {
              standard_hours_callout: 'Standard Callout',
              out_of_hours_callout: 'Out of Hours Callout',
              standard_hours_labour: 'Standard Labour',
              out_of_hours_labour: 'Out of Hours Labour',
            },
            form: {
              entityType: 'Currency Support',
            },
          },
        },
      },
    },
    contractorProfile: {
      title: 'Contractor Profile',
      progress: 'Progress',
      complete: 'Complete',
      incomplete: 'Incomplete',
      notFound: 'There is no contractor profile linked to your user account',
      companyDetails: {
        title: 'Company Details',
        description: 'Company name, address and contact details',
        fields: {
          name: 'Company Name',
          email: 'Email',
          telephone: 'Telephone',
          address: 'Address',
          city: 'City',
          county: 'County',
          postcode: 'Postcode',
          country: 'Country',
        }
      },
      workingHours: {
        title: 'Working Hours',
        description: 'Standard working hours, out of hours service and excluded dates',
        hours: {
          title: 'Hours',
          description: 'Standard working hours and out of hours availability',
          fields: {
            standardHours: 'Do you offer standard working hours on {type}?',
            start: 'Start',
            finish: 'Finish',
            outOfHours: 'Do you offer an out of hours service on {type}?',
          },
        },
        excludedDates: {
          title: 'Excluded Dates',
          description: 'Dates where you are unable to offer your services',
          addDate: 'Add New Date',
          form: {
            title: 'Add New Excluded Date',
            date: 'Date',
            appliesToStandardHours: 'Applies to standard hours',
            appliesToOutOfHours: 'Applies to out of hours',
          },
        }
      },
      billingSettings: {
        title: 'Billing Settings',
        description: 'Callout duration and labour charges',
        fields: {
          minutesIncludedInCallout: 'Minutes included in callout',
          timeRoundingRule: 'Time rounding rule'
        }
      }
    },
    workOrderTypes: {
      WorkOrderType: {
        title: 'Work Order Type',
        name: 'Name',
        add: 'Add Work Order Type',
        description: 'Description',
        default_cost_code: 'Default Cost Code',
        workflow: 'Workflow',
        default_sign_off_required: 'Default Sign Off Required',
        default_billing_status: 'Default Billing Status',
        is_part_order: 'Is Part Order',
        requires_rams: 'Requires Health & Safety?',
        sortOptions: {
          name: 'Name',
        },
      },
    },
    workOrderDueSla: {
      due: '{type} due {relative}',
      types: {
        response: 'Response',
        attendance: 'Attendance',
        fix: 'Fix',
      },
    },
    servicePriorities: {
      ServicePriority: {
        title: 'Contractor SLA',
        name: 'Name',
        add: 'Add Contractor SLA',
        description: 'Description',
        sort: 'Sort',
        color: 'Color',
        emergencySla: 'Emergency SLA?',
        timeToAttendHeader: 'Time to Attend',
        timeToFixHeader: 'Time to Fix',
        fallsWithinSiteAccessTimes:
          'Calculate SLA based on site access times (do not count time when sites are closed towards this SLA.)',
        response_time: 'Response Time (minutes)',
        responseTimeHeader: 'Response Time',
        time_to_attend: 'Time to Attend (minutes)',
        time_to_fix: 'Time to Fix (minutes)',
        response_time_at_risk: 'Response Time At Risk (minutes)',
        time_to_attend_at_risk: 'Time to Attend At Risk (minutes)',
        time_to_fix_at_risk: 'Time to Fix At Risk (minutes)',
        roles: 'Roles',
        digestEmail: 'Include in digest email',
        immediateAlerts: 'Send alert immediately if exceeded',
        sortOptions: {
          name: 'Name',
        },
      },
    },
    suppliers: {
      Supplier: {
        title: 'Supplier',
        name: 'Name',
        add: 'Add Supplier',
        sortOptions: {
          name: 'Name',
        },
      },
    },
    costCodes: {
      CostCode: {
        costType: 'Cost Type',
        title: 'Cost Code',
        name: 'Name',
        code: 'Code',
        add: 'Add Cost Code',
        description: 'Description',
        sortOptions: {
          name: 'Name',
          code: 'Code',
        },
      },
    },
    audits: {
      Audit: {
        genericError: 'An error occurred while fetching Service Request. Please try again',
        description: 'Description',
        subject: 'Subject',
        subjectId: 'Subject ID',
        subjectName: 'Subject Name',
        causer: 'Causer',
        createdBefore: 'Created Before',
        createdAfter: 'Created After',
        date: 'Date',
        sortOptions: {
          date: 'Date',
        },
      },
      AuditDisplay: {
        date: 'Date',
        description: 'Description',
        causer: 'Causer',
        impersonatedUser: 'Impersonated User',
        subject: 'Subject',
        subjectId: 'Subject ID',
        subjectName: 'Subject Name',
        summary: 'Summary',
        data: 'Data',
      },
    },
    requestForService: {
      RequestForServicesHome: {
        sortOptions: {
          dateUpdated: 'Updated',
          dateCreated: 'Created',
          contractorId: 'Contractor',
        },

        fields: {
          id: 'WO #',
          summary: 'Summary',
          site: 'Site',
          contractor: 'Contractor',
          service: 'Service',
          priority: 'Contractor SLA',
          status: 'Status',
          requested: 'Requested',
          responded: 'Responded',
        },

        genericError: 'An error occurred while fetching Service Request. Please try again',
      },
      filters: {
        workOrderDisplayReference: 'Work Order Reference',
        status: 'Status',
        contractor: 'Contractor',
        site: 'Site',
        owner: 'WO Internal Owner',
        currentUser: 'Current User',
        workOrderId: 'Work Order ID',
        createdAt: 'Created Date',
        respondedAt: 'Actioned Date',
      },
      Assets: {
        assets: {
          paneId: 'assets',
          title: 'Assets',
        },
      },
      Detail: {
        status: 'Status',
        contractor: 'Contractor',
        currency: 'Currency',
        date: 'Date of Request',
        requester: 'Requested By',
        responder: 'Responded By',
        service: 'Service',
        responded_at: 'Date of Response',
        requestMessage: 'Request Message',
        responseMessage: 'Response Message',
        site: 'Site',
        siteAddress: 'Site Address',
        siteTelephone: 'Site Phone Number',
        summary: 'Summary',
        description: 'Description',
        location: 'Location',
        priority: 'Contractor SLA',
        service_type: 'Service Type',
        request_type: 'Request Type',
        work_order_type: 'Work Order Type',
        pending: 'Pending',
        details: {
          paneId: 'details',
          title: 'Work Order',
          request: 'Request',
          summary: 'Summary',
          map: 'Map',
          latitude: 'Latitude',
          longitude: 'Longitude',
        },
        deleteConfirm: 'Are you sure you want to delete this Request For Service?',
        viewWorkorder: 'View Work Order',
      },
      statuses: {
        accepted: 'ACCEPTED',
        pending: 'PENDING',
        rejected: 'REJECTED',
        cancelled: 'CANCELLED',
      },
    },
    budget: {
      entityName: 'Budget',
      title: 'Budget',
      name: 'Name',
      description: 'Description',
      type: 'Type',
      financialPeriod: 'Financial Year',
      site: 'Site',
      siteTag: 'Site Tag',
      total: 'Budget Total',
      siteSelectionType: 'Sites',
      selectType: 'Select a Budget Type',
      siteSelectionTypeFilterLabel: 'Site Selection Type',
      usableForSite: 'Usable for Site',
      selectSiteTags: 'Select Site Tags',
      selectedSiteTags: 'Selected Site Tags',
      selectSites: 'Select Sites',
      selectedSites: 'Selected Sites',
      splitByFinancialPeriod: 'Split by Financial Period',
      allowFinancialYearOverspend: 'Allow Financial Year Overspend',
      allowFinancialPeriodOverspend: 'Allow Financial Period Overspend',
      hideAfterOverspend: 'Hide after Overspend',
      enabled: 'Enabled',
      amount: 'Amount',
      allSites: 'All Sites',
      periodDivisionLabel: 'Period { index }',

      buttons: {
        add: 'Add Budget',
      },
    },
    organisations: {
      Organisation: {
        title: 'Organisation',
        name: 'Name',
        prefix: 'Prefix',
        add: 'Add Organisation',
        sortOptions: {
          name: 'Name',
        },
        genericError: 'An error occurred while fetching the Organisations. Please try again',
      },
    },
    AssetCategory: {
      title: 'Asset Category',
      name: 'Name',
      selected: 'Selected',
      add: 'Add Asset Category',
      sortOptions: {
        name: 'Name',
      },
      filters: {
        name: 'Name',
      },
      genericError: 'An error occurred while fetching the Asset Categories. Please try again.',
    },
    AssetSupplier: {
      title: 'Asset Supplier',
      name: 'Name',
      selectable: 'Selectable',
      add: 'Add Asset Supplier',
      sortOptions: {
        name: 'Name',
      },
      filters: {
        name: 'Name',
      },
      genericError: 'An error occured while fetching the Asset Suppliers. Please try again.',
    },
    AssetModel: {
      title: 'Asset Model',
      name: 'Name',
      selectable: 'Selectable',
      actions: {
        create: 'Add Model',
        update: 'Update Model',
      },
      sortOptions: {
        name: 'Name',
      },
      fields: {
        files: 'Files',
        image: 'Image',
        name: 'Name',
        modelNo: 'Model No.',
        selectable: 'Selectable',
        assetType: 'Asset Type',
        assetManufacturer: 'Asset Manufacturer',
      },
      filters: {
        name: 'Name',
      },
      sections: {
        details: {
          id: 'details',
          title: 'Details',
        },
        image: {
          id: 'image',
          title: 'Image',
          maxLimitError: 'The maximum number of images is 1, please try again.',
        },
        files: {
          id: 'files',
          title: 'Files',
          screenReader: {
            buttonDelete: 'Delete',
            buttonDownload: 'Download',
            buttonPreview: 'Preview',
          },
        },
      },
      fileUpload: {
        save: 'Upload File',
        saving: 'Saving...',
      },
      imageUpload: {
        save: 'Upload Image',
        saving: 'Saving...',
      },
      display: {
        delete: 'Delete',
        deleteConfirm: 'Are you sure you want to delete this Asset Model?',
      },
      genericError: 'An error occured while fetching the Asset Model. Please try again.',
    },
    Hazard: {
      title: 'Hazard',
      name: 'Name',
      enabled: 'Enabled',
      add: 'Add Hazard',
      sortOptions: {
        name: 'Name',
      },
      filters: {
        name: 'Name',
      },
      genericError: 'An error occurred while fetching the Hazard. Please try again',
    },
    roles: {
      Role: {
        title: 'Role',
        name: 'Name',
        add: 'Add Role',
        servicePriorities: 'Contractor SLAs',
        isInternal: 'Internal Role',
        defaultDashboard: 'Default Dashboard',
        sortOptions: {
          name: 'Name',
        },
        genericError: 'An error occurred while fetching the Roles. Please try again',
      },
    },
    notifications: {
      Notification: {
        title: 'Notification Schemes',
        name: 'Name',
        alwaysNotifyOnWOCreation: 'Always Notify on WO Creation',
        add: 'Add Notification Scheme',
        email: 'Email',
        sortOptions: {
          name: 'Name',
        },
        usersRelationshipToWorkOrder: "User's Relationship to Work Order",
        associations: {
          creator: 'Creator',
          reporter: 'Reporter',
          owner: 'Owner',
          watcher: 'Watcher',
          contractor: 'Contractor',
          engineer: 'Engineer',
        },
        sections: {
          workOrder: 'Work Order Notifications',
          spendLimitIncreaseRequests: 'Spend Limit Increase Requests',
          workOrderCosts: 'Work Order Costs',
          requestForService: 'Service Request Notifications',
          invoice: 'Invoice Notifications',
          costProposal: 'Quote Notifications',
          costRequest: 'Quote Request Notifications',
          slas: 'SLAs',
          partOrder: 'Part Order Notifications',
        },
        event: 'Event',
        events: {
          created: 'Created',
          approved: 'Approved',
          rejected: 'Rejected',
          accepted: 'Accepted',
          cancelled: 'Cancelled',
          requested: 'Requested',
          submitted: 'Submitted',
          queried: 'Queried',
          stateChanged: 'State Changed',
          contractorAssigned: 'Contractor Assigned',
          engineerAssigned: 'Engineer Assigned',
          commentCreated: 'Comment Created',
          actionableDateChanged: 'Actionable Date Changed',
          dueDateChanged: 'Due by Date Changed',
          workOrderPriorityChanged: 'Work Order Priority Changed',
          startWorkCheckFailed: 'Start Work Check Failed',
          etaDateChanged: 'ETA Date Changed',
          compliantDateChanged: 'Compliant Date Changed',
          serviceAssigned: 'Service Assigned',
          slaWeeklyDigest: 'SLA Weekly Digest',
          slaDailyDigest: 'SLA Daily Digest',
          slaAlert: 'SLA Alert',
          workOrderSlaTargetDateChanged: 'SLA Target Changed',
          partOrderAmended: 'Amended',
          partOrderCreated: 'Created',
          partOrderAmendmentApprove: 'Amendment Approved',
          partOrderAmendmentCancel: 'Amendment Cancelled',
          partOrderPartDispatch: 'Parts Dispatched',
          partOrderPartReceived: 'Parts Received',
          partOrderStateChange: 'State Changed',
          partOrderCancelled: 'Cancelled',
        },
        genericError: 'An error occurred while fetching the Notifications. Please try again',
      },
    },
    tags: {
      Tags: {
        add: 'Add Tag',

        title: 'Tag',
        name: 'Name',
        tagType: 'Tag Type',
        color: 'Colour',

        sortOptions: {
          name: 'Name',
        },

        filters: {
          name: 'Name',
        },

        genericError: 'An error occurred while fetching the Tags. Please try again',
      },
    },
    'tag-types': {
      TagTypes: {
        add: 'Add Tag Type',

        title: 'Tag Type',
        name: 'Name',
        entityType: 'Entity Type',

        sortOptions: {
          name: 'Name',
        },

        filters: {
          name: 'Name',
        },

        genericError: 'An error occurred while fetching the Tag Types. Please try again',
      },
    },
    'work-order-creation-rule': {
      WorkOrderCreationRule: {
        add: 'Add Rule',

        title: 'Work Order Creation Rules',
        name: 'Name',
        guard_config: 'Guard Configuration',
        fields: 'Fields',
        sortOptions: {
          name: 'Name',
        },

        filters: {
          name: 'Name',
        },

        genericError:
          'An error occurred while fetching the Work Order Creation Rules. Please try again',
      },
    },
    'financial-periods': {
      FinancialPeriods: {
        add: 'Add Financial Period',
        information: 'Please note: You should enter the values for Financial Periods in UTC time.',
        title: 'Financial Period',
        name: 'Name',
        startDate: 'Start Date',
        endDate: 'End Date',
        divisions: 'Divisions',
        divisionsHelpText:
          'Enter the <strong>end date</strong> of exactly 12 or 13 divisions with T23:59:59 after each date. One date per line.<br/>Expected format YYYY-MM-DDTHH:mm:ss',

        validations: {
          numberOfDivisions: '{field} must have 12 items',
          validDivisionsFormat: 'Each Division must have the format `YYYY-MM-DDTHH:mm:ss`',
          uniqueDivisions: 'Each Division must be unique',
          inRangeDivisions: 'All Divisions must fall between the Start and End Dates',
          lastDivisionEqualEndDate: 'The last Division must equal the End Date',
        },

        sortOptions: {
          name: 'Name',
        },

        filters: {
          name: 'Name',
        },

        genericError: 'An error occurred while fetching the Financial Periods. Please try again',
      },
    },
    'service-types': {
      modals: {
        ServiceTypeModal: {
          addTitle: 'Add Service Type',
          addButton: 'Add Service Type',
          entityName: 'Service Type',
          name: 'Name',
          parent: 'Parent',
          parentEmptyText: 'Choose a parent',
        },
        RequestTypeModal: {
          calendarAlias: 'Calender Alias',
          mediaUpload: 'Attached Media for Service Type',
          title: 'Add WO Request Type',
          addButton: 'Add Request Type',
          entityName: 'Request Type',
          name: 'Name',
          serviceType: 'Service Type',
          serviceTypeEmptyText: 'Choose a Service Type',
          workOrderType: 'Work Order Type',
          workOrderTypeEmptyOption: 'Choose a Work Order Type',
          requiredComplianceDocumentType: 'Required Compliance Document Type',
          requiredComplianceDocumentTypeEmptyOption: 'None',
          usableForRecurringWorkOrders: 'Usable for Recurring Work Orders',
          usableForEmergencyWorkOrders: 'Usable for Emergency Work Orders',
        },
      },
    },
    sites: {
      filters: {
        name: 'Name',
        enabled: 'Enabled',
        organisation: 'Organisation',
        siteType: 'Site Type',
        tag: 'Tag',
      },

      SiteDetail: {
        currency: 'Currency',
        excludedDates: 'Excluded Dates',
        reference: 'Reference',
        name: 'Name',
        costCentre: 'Cost Centre',
        date: 'Date',
        addExcludedDate: ' Add Excluded Date',
        selectDateToExclude: 'Select Date to Exclude',
        address: 'Address',
        city: 'City',
        county: 'County',
        postcode: 'Postcode',
        telephone: 'Telephone',
        notes: 'Notes',
        site_type: 'Site Type',
        organisation: 'Organisation',
        permit_instructions: 'Permit Instructions',
        country: 'Country',
        access_times: 'Access Times',
        enabled: 'Enabled',
        latitude: 'Latitude',
        longitude: 'Longitude',
        tags: 'Tags',
        timezone: 'Time Zone',
        landlord: {
          paneId: 'landlord',
          title: 'Landlord',
          name: 'Landlord',
          address: 'Address',
          telephone: 'Telephone',
          email: 'Email',
          notes: 'Notes',
          noLandlord: 'None',
        },
        users: {
          paneId: 'users',
          title: 'Users',
          associatedUsersIntro: 'The following users are associated with this site.',
          associatedUsersNone: 'No users are associated with this site.',
        },
        information: {
          paneId: 'information',
          title: 'Information',
        },
        details: {
          paneId: 'details',
          title: 'Details',
          contact: 'Contact',
        },
        map: {
          paneId: 'map',
          title: 'Map',
          geofence: 'Geofence',
          location: 'Location',
          latitude: 'Latitude',
          longitude: 'Longitude',
          selectedArea: 'Selected Area',
          clear: 'Clear',
          start: 'Click on map to start creating a geofence.',
          locationSelectHelper:
            'Please either search for a location, select it on the map, or insert the longitude and latitude.',
          locationEditClick: 'Click to edit location',
          locationAddClick: 'Click to select location',
          locationNotSet: 'Location Not Set',
        },
        emergencyWo: {
          paneId: 'emergency-wo',
          title: 'Emergency WO',
        },
        locations: {
          paneId: 'locations',
          title: 'Locations',
          addBuilding: 'Add Building',
          addFloor: 'Add Floor',
          addSpace: 'Add Space',

          spaces: {
            title: 'Add Space',
            entityName: 'Space',
            name: 'Name',
            floor: 'Parent Floor',
            floorEmptyText: 'Choose Floor',
          },

          floors: {
            title: 'Add Floor',
            entityName: 'Floor',
            name: 'Name',
            position: 'Position',
            building: 'Parent Building',
            buildingEmptyText: 'Choose Building',
          },

          buildings: {
            title: 'Add Building',
            entityName: 'Building',
            name: 'Name',
            buildingEmptyText: 'Choose Building',
          },
        },

        delete: 'Delete',
        add: 'Create Site',
      },
      SitesHome: {
        sortOptions: {
          name: 'Name',
        },

        fields: {
          ref: 'Ref.',
          organisation: 'Organisation',
          name: 'Name',
          type: 'Type',
          enabled: 'Enabled',
        },
      },
    },
    compliance: {
      complianceList: {
        summary: 'Summary',
        approvalStatus: 'Approval Status',
        documentStatus: 'Document Status',
        validFrom: 'Valid From',
        validUntil: 'Valid Until',
        documentType: 'Document Type',
        contractor: 'Contractor',
        site: 'Site',
      },
    },
    'recurring-work-orders': {
      RecurringWorkOrdersHome: {
        sortOptions: {
          dateUpdated: 'Updated',
          summary: 'Summary',
        },

        fields: {
          id: 'RWO #',
          summary: 'Summary',
          site: 'Site',
          compliance: 'Compliance',
          interval: 'Interval',
          nextDueDate: 'Next Due by Date',
          nextUnspawnedDueDate: 'Due by Date of Next Work Order to Be Generated',
          contractor: 'Contractor',
          endDate: 'End Date',
        },

        genericError: 'An error occurred while fetching Recurring Work Orders. Please try again',
      },

      RecurringWorkOrderGroups: {
        groups: {
          type: 'Type',
          contractor: 'Contractor',
          site: 'Site',
          all: 'See all',
        },
        commonFields: {
          rwoCount: 'RWO Count',
          woOpen: 'WOs Open',
          woOverdue: 'WOs Overdue',
        },
        type: {
          fields: {
            name: 'Name',
          },
        },
        contractor: {
          fields: {
            contractor: 'Contractor',
          },
        },
        site: {
          fields: {
            site: 'Site',
          },
        },
      },

      RecurringWorkOrderList: {
        editRWO: 'Edit Recurring Work Order',
        fields: {
          type: 'Type',
          summary: 'Summary',
          site: 'Site',
          location: 'Location',
          contractor: 'Contractor',
          interval: 'Interval',
          nextDueDate: 'Next Due by Date',
          compliance: 'Compliance',
          open: 'WOs Open',
          overdue: 'WOs Overdue',
        },
      },

      RecurringWorkOrderWOList: {
        showArchived: 'Show Archived Work Orders',
        openWOs: 'Open Work Orders',
        archivedWOs: 'Archived Work Orders',
        fields: {
          id: 'WO #',
          contractor: 'Contractor',
          status: 'Status',
          serviceCompleted: 'Service Completed',
          dueDate: 'Due by Date',
          spentLimit: 'Spend Limit',
        },
      },

      buttons: {
        add: 'Add Recurring Work Order',
      },

      RecurringWorkOrderCreate: {
        title: 'New Recurring Work Order',
        buttons: {
          create: 'Save',
          cancel: 'Cancel',
        },
      },

      RecurringWorkOrderEdit: {
        selectAnServiceType: 'Please select a Service Type',
        selectASite: 'Please select a Site',
        selectABudget: 'Please select a Budget',
        selectACostCode: 'Please select a Cost Code',
        selectAnInternalOwner: 'Please select an Internal Owner',
        selectAnEngineer: 'Please select an Engineer',
        selectWatchers: 'Please select Watchers',
      },

      RecurringWorkOrderDisplay: {
        templateTitle: 'Work Order Template',
        assetsTitle: 'Assets',
        scheduleTitle: 'Schedule Settings',
        viewWOs: 'View Work Orders',
        delete: 'Delete',
        view: 'View',
        summary: 'Summary',
        site: 'Site',
        location: 'Location',
        serviceType: 'Service Type',
        description: 'Description',
        requestType: 'Request Type',
        addCost: 'Add Cost',
        costLines: 'Cost Lines',
        costLinesTotal: 'Cost Line Total',
        costLinesTotalError: 'The Cost Line Total must not exceed the Spend Limit',
        costLineExplanation:
          'Costs added here will be automatically populated when a work order is created.',
        skipRfs: 'Skip Service Request',
        skipRfsHelp:
          'Assign the service to generated work orders directly - without creating a service request.',
        autoSubmitCosts: 'Auto-submit Costs',
        engineer: 'Engineer',
        maintainableEntityType: {
          name: 'Associated Location or Asset',
          site: 'Site',
          siteInfo:
            'Log the work order against the entire site, without selecting a location or asset.',
          location: 'Location',
          locationInfo: 'Log the work order against a specific location within the site.',
          system: 'System',
          systemInfo: 'Log the work order against a system.',
          asset: 'Asset',
          assetInfo: 'Log the work order against one or more assets of the same type.',
        },
        pointOfContact: 'Point of Contact',
        nextDueDate: 'Next Due by Date',
        nextUnspawnedDueDate: 'Due by Date of Next Work Order to Be Generated',
        nextUnspawnedDueDateHelp: 'The due by date of the first work order in the sequence',
        interval: 'Interval',
        intervalHelp: 'The interval between the due by date of work orders.',
        endDate: 'End Date',
        endDateHelp: 'Work orders will not be generated after this date.',
        viewPeriod: 'View Period',
        viewPeriodHelp: 'The number of days prior to the due by date to create a work order.',
        actionPeriod: 'Action Period',
        actionPeriodHelp:
          'The number of days prior to the due by date to allow the assignee to interact with the work order.',
        compliance: 'Compliance date required?',
        complianceHelp: 'This flag controls whether the Work Order requires a compliance date in order to be considered complete.',
        poNumber: 'PO Number',
        budget: 'Budget',
        spendLimit: 'Spend Limit',
        costCode: 'Cost Code',
        internalOwner: 'Internal Owner',
        watchers: 'Watchers',
        enabled: 'Enabled',
        enabledHelp: 'Work orders will not be generated when the schedule is disabled.',
        contractor: 'Contractor',
        status: 'Status',
        rams: 'Risk Assessments & Method Statements',
        noRams: 'None Selected',
        ramsSelected: '{count} Selected',
        manageRams: 'Manage RAMS',
        template: {
          paneId: 'template',
          title: 'Work Order Template',
        },
        assets: {
          paneId: 'assets',
          title: 'Assets',
        },
        schedule: {
          paneId: 'schedule',
          title: 'Schedule Settings',
        },
        tasks: {
          paneId: 'tasks',
          title: 'Tasks',
        },
        auditLog: {
          paneId: 'audit-log',
          title: 'Audit Log',
          generatedWorkOrder: 'Generated Work Order',
          created: 'Created',
          updated: 'Updated',
          spawnerError: 'Spawner Error',
          changedFrom: '{field} changed from {-from} to {to}',
        },
        workOrders: {
          paneId: 'work-orders',
          title: 'Work Orders',
          id: 'WO#',
          name: 'Name',
          status: 'Status',
          site: 'Site',
          priority: 'Contractor SLA',
          contractor: 'Contractor',
          created: 'Created',
          dueDate: 'Due by Date',
        },
        disabled: 'Disabled',
      },

      filters: {
        site: 'Site',
        contractor: 'Contractor',
        nextDueDate: 'Next Due by Date',
        endDate: 'End Date',
        active: 'Active',
        compliance: 'Compliance',
        workOrderRequestType: 'Type',
        workOrderType: 'Work Order Type',
        summary: 'Summary',
      },
    },
    'work-orders': {
      closureReasons: {
        noFurtherAction: 'Service Complete - no further action',
        followOnQuoteRequired: 'Service Complete - follow on quote required',
        anotherVisitRequired: 'Leave Open - another visit required',
      },
      resolutionCodes: {
        firstTimeFix: 'First-Time Fix',
        fixed: 'Fixed',
        quoteRequired: 'Quote Required',
      },
      LinkedWorkOrderTypes: {
        title: 'Link Type',
        recommendedWorks: 'Recommended Works',
        requiredWorks: 'Required Works/Compliance',
      },
      WorkOrdersHome: {
        sortOptions: {
          workOrderPriority: 'Priority',
          dateUpdated: 'Updated',
          dateCreated: 'Created',
        },

        fields: {
          ref: 'WO #',
          summary: 'Summary',
          status: 'Status',
          site: 'Site',
          workOrderPriority: 'Priority',
          contractorSla: 'Contractor SLA',
          contractor: 'Contractor',
          created: 'Created',
          updated: 'Updated',
          partOrderTotalCost: 'Total Cost',
        },

        genericError: 'An error occurred while fetching Users. Please try again',
      },

      WorkOrderCalendar: {
        mode: {
          months: 'Months',
          days: 'Days',
        },
      },

      buttons: {
        add: 'Add Work Order',
      },
      filters: {
        id: 'Work Order ID',
        summary: 'Summary',
        serviceType: 'Service Type',
        organisation: 'Site Organisation',
        country: 'Site Country',
        siteTag: 'Site Tag',
        siteType: 'Site Type',
        costsAmended: 'Costs Amended',
        site: 'Site',
        location: 'Location',
        contractor: 'Contractor',
        state: 'State',
        status: 'Grouped State',
        priority: 'Contractor SLA',
        reporter: 'Reporter',
        externalForm: 'External Form',
        type: 'Type',
        superType: 'Grouped Type',
        workflow: 'Workflow',
        engineer: 'Engineer',
        owner: 'Owner',
        watcher: 'Watcher',
        maxValue: 'Maximum Value',
        minValue: 'Minimum Value',
        poNumber: 'PO Number',
        invoice: 'Invoice ID',
        invoiceBatchProfile: 'Invoice Batch Profile',
        invoiceRequired: 'Invoice Required',
        complianceWorkOrders: 'Compliance Work Orders',
        compliant: 'Compliant',
        emergency: 'Emergency',
        recurringWorkOrderId: 'Recurring Work Order ID',
        recurringWorkOrder: 'Recurring Work Order',
        subTotal: 'Work Order Subtotal',
        slas: 'SLAs',
        parts: 'Parts',
        partOrderSubTotal: 'Part Order Subtotal',
        sla_options: {
          response_time_at_risk: 'Response Time At Risk',
          response_time_at_risk_historic: 'Response Time was At Risk',
          response_time_breached: 'Response Time Breached',
          response_time_breached_historic: 'Response Time was Breached',
          time_to_attend_at_risk: 'Time to Attend At Risk',
          time_to_attend_at_risk_historic: 'Time to Attend was At Risk',
          time_to_attend_breached: 'Time to Attend Breached',
          time_to_attend_breached_historic: 'Time to Attend was Breached',
          time_to_fix_at_risk: 'Time to Fix At Risk',
          time_to_fix_at_risk_historic: 'Time to Fix was At Risk',
          time_to_fix_breached: 'Time to Fix Breached',
          time_to_fix_breached_historic: 'Time to Fix was Breached',
        },
        sla_date_filters: {
          response_time_at_risk: 'SLA - Response Time - At Risk',
          response_time_target: 'SLA - Response Time - Target',
          response_time_actual: 'SLA - Response Time - Actual',
          time_to_attend_at_risk: 'SLA - Time to Attend - At Risk',
          time_to_attend_target: 'SLA - Time to Attend - Target',
          time_to_attend_actual: 'SLA - Time to Attend - Actual',
          time_to_fix_at_risk: 'SLA - Time to Fix - At Risk',
          time_to_fix_target: 'SLA - Time to Fix - Target',
          time_to_fix_actual: 'SLA - Time to Fix - Actual',
        },
        createdAt: 'Created Date',
        updatedAt: 'Last Updated Date',
        etaDate: 'Estimated Time of Arrival Date',
        dueDate: 'Due by Date',
        actionableDate: 'Actionable Date',
        serviceCompletedDate: 'Service Completed Date',
        quoteStatus: 'Quote Status',
        openQuoteRequests: 'Open Quote Requests',
        open: 'Open',
        hasPendingQuote: 'Has Pending Quote',
        hasJobSheet: 'Has Job Sheet',
        hasPendingSpendLimitIncreaseRequest: 'Has Pending Spend Limit Increase Request',
        invoiceLineStatus: 'Invoice Line Status',
        '-invoiceLineStatus': 'Invoice Line Status (Exclude)',
        invoiceLineStatusOptions: {
          pending: 'Pending',
          rejected: 'Rejected',
          approved: 'Approved',
        },
        invoiceStatus: 'Invoice Status',
        invoiceStatusOptions: {
          uninvoiced: 'Uninvoiced',
          pending: 'Pending',
          approved: 'Approved',
        },
        financialYear: 'Financial Year',
        accountingPeriod: 'Accounting Period',
        costType: 'Cost Type',
        costCode: 'Cost Code',
        tag: 'Tag',
        billingStatus: 'Billing Status',
        requesterName: 'Requester Name (External Form)',
        requesterEmail: 'Requester Email (External Form)',
        workOrderPriority: 'Priority',
        hasUnseenComment: 'Has Unseen Comment',
        hasUnseenActivity: 'Has Unseen Activity',
        lastCommentAt: 'Last Comment At',
        lastCommentByRole: 'Last Comment By Role',
        lastCommentByRoleGroup: 'Last Comment By Role Group',
        lastCommentByUser: 'Last Comment By User',
        lastActivityAt: 'Last Activity At',
        lastActivityByRole: 'Last Activity By Role',
        lastActivityByRoleGroup: 'Last Activity By Role Group',
        lastActivityByUser: 'Last Activity By User',
        linkType: 'Link Type',
        linkTypeOptions: {
          noLink: 'Not Linked',
          linked: 'Linked',
        },
        externalReference: 'External Reference',
        search: 'Search',
        parentWorkOrderSupertype: 'Parent Work Order Supertype',
        latestComplianceSubmissionStatus: 'Latest Compliance Submission Document Status',
        latestComplianceSubmissionApprovalStatus: 'Latest Compliance Submission Status',
        costStatus: 'Cost Status',
        currency: 'Currency',
        resolutionCode: 'Resolution Code',
      },
      WorkOrderSlaLogUpdate: {
        activity: {
          status: 'SLA value(s)',
          changeReason: 'Change reason',
        },
      },
      WorkOrderDisplayContractor: {
        externalReference: 'External Reference',
        siteDetails: {
            siteDetailsTitle: 'Site Details',
        },
      },
      WorkOrderDisplay: {
        secondaryActions: {
          newLinkedQuote: 'New Linked Quote',
          addTasklist: 'Add Tasklist',
          manageTags: 'Manage Tags',
          newLinkedWO: 'New Linked WO',
          viewParentRecurringWorkOrder: 'View Template',
          changePriority: 'Change Priority',
        },
        isNonBillableNotice:
          'This work order is currently marked as non-billable. To view and add quotes, please mark as billable in the costs tab.',
        editSummaryAndDescriptionTitle: 'Edit Summary and Description',
        summary: 'Summary',
        notWatching: 'You are not watching this work order',
        watching: 'You are watching this work order',
        assignWorkOrderToSelf: 'Click to assign this work order to yourself',
        confirmAssignToSelf:
          'Are you sure you want to assign this work order to yourself, it is currently owned by',
        actionableDateNoticeWithPermission: 'This work order has an actionable date set to {date} and therefore cannot be started.',
        editActionableDate: 'Edit Actionable Date',
        actionableDateNoticeWithoutPermission: 'This work order cannot be started until {{date}}. If you have any updates or wish to get in touch, please add a comment.',
        site: 'Site',
        state: 'Status',
        workOrderPriority: 'Priority',
        priority: 'Contractor SLA',
        availableServices: 'Service',
        location: 'Location',
        serviceType: 'Service Type',
        assets: 'Assets',
        description: 'Description',
        workOrderType: 'Work Order Type',
        requestType: 'Request Type',
        externalForm: 'External Form',
        externalReference: 'External Reference',
        externalReferenceSubText:
          "An optional external reference for the work order, e.g. contractor's own reference.",
        creatingUser: 'Creating User',
        requestingUser: 'Requesting User',
        requesterName: 'Requester Name',
        requesterEmail: 'Requester Email',
        internalOwner: 'Internal Owner',
        internalOwnerEmptyOption: 'None',
        spendLimit: 'Spend Limit',
        changeSpendLimit: 'Change Spend Limit',
        changeSpendLimitTitle: 'Spend Limit',
        requestSpendLimitIncrease: 'Request Uplift',
        budget: 'Budget',
        signOffRequired: 'Sign Off Required',
        invoiceRequired: 'Invoice Required',
        etaDate: 'Estimated Time of Arrival Date',
        dueDate: 'Due by Date',
        actionableDate: 'Actionable Date',
        compliantDate: 'Compliant Date',
        costCode: 'Cost Code',
        consolidatedInvoicing: 'Consolidated Invoicing',
        invoiceBatchProfile: 'Invoice Batch Profile',
        poNumber: 'PO Number',
        editDescription: 'Edit Description',
        tags: 'Tags',
        addTags: 'Add Tags',
        manageTags: 'Manage Tags',
        siteTelephone: 'Site Telephone',
        siteAddress: 'Site Address',
        siteAccessTimes: 'Access Times',
        address: 'Address',
        city: 'City',
        country: 'Country',
        county: 'County',
        postcode: 'Post Code',
        tagType: 'Tag Type',
        watchers: 'Watchers',
        contractor: 'Contractor',
        contractorTelephone: 'Contractor Telephone',
        service: 'Service',
        engineer: 'Engineer',
        pointOfContact: 'Point of Contact',
        noSpendLimit: 'Not Specified',
        link: 'Link',
        isThisAnEmergencyWorkOrder: 'Is this an emergency work order?',
        emergency: 'emergency',
        emergencyWorkOrder: 'Emergency Work Order',

        permissionDenied: "You don't have permission to view that work order.",

        confirm: {
          executeTransition: 'Are you sure you want to execute this transition?',
        },

        buttons: {
          requestQuote: 'Request Quote',
          requestService: 'Request Service',
          viewParentRecurringWorkOrder: 'View Template',
        },

        createdDescription: {
          genericCreate: 'A work order was created',
          createdOnBehalfOfReporter: 'A work order was created on behalf of {reporter}',
          recurringWorkOrderCreated:
            'A work order was automatically created by a planned work template schedule',
          creatorCreatedOnBehalfOfReporter:
            '{user} created a work order on behalf of {reporter}',
          createdAWorkOrder: '{user} created a work order',
        },

        maintainableEntities: {
          paneId: 'assets',
          title: 'Assets',
          hasData: 'The following assets are associated with this work order',
          noData: 'There are no assets associated with this work order',
          linkAsset: 'Link Asset',
          view: 'Linked Assets To Work Order',
          workOrderSummary: 'Work Order',
          workOrderAssets: 'Assets',
          saving: 'Saving',
          save: 'Save',
          edit: 'Edit Linked Assets To Work Order',
          addTitle: 'Link Assets To Work Order',
          deletePrompt: 'Are you sure you want to unlink this asset from the work order?',
          serialNumber: 'Serial Number: {serial_number}',
        },
        partsOrder: {
          paneId: 'parts',
          title: 'Parts Order',
          stateBanner: {
            orderState: 'ORDER STATE:',
          },
          totals: {
            subtotal: 'Subtotal ({items} items)',
            delivery: 'Delivery (ex. Tax)',
            total: 'Total (ex. Tax)',
          },
          unitDeliveryFee: 'Unit Delivery Fee',
          contractorDeliveryFee: 'Supplier Delivery Fee',
          surchargeFees: 'Surcharges',
          unitPrice: 'Unit Price',
          delivery: 'Delivery',
          quantity: 'Quantity',
          partNumber: 'Part Number',
          surcharge: 'Surcharge',
          createdDescription: {
            genericCreate: 'A parts order was created',
            createdOnBehalfOfReporter: 'A parts order was created on behalf of {reporter}',
            recurringWorkOrderCreated:
              'A parts order was automatically created by a planned work template schedule',
            creatorCreatedOnBehalfOfReporter:
              '{user} created a parts order on behalf of {reporter}',
            createdAWorkOrder: '{user} created a parts order',
          },
        },
        quotes: {
          paneId: 'quotes',
          title: 'Quotes',
          addButton: 'Add Quote',
          quotesRequested: {
            title: 'Quote Requests',
            none: 'There are no quote requests associated with this work order',
          },
        },
        invoices: {
          paneId: 'invoices',
          title: 'Invoices',
          addButton: 'Add Invoice',
          none: 'There are no invoices associated with this work order',
        },
        compliance: {
          paneId: 'compliance',
          title: 'Compliance',
          noData: ' There are no compliance documents associated with this work order',
          addComplianceDocument: 'Add Compliance Document',
          comment: 'Comment',
          responseComment: 'Response Comment (required if rejecting)',
          assets: 'Assets',
          status: 'Status',
          approvalStatus: 'Approval Status',
          documentStatus: 'Document Status',
          moreDocuments: '+ {count} more',
        },
        costsPane: {
          paneId: 'costs',
          title: 'Costs',
          confirmApproveInvoiceLine: 'Approve Work Order Cost?',
          resubmit: 'Resubmit',
          amendDeleteHint: 'Amend or Delete',
          addButton: 'Add Cost',
          submitButton: 'Submit for Review',
          submitTooltip: 'Costs can only be submitted when the work order has been completed.',
          makeBillable: 'Make Billable',
          makeNonBillable: 'Make Non-Billable',
          draftButton: 'Make Draft',
          approveButton: 'Approve Costs',
          rejectButton: 'Reject Costs',
          queryButton: 'Query Costs',
          cancelQueryButton: 'Cancel Query',
          cancelRejectionButton: 'Cancel Rejection',
          confirmSubmit: 'Are you sure you want to submit the costs for review?',
          confirmDraft: 'Are you sure you want to return the costs to draft status?',
          confirmCancelQuery:
            'Are you sure you want cancel the query and to return the costs to draft status?',
          confirmCancelRejection:
            'Are you sure you want cancel the rejection and to return the costs to draft status?',
          none: 'There are no costs associated with this work order',
          description: 'Description',
          type: 'Type',
          amount: 'Amount',
          status: 'Status',
          noContractor:
            'There are no costs associated with this work order because a contractor has not been assigned to it.',
          workOrderIsNonBillable:
            "Costs cannot be added because the work order's billing status is 'Non-Billable'.",
          invoice: 'Invoice',
          costLineModal: {
            editCostLine: 'Edit Cost Line',
            addCostLine: 'Add Cost Line',
            contractor: 'Contractor',
            costType: 'Cost Type',
            description: 'Description',
            quantity: 'Quantity',
            unitAmountExTax: 'Unit Amount (ex. Tax)',
            taxRate: 'Tax Rate',
            rejectionReason: 'Rejection Reason',
            rejectedBy: '{user} rejected this on {date}:',
            queriedBy: '{user} queried this on {date}:',
          },
          assignToInvoiceModal: {
            assignToInvoice: 'Assign To Invoice',
            selectInvoice: 'Select Invoice',
            contractor: 'Contractor',
          },
          billingStatusBanner: {
            billingStatus: 'Billing Status',
            statusInfo: {
              DRAFT: 'Add costs associated with this work order, then submit for review.',
              SUBMITTED: 'The costs for this work order have been submitted are awaiting review.',
              APPROVED:
                'The costs for this work order have been approved and allocated to an invoice.',
              QUERIED: 'Please amend or delete all queried costs, then resubmit for review.',
              REJECTED:
                'The costs for this work order have been rejected. No further changes can be made.',
            },
          },
        },
        expenses: {
          paneId: 'expenses',
          title: 'Expenses',
          addButton: 'Add Expense',
          none: 'There are no expenses associated with this work order',
        },
        files: {
          paneId: 'files',
          title: 'Files',
          fileInformation:
            'You can upload PDF, Word & Excel documents and images & videos. Max size per file is {size} {unit}.',
          dragUpload: 'Drop files here or click to upload',
          screenReader: {
            buttonUpload: 'Upload Button',
            buttonPreview: 'Preview Button',
            buttonDelete: 'Delete Button',
            buttonDownload: 'Download Button',
          },
        },
        tasks: {
          newTaskListTitle: 'New Task List',
          paneId: 'tasks',
          title: 'Tasks',
          addList: 'Add List',
          pass: 'Pass',
          fail: 'Fail',
          skipped: 'Skip',
          confirmTaskDelete: 'Are you sure you want to delete this Task?',
          confirmTasklistDelete: 'Are you sure you want to delete this Task List?',
        },
        activity: {
          workOrderCreated: {
            title: 'WO #{id} was created',
            body: '',
          },
          approvedRequestedIncreaseToThe:
            '{user} approved the requested increase to the {field}',
          rejectedRequestedIncreaseToThe:
            '{user} rejected the requested increase to the {field}',
          requestedIncreaseToThe: '{user} requested an increase to the {field}',
          cancelledRequestedIncreaseToThe:
            '{user} cancelled the requested increase to the {field}',
          paneId: 'activity',
          title: 'Activity',
          summaryTitle: 'Summary',
          addComment: 'Add Comment',
          writeCommentPlaceholder: 'Write a comment...',
          attachFile: 'Attach File',
          internal: 'Internal',
          requesterOnly: 'Requester only',
          makePublic: 'Make public',
          makePrivate: 'Make private',
          confirmMakePublic: 'Are you sure you want to make this comment public?',
          confirmMakePrivate: 'Are you sure you want to make this comment private?',
          savingMsg: 'Saving...',
          send: 'Send',

          // ActivityComment
          commented: '{user} commented',
          commentedToRequester: '{user} commented to {requester} (external requester)',
          requesterCommented: '{requester} (external requester) commented',
          receivedViaEmail: '(received via email)',
          billingStatus: 'Billing Status',
          status: 'Status',
          contractor: 'Contractor',
          engineer: 'Engineer',
          service: 'Service',
          internalOwner: 'Internal Owner',
          priority: 'Contractor SLA',
          workOrderPriority: 'Work Order Priority',
          spendLimit: 'Spend Limit',
          creationRules: 'Creation rules run:',
          compliantDate: 'Compliant Date',

          updatedThe: '{user} updated the {field}',
          changedThe: '{user} changed the {field}',
          increasedThe: '{user} increased the {field}',
          decreasedThe: '{user} decreased the {field}',
          assignedANew: '{user} assigned a new {field}',
          changedFrom: '{field} changed from {from} to {to}',
          increasedTo: '{field} increased from {from} to {to}',
          decreasedFrom: '{field} decreased from {from} to {to}',

          complianceSubmission: {
            created: '{user} submitted the compliance document {documentName}',
            approved: '{user} approved the compliance document {documentName}',
            rejected: '{user} rejected the compliance document {documentName}',
          },

          requestForService: {
            sent: '{user} sent a request for service to {contractor}',
            accepted: '{user} accepted a request for service on behalf of {contractor}',
            rejected: '{user} rejected a request for service on behalf of {contractor}',
            cancelled: '{user} cancelled a request for service to {contractor}',
          },

          partOrderAmended: {
            amended: '{user} amended the part order',
            qtyAndPriceBody: 'The quantity and cost for {name} was amended from {quantity_from} to {quantity_to} and from {price_from} to {price_to}',
            qtyBody: 'The quantity for {name} was amended from {quantity_from} to {quantity_to}',
            priceBody: 'The price for {name} was amended from {price_from} to {price_to}',
            surchargeBody: 'Additional cost \'{name}\' valued at {price} has been added',
          },
          partOrderDispatch: {
            dispatched: '{user} has marked parts as dispatched',
            body: '{name} (x{quantity}) has been marked as dispatched',
          },
          partOrderReceive: {
            received: '{user} has marked parts as received',
            body: '{name} (x{quantity}) has been marked as received',
          },

          task: {
            taskEvent: '{user} {event} the task {name} {field}',
            taskDeleted: ' {task} was deleted',
            taskListEvent: '{user} {event} the tasklist {list} {field}',
          },

          costProposalRequest: {
            sent: '{user} sent a quote request to {contractor}',
            sentCount: '{user} sent a quote request to {count} contractors',
            rejected: '{user} rejected a quote request on behalf of {contractor}',
            rejectedNoContractor: '{user} rejected the quote request',
            cancelled: '{user} cancelled a quote request to {contractor}',
            cancelledNoContractor: '{user} cancelled the quote request',
          },

          creationRuleEvents: {
            spend_limit: 'Spend Limit',
            po_number: 'PO Number',
            WorkOrderPriority: 'Work Order Priority',
            sign_off_required: 'Sign Off Required',
            billing_status: 'Billing Status',
            WorkOrderType: 'Work Order Type',
            Workflow: 'Workflow',
            State: 'State',
            Contractor: 'Contractor',
            Service: 'Service',
            Engineer: 'Engineer',
            Owner: 'Owner',
            Watchers: 'Watchers',
            CostCode: 'Cost Code',
            InvoiceBatchProfile: 'Invoice Batch Profile',
            Tags: 'Tags',
            Budget: 'Budget',
          },

          costProposal: {
            submitted: '{user} submitted a quote on behalf of {contractor}',
            approved: '{user} approved a quote from {contractor}',
            rejected: '{user} rejected a quote from {contractor}',
            requestAuthorisation:
              '{user} requested authorisation for a quote from {contractor}',
            authorised: '{user} authorised a quote from {contractor}',
            approvalRequested: '{user} requested approval for a quote',
          },

          jobSheet: {
            uploaded: '{user} uploaded a job sheet',
          },

          linkedWorkOrder: {
            created: '{user} created a new linked work order (#{id})',
            view: 'View Work Order',
          },

          linkedQuote: {
            created: '{user} created a new linked quote (#{id}) for {contractorName}',
            view: 'View Quote',
          },

          linkedQuoteRequest: {
            created: '{user} created a new linked quote request (#{id}) for {contractorName}',
            view: 'View Quote Request',
          },

          ranCreationRules: '{user} ran the following creation rules against the work order:',

          sla: {
            displayName:
              'SLA',
            alertSent:
              '{contractor} has been sent a {type} {state} SLA alert.',
          },

          workday: {
            poRequestFailed: {
              title: 'Failed to create Workday Purchase Order',
            },
            poRequestSent: {
              title: 'Created Workday Purchase Order',
              details: 'Workday Purchase Order #{po_number} has been created',
            },
            receiptRequestFailed: {
              title: 'Failed to create Workday Receipt',
            },
            receiptRequestSent: {
              title: 'Created Workday Receipt',
              details: 'Workday Receipt #{receipt_number} has been created',
            },
            retry: 'Retry',
          },

          short: {
            requestForService: {
              sent: 'Request for service sent to {contractor}',
              accepted: 'Request for service accepted on behalf of {contractor}',
              rejected: 'Request for service rejected on behalf of {contractor}',
              cancelled: 'Request for service to {contractor} cancelled',
            },

            costProposalRequest: {
              sent: 'Quote request sent to {contractor}',
              sentCount: 'Quote request sent to {count} contractors',
              rejected: 'Quote request rejected on behalf of {contractor}',
              cancelled: 'Quote request to {contractor} cancelled',
            },

            costProposal: {
              submitted: 'Quote submitted on behalf of {contractor}',
              approved: 'Quote from {contractor} approved',
              rejected: 'Quote from {contractor} rejected',
              requestAuthorisation: 'Authorisation requested for quote from {contractor}',
              authorised: 'Quote from {contractor} authorised',
              approvalRequested: '{user} requested approval for a quote',
            },

            spendLimitIncreaseRequest: {
              cancelled: 'The outstanding spend limit increase request has been cancelled',
            },

            jobSheet: {
              uploaded: 'Job sheet uploaded',
            },

            sla: {
              alertSent: '{contractor} has been sent a {type} {state} SLA alert.',
            },

            partOrder: {
              amendQuantityAndPrice: 'The quantity and price for {name} was amended from {quantity_from} to {quantity_to} and from {price_from} to {price_to}.',
            },
          },

          filesRemaining: 'files remaining',
          uploadComplete: 'Upload complete!',
          invalidFieldValue: 'Invalid values for field {field}',

          genericError:
            'An error occurred while fetching the Work Order Activity. Please try again',
        },
        detailsContractor: {
          detailsTitle: 'Work Order Details',
        },
        details: {
          paneId: 'details',
          summaryTitle: 'Summary',
          detailsTitle: 'Details',
          datesTitle: 'Dates',
          peopleTitle: 'People',
          attachmentsTitle: 'Attachments ({count})',
          legacyLinksTitle: 'Legacy Work Order Links',
          linkedWorkOrderTitle: 'Linked Work Orders',
          recommendedLinkedWorkOrders: 'Recommended',
          requiredLinkedWorkOrders: 'Required/Compliance',
          parentWorkOrder: 'Parent Work Order',
        },
        documents: {
          paneId: 'documents',
          title: 'Documents',
        },
        time: {
          paneId: 'time',
          title: 'Time',
          sla: 'SLA',

          serviceLevels: {
            timezoneInfo: 'Please note the following times are displayed in the site timezone which is {timezone}.',
            title: 'Service Levels',
            noService: 'There is no service assigned to this work order',
            types: {
              internal: 'Internal Response',
              response: 'Response Time',
              attendance: 'Time to Attend',
              fix: 'Time to Fix',
            },
            states: {
              atRisk: 'AT RISK',
              hit: 'HIT',
              miss: 'MISS',
              pending: 'PENDING',
            },
            fields: {
              response_time: 'Response Time',
              time_to_fix: 'Time To Fix',
              time_to_attend: 'Time To Attend',
            },
          },

          serviceTime: {
            title: 'Service Time Tracking',
            totalDuration: 'Total Time Logged',
            history: 'History',
            noData: 'There are no time logs associated with this work order',

            addTimeLog: 'Add Engineer Time Log',
            startTimeLog: 'Start Engineer Time Log',
            endLogAction: 'End Log',
            viewTimeLog: 'View Time Log',
            editTimeLog: 'Edit Time Log',

            status: 'Status',
            statusManual: 'Manual',
            statusAuto: 'Automatic',
            engineer: 'Engineer',
            engineerName: "Engineer's Name",
            engineerType: 'Engineer Type',
            from: 'Start Time',
            endTime: 'End Time',
            duration: 'Duration',
            contractorRate: 'Contractor Rate',
            rate: 'Rate',
          },
        },

        healthAndSafety: {
          paneId: 'health-and-safety',
          title: 'Health & Safety',
        },

        rams: {
          paneId: 'rams',
          title: 'RAMS',
          noContractor: 'To Add RAMS, a Contractor must be assigned',
          noData: 'There are no RAMS associated with this work order',
          manageRams: 'Manage RAMS',
        },

        jobSheets: {
          paneId: 'job-sheets',
          title: 'Job Sheets',
          noData: 'There are no job sheets associated with this work order',
          addJobSheet: 'Add Job Sheet',
          viewJobSheet: 'View Job Sheet',
          editJobSheet: 'Edit Job Sheet',
          description: 'Description',
          creator: 'Creator',
          deleteConfirm: 'Are you sure you want to delete this job sheet?',
        },

        costs: {
          paneId: 'costs',
          title: 'Costs',
          reference: 'ref',
          exTax: 'ex. Tax',

          costProposals: {
            title: 'Quotes',
            none: 'There are no quotes associated with this work order',

            deleteConfirm: 'Are you sure you want to delete this Invoice?',
            genericError: 'An error occurred while fetching the Invoices. Please try again',
            saveError: 'An error occurred while saving this Invoice. Please try again',
            deleteError: 'An error occurred while deleting this Invoice. Please try again',
          },

          expenses: {
            title: 'Expenses',
            none: 'There are no expenses associated with this work order',

            deleteConfirm: 'Are you sure you want to delete this Expense?',
            genericError: 'An error occurred while fetching the Expenses. Please try again',
            saveError: 'An error occurred while saving this Expense. Please try again',
            deleteError: 'An error occurred while deleting this Expense. Please try again',
          },

          invoices: {
            title: 'Invoices',
            none: 'No invoices',

            deleteConfirm: 'Are you sure you want to delete this Invoice?',
            genericError: 'An error occurred while fetching the Invoices. Please try again',
            saveError: 'An error occurred while saving this Invoice. Please try again',
            deleteError: 'An error occurred while deleting this Invoice. Please try again',
          },
        },

        genericError: 'An error occurred while fetching this Work Order. Please try again',
        createGenericError: '',
        updateGenericError: '',

        linkedQuoteModal: {
          title: 'New Linked Quote',
          quoteInstruction:
            'Click the button below to add a quote now, or skip this step if you want to upload a quote later.',
          quoteTitle: 'Quote',
          addQuote: 'Add Quote',
          exTax: 'ex. Tax',
          description: 'Description of Works Required',
          contractor: 'Contractor',
          summary: 'Follow-on Quote from Work Order #{id} ({type})',
        },
      },

      WorkOrderEdit: {
        assetSearchPlaceholder: 'Search by Name or Label',
        selectAssets: 'Select Assets',
        selectAnServiceType: 'Please select a Service Type',
        assetSearchResult: ' Found { count } matching assets',
        locationWholeSite: 'Whole Site',
        singleAsset: {
          partsWarrantyExpires: 'Parts Warranty Expires',
          labourWarrantyExpires: 'Labour Warranty Expires',
        },
      },

      WorkOrderCreate: {
        title: 'New Work Order',
        linkedTitle: 'New Linked Work Order',
        buttons: {
          create: 'Create Work Order',
        },
      },

      WorkOrderSlaEdit: {
        title: 'Change { serviceName } Target',
        linkedTitle: 'Change { serviceLevel } Target',
        TargetTimeLabel: 'New Target Time',
        ChangeReasonLabel: 'Reason For Change',
        buttons: {
          save: 'Change Target Time',
        },
      },

      EmergencyWorkOrder: {
        noServicesAvailable: 'There are no services available.',
        noSuitableServices: 'No suitable services? Click here for further instructions.',
        createWOWithoutService: 'Create Emergency WO Without Service',
        instructionSelectService: 'Please select one of the services below:',
        followInstructionsBelow: 'Please follow the instructions below:',
        enterDetailsFromContact:
          'Enter the details below, then complete and submit the work order.',
        contactName: 'Contact Name',
        contactNameSubText: 'Please enter the name of the contact you spoke to below.',
        jobReference: 'Job Reference',
        jobReferenceSubText: 'Please request a job reference from the contact and enter it below.',
        step1: 'Step 1:',
        step2: 'Step 2:',
        step3: 'Step 3:',
        contactDetails: 'Contact Details:',
      },

      WorkOrderLink: {
        Create: {
          title: 'Link Work Order',
          sourceLink: {
            label: 'This Work Order',
          },
          targetLink: {
            label: 'Link To',
            placeholder: 'Search Work Orders',
          },
          linkType: {
            label: 'Link Type',
          },
          buttons: {
            create: 'Add',
            save: 'Link Work Order',
          },
        },
        Delete: {
          buttons: {
            delete: 'Delete',
          },
        },
        createGenericError:
          'An error occurred while creating the work order link. Please try again',
        deleteGenericError:
          'An error occurred while creating the work order link. Please try again',
      },
    },
    'cost-proposals': {
      CostProposalsHome: {
        sortOptions: {
          createdAt: 'Submitted',
        },

        filters: {
          workOrderId: 'Work Order ID',
          workOrderDisplayReference: 'Work Order Reference',
          contractors: 'Contractors',
          status: 'Status',
          submitted: 'Submitted',
          site: 'Site',
          owner: 'WO Internal Owner',
          currentUser: 'Current User',
          createdAt: 'Created Date',
          expiryDate: 'Expiry Date',
          respondedAt: 'Actioned Date',
        },

        fields: {
          id: 'WO',
          summary: 'Summary',
          site: 'Site',
          contractor: 'Contractor',
          subtotal: 'Subtotal',
          tax: 'Tax',
          submitted: 'Submitted',
        },

        gridItemTitle: '{type} for Work Order #{work_order_id}: {work_order_summary}',

        genericError: 'An error occurred while fetching the Quotes. Please try again',
      },
    },
    invoices: {
      InvoicesHome: {
        createInvoice: 'Create Invoice',
        sortOptions: {
          closingDate: 'Closing Date',
        },
        createInvoiceModal: {
          description: 'Description',
          reference: 'Reference',
          contractor: 'Contractor',
          currency: 'Currency',
          title: 'Create Invoice',
          consolidated: 'Consolidated',
          closingDate: 'Closing Date',
          poNumber: 'PO Number',
        },
        editInvoiceModal: {
          description: 'Description',
          reference: 'Reference',
          title: 'Edit Invoice',
          closingDate: 'Closing Date',
        },

        filters: {
          workOrderId: 'Work Order ID',
          contractors: 'Contractors',
          contractorReference: 'Contractor Reference',
          currency: 'Currency',
          consolidated: 'Consolidated',
          autoSubmitCosts: 'Auto Submit Costs',
          closingDate: 'Closing Date',
          status: 'Status',
          submitted: 'Submitted',
          site: 'Site',
          owner: 'WO Internal Owner',
          currentUser: 'Current User',
          createdAt: 'Created Date',
          dueDate: 'Due by Date',
          respondedAt: 'Actioned Date',
          serviceCompletedDate: 'Service Completed Date',
          invoiceBatchProfile: 'Batch Profile',
          financialPeriod: 'Financial Period',
          reference: 'Reference',
          exportedAt: 'Exported At',
          exportedBy: 'Exported By',
          exportFileName: 'Export File Name',
        },

        properties: {
          issue_date: 'Issued: ',
          due_date: 'Due: ',
        },

        fields: {
          id: 'Inv #',
          workOrder: 'WO #',
          summary: 'Summary',
          site: 'Site',
          contractor: 'Contractor',
          contractorReference: 'Contractor Reference',
          status: 'Status',
          subtotal: 'Subtotal',
          tax: 'Tax',
          issued: 'Issued',
          consolidated: 'Consolidated',
          closingDate: 'Closing Date',
          approvedAt: 'Approved At',
        },

        gridItemTitle: '{reference} for Work Order #{work_order_id}: {work_order_summary}',

        genericError: 'An error occurred while fetching the Quotes. Please try again',

        exportByLine: 'Export By Line',
      },
      InvoiceDisplay: {
        edit: 'Edit',
        export: 'Export',
        delete: 'Delete',
        print: 'Print',
        invoiceTo: 'Invoice To',
        invoiceDetails: 'Invoice Details',
        status: 'Invoice Status',
        reference: 'Reference',
        description: 'Description',
        contractor: 'Contractor',
        currency: 'Currency',
        account: 'Account',
        subtotal: 'Subtotal',
        total: 'Total',
        approveInvoice: 'Approve Invoice',
        approveInvoiceFromPaid: 'Mark Invoice as Unpaid',
        markInvoiceAsPaid: 'Mark Invoice as Paid',
        draftInvoice: 'Draft Invoice',
        draftInvoiceConfirmation: 'Are you sure you wish to move this invoice back to draft?',
        draftInvoiceConsolidatedConfirmation: 'You’re about to change the status of this invoice back to DRAFT. ' +
          'If you wish to add new costs to this invoice you may need to change the closing date to a future date. ' +
          'Once you’re happy with the lines in this DRAFT invoice, please APPROVE it once more.',
        markInvoiceAsSubmitted: 'Upload & Submit Invoice',
        markAsSubmittedModalTitle: 'Submit Invoice',
        markAsSubmittedModal: {
          contractorReference: 'Contractor Reference',
          invoiceValue: 'Invoice Value (ex. Tax)',
          invoiceDate: 'Invoice Date',
          invoiceAttachment: 'Invoice Attachment',
          replaceInvoiceAttachmentMessage: "You're about to upload a new invoice attachment. Any existing attachment will be replaced with this new one."
        },
        replaceInvoiceAttachment: 'Replace Invoice Attachment',
        invoiceMustBeSameAs: 'The value of the invoice you\'re uploading doesn\'t match the value we have approved which is',
        attachments: 'Invoice Attachment',
        attachmentTable: {
          contractorReference: 'Contractor Reference',
          invoiceDate: 'Invoice Date',
          submitted: 'Date Submitted',
          creator: 'Creator',
          files: 'Attachments',
        },
        confirmApproveInvoiceFromPaid:
          'Are you sure you wish to mark this invoice as unpaid? ' +
          'The invoice will return to approved status.',
        poNumberModalTitle: 'Confirm PO Number',
        confirmApproveInvoice: 'Are you sure you wish to approve this invoice?',
        confirmInvoiceDeletion: 'Are you sure you wish to delete this invoice?',
        markAsPaidModalTitle: 'Invoice Paid Date',
        tax: 'Tax',
        instructions: 'Instructions',
        approve: 'Approve',
        reject: 'Reject',
        createdAt: 'Date Created',
        approvedAt: 'Date Approved',
        poNumber: 'PO Number',
        closingDate: 'Closing Date',
        financialPeriodDivision: 'Financial Period',
        period: 'Period {period}',
        paidDate: 'Paid Date',
        table: {
          workOrderId: 'WO',
          site: 'Site',
          summary: 'Summary',
          description: 'Description',
          costCode: 'Cost Code',
          budget: 'Budget',
          createdAt: 'Date Created',
          subtotal: 'Subtotal',
          costType: 'Cost Type',
          quantity: 'Quantity',
          unitPrice: 'Unit Price',
          taxRate: 'Tax Rate',
          taxAmount: 'Tax Amount',
          total: 'Total',
        },
        emailSendLabel: 'Send',
        emailResendToastMessage: 'The invoice approval notification has successfully been reissued to all valid recipients.',
        emailResendStartedToastMessage: 'Reissuing invoice approval notifications ...',
        invoiceEmailToastTitle: 'Notification',
        workday: {
          heading: 'Workday Integration',
          created: 'Invoice #{invoice_number} was created.',
          failed: 'Failed to create in Workday.',
          retry: 'Retry?',
          unsubmitted: 'An invoice will be created in \'Submitted\' state.',
        }
      },
    },
    'cost-requests': {
      RequestsForCostsHome: {
        sortOptions: {
          requested_at: 'Requested At',
          due_at: 'Due At',
          responded_at: 'Responded At',
        },

        filters: {
          workOrderId: 'Work Order ID',
          contractors: 'Contractors',
          status: 'Status',
          awaitingResponse: 'Awaiting Response',
          site: 'Site',
          owner: 'WO Internal Owner',
          currentUser: 'Current User',
          createdAt: 'Created Date',
          dueAt: 'Due by Date',
          respondedAt: 'Actioned Date',
        },

        fields: {
          id: 'WO #',
          summary: 'Summary',
          site: 'Site',
          contractor: 'Contractor',
          status: 'Status',
          requested: 'Requested',
          due: 'Due',
          responded: 'Responded',
        },

        gridItemTitle: '{type} Request for Work Order #{work_order_id}: {work_order_summary}',

        genericError: 'An error occurred while fetching the Requests for Costs. Please try again',
      },

      CostRequestDisplay: {
        quoteForWorkOrder: 'Quote {work_order_id} {work_order_summary}',
        addQuote: 'Add Quote',
        cancelRequestConfirm: 'Are you sure you wish to cancel the request?',
        viewWorkOrder: 'View Work Order',
      },

      sections: {
        activity: {
          paneId: 'activity',
          title: 'Activity',

          noData: 'There are no events to display',
        },
        assets: { paneId: 'assets', title: 'Assets' },

        details: {
          paneId: 'details',
          title: 'Details',

          workOrderDetails: 'Work Order Details',
          requestDetails: 'Request Details',
          quoteRequestCreatedForWorkOrder: 'Quote request created for ',
          workOrder: 'Work Order {workOrderId}',

          type: 'Type',
          status: 'Status',
          due_date: 'Due by Date',
          contractor: 'Contractor',
          date_requested: 'Date Requested',
          requester: 'Requester',
          responded_at: 'Responded At',
          responder: 'Responder',
          requestMessage: 'Request Message',
          responseMessage: 'Response Message',
          description: 'Description',
          site: 'Site',
          location: 'Location',
          work_order_type: 'Work Order Type',
          priority: 'Contractor SLA',
          service_type: 'Service Type',
          request_type: 'Request Type',

          pending: 'Pending',
        },
        QuotesTable: {
          quotes: 'Quotes',
          addQuote: 'Add Quote',
          reference: 'Reference',
          submitted: 'Submitted',
          status: 'Status',
          subtotal: 'Subtotal',
          noData: 'There are no quotes to display',
        },
      },
    },
    maintainableEntities: {
      MaintainableEntity: {
        add: 'Add Item',
        delete: 'Delete Item',

        createWO: 'Create WO',
        manageTags: 'Manage Tags',
      },
      MaintainableEntityDetail: {
        printsAndCodes: {
          paneId: 'prints-and-codes',
          title: 'Print and Codes',
          noData: 'There are no items to display',
          printsTitle: 'Prints',
          checkInPointPosterTitle: 'Engineer Check In Point Poster',
          checkInPointPosterText: 'Ideally placed where Contractors sign into your location and scanned by engineers to identify them and record Time & Attendance records.',
          qrCodesTitle: 'QR Codes',
          checkInPointQrTitle: 'Engineer Check In Point QR Code',
          checkInPointQrText: 'Ideally placed where Contractors sign into your location and scanned by engineers to identify them and record Time & Attendance records.',
          locationText: 'Locaengineer-checintion',
          workOrderForms: {
            posterTitle: '{formName} - External Work Order Form Poster',
            posterIntro: 'Place this at the relevant location so people can scan and raise a work order request',
            qrTitle: '{formName} - External Work Order Form QR Code',
            qrIntro: 'Place this at the relevant location so people can scan and raise a work order request',
          },
        },
        details: {
          paneId: 'details',
          title: 'Details',
        },
        assets: {
          paneId: 'assets',
          title: 'Assets',
          onlyShowFor: 'Only show assets for {name}',
          createAsset: 'Create Asset',
        },
        documents: {
          paneId: 'documents',
          title: 'Documents',
        },
        workOrders: {
          paneId: 'work-orders',
          title: 'Work Orders',
          hasData: 'The following work orders have been associated with this',
          noData: 'There are no work orders to display',
          checkbox: 'Show only work orders against ',
          created: 'Created',
          status: 'Status',
          engineer: 'Engineer',
          contractor: 'Contractor',
        },
        access: {
          paneId: 'access',
          title: 'Access',
          save: 'Save',
          locationTags: 'Location Tags',
          locationTagsDescription: 'Users with the following location tags will be restricted to viewing this location.',
          saved: 'Access Tags successfully saved.',
          savedTitle: 'Success',
          saveFailed: 'Failed to save Location Tags',
          accessRevoked: 'You no longer have access to this location.',
          accessRevokedTitle: 'Access Revoked',
        },
        fieldset: {
          details: 'Main Details',
          navigation: 'Navigation',
        },
        tree: {
          title: 'Menu',
          paneId: 'tree',
          filter: {
            filter: 'Filter',
            all: 'All',
            spaces: 'Spaces',
            floors: 'Floors',
            buildings: 'Buildings',
            assets: 'Assets',
            systems: 'Systems',
          },
          contextMenu: {
            addBuilding: 'Add Building',
            addSystem: 'Add System',
            addFloor: 'Add Floor',
            addSpace: 'Add Space',
            addAsset: 'Add Asset',
            cloneAsset: 'Clone Asset',
          },
          noAttachedAsset: 'This location has no attached assets.',
        },
        MainDetails: 'Main Details',
        name: 'Name',
        code: 'Code',
        codeDescr: 'The unique code or tag for this location or asset.',
        maintainableEntityType: 'Type',
        parentId: 'Parent',
        serviceType: 'Service Type',
        notes: 'Notes',
        out_of_service_since_date: 'Out of Service Since Date',
        out_of_service_since_date_descr:
          'Enter a date here to mark this location or asset as out of service',
        disposal_date: 'Disposal Date',
        disposal_date_descr: 'Enter a date here to mark this location or asset as disposed.',
        Attributes: 'Attributes',
        condition_grade_id: 'Condition Grade',
        in_storage: 'In Storage',
        manufacturer: 'Manufacturer',
        model: 'Model',
        serial_number: 'Serial Number',
        qty: 'Qty',
        purchase_cost: 'Purchase Cost',
        purchase_date: 'Purchase Date',
        install_date: 'Install Date',
        install_contractor: 'Install Contractor',
        expected_replacement_date: 'Expected Replacement Date',
        warranty_labour_expiry_date: 'Warranty Labour Expiry Date',
        warranty_parts_expiry_date: 'Warranty Parts Expiry Date',
        warranty_details: 'Warranty Details',
        EmptyAttributes: 'There are no attributes for this location or asset',
        building_type_id: 'Building Type',
        built_date: 'Built Date',
        lease_end_date: 'Lease End Date',
        total_space: 'Total Space (m2)',
        usable_space: 'Usable Space (m2)',
        number_of_floors: 'Number Of Floors',
        number_of_lifts: 'Number Of Lifts',
        number_of_parking_spaces: 'Number Of Parking Spaces',
        number_of_meeting_rooms: 'Number Of Meeting Rooms',
        number_of_employees: 'Number Of Employees',
        capacity: 'Capacity',
        opening_times: 'Opening Times',
        disabled_access: 'Disabled Access',
        hazards: 'Hazards',
        site_type_id: 'Site Type',
        confined_space: 'Confined Space',
        emergency_contact_details: 'Emergency Contact Details',
        emergency_procedure: 'Emergency Procedure',
        cost_centre: 'Cost Centre',

        deleteConfirm: 'Are you sure you want to delete this MaintainableEntity?',
      },

      MaintainableEntityHome: {
        fields: {
          code: 'Code',
          name: 'Name',
          site: 'Site',
          manufacturer: 'Manufacturer',
          model: 'Model',
          qty: 'Qty',
          updated: 'Updated',
        },
        filters: {
          name: 'Name',
        },
        sortOptions: {
          name: 'Name',
        },
        createWO: 'Create WO',
        noCommonLocationFound: 'The selected items do not have a common parent location.',
        multiSiteSelected: 'The selected items must be from a single Site',
      },
      modals: {
        AddModal: {
          parentLabel: 'Parent',
          addSingle: 'Add Single',
          addMultiple: 'Add Multiple',
          placeholder: 'Add one {type} per line.',
        },
      },
    },
    workflows: {
      WorkflowView: {
        title: 'Workflow',
        name: 'Name',
      },
      WorkflowsHome: {
        add: 'Add Workflow',
        name: 'Name',
      },
    },
    budgetType: {
      manage: {
        addLabel: 'Add Budget Type',
        genericErrorMessage: 'Something went wrong.',
        title: 'Budget Type',
      },
      fields: {
        name: 'Name',
      },
    },
    workOrderFormRequestType: {
      manage: {
        addLabel: 'Add Request Type',
        genericErrorMessage: 'Something went wrong.',
        title: 'Work Order Form Request Type',
      },
      fields: {
        order: 'Order',
        name: 'Name',
        workOrderRequestType: 'Work Order Request Type',
        serviceType: 'Service Type',
      },
    },
    assetManufacturer: {
      manage: {
        addLabel: 'Add Asset Manufacturer',
        genericErrorMessage: 'Something went wrong.',
        title: 'Asset Manufacturer',
      },
      fields: {
        name: 'Name',
      },
    },
    assetType: {
      manage: {
        addLabel: 'Add Asset Type',
        genericErrorMessage: 'Something went wrong.',
        title: 'Asset Type',
      },
      fields: {
        name: 'Name',
        category: 'Category',
        depreciation: 'Depreciation (months)',
        endOfLife: 'End Of Life (months)',
      },
    },
    asset: {
      createAsset: 'Create Asset',
      fields: {
        asset: 'Asset',
        status: 'Status',
        condition: 'Condition',
        createdAt: 'Created At',
        updatedAt: 'Updated At',
        location: 'Location',
        site: 'Site',
        assetModel: 'Asset Model',
        assetType: 'Asset Type',
        assetCategory: 'Asset Category',
        installDate: 'Install Date',
        purchaseDate: 'Purchase Date',
        purchaseCost: 'Purchase Cost',
        warrantyLabour: 'Warranty Labour',
        warrantyParts: 'Warranty Parts',
        eolDate: 'End of Life',
        depreciationDate: 'Depreciation',
        usefulLifeRemaining: 'Useful Life Remaining',
        usefulLifeRemainingValue: '{months} Months',
        image: 'Image',
        supplier: 'Supplier',
        serialNumber: 'Serial Number',
        tag: 'Asset Tag',
        label: 'Asset Label',
        labelDescription: 'You can scan a barcode or QR code to populate this field',
        notes: 'Notes',
      },
      filters: {
        locationWithSubLocations: 'Location (with sub locations)',
      },
      assetCreate: {
        sections: {
          mainDetails: 'Main Details',
          orderInformation: 'Order Information',
          additionalInformation: 'Additional Information',
        },
        toast: {
          title: 'Asset Created',
          details: 'The following asset was created successfully',
        }
      },
      assetDisplay: {
        notSelectableNotice: 'This asset is not currently selectable',
        panes: {
          details: {
            title: 'Details',
          },
          activity: {
            title: 'Activity',
          },
          recurringWorkOrders: {
            title: 'Recurring Work Orders',
            noData: 'There are currently no recurring work orders associated with this asset',
          },
          workOrders: {
            title: 'Work Orders',
            noData: 'There are currently no work orders associated with this asset',
          },
          files: {
            title: 'Files',
            noData: 'There are currently no files associated with this asset',
            uploadFiles: 'Upload Files',
            selectFiles: 'Select files to upload',
            types: {
              asset: 'Asset Document',
              assetModel: 'Asset Model Document',
            },
            confirmDeleteBody: 'Are you sure you want to delete this file?',
            confirmNavigateAwayBody: 'You have files that have not been uploaded, are you sure you want to leave this page?'
          },
        },
        actions: {
          updateStatus: 'Update Status',
          updateCondition: 'Update Condition',
          createWorkOrder: 'Create Work Order',
          updateLocation: 'Relocate',
        },
      },
      blocks: {
        status: {
          title: 'Status',
        },
        details: {
          title: 'Details',
        },
        location: {
          title: 'Location',
        },
        orderInfo: {
          title: 'Order Info.',
        },
        notes: {
          title: 'Notes',
        }
      },
      scan: {
        title: 'Scan for Asset',
        assetFound: 'Asset Found',
        noResults: 'No asset found',
        createAsset: 'Create Asset',
        searchAgain: 'Search Again',
      },
      bulk: {
        buttonLabel: 'Bulk Update',
      },
      selectLocation: 'Select a Location',
      selectModel: 'Select a Model',
    },
    startWorkChecks: {
      tabbedHeader: {
        inductionTaskList: 'Induction Tasks',
        hazardTaskLists: 'Hazard Task Lists',
        safeToProceedTaskList: 'Safe to Proceed Tasks'
      },
      titles: {
        inductionTaskList: 'Induction Task List',
        hazardTaskLists: 'Hazard Task Lists',
        safeToProceedTaskList: 'Safe to Proceed Task List'
      },
      tasks: {
        form: {
          hazardDropdownPlaceholder: 'Select a Hazard',
          taskInputPlaceholder: 'New task',
          taskLinkInputPlaceholder: 'Task link (optional)'
        },
        emptyState: {
          emptyInduction: 'No Induction Tasks',
          emptyHazard: 'No Hazard Task Lists',
          emptySafeToProceed: 'No Safe to Proceed Tasks'
        },
        sidebar: {
          header: 'New Hazard Task List'
        },
        actions: {
          add: 'Add Task',
          addHazardTask: 'Add Hazard Task List',
          moveOrderUp: 'Move up',
          moveOrderDown: 'Move down',
          delete: 'Delete'
        }
      }
    },
    healthAndSafety: {
      hazards: {
        empty: 'No hazards are currently associated with this work order.',
        header: 'Hazards',
        sidebar: {
          header: 'Add Hazard'
        },
        remove: {
          confirmation: 'Please confirm you want to remove this hazard.'
        }
      },
      rams: {
        header: 'RAMS'
      },
      startWorkChecks: {
        header: 'Start Work Checks'
      }
    },
    workPermit: {
      title: 'Permit to Work Templates',
      create: 'Create New Template',
      edit: 'Edit Template',
      fields: {
        name: 'Name',
        selectable: 'Selectable',
        document: 'Document',
        createdAt: 'Created At',
        updatedAt: 'Updated At',
      },
      replaceDocument: "Replace Document",
    },
  },
  components: {
    budget: {
      descriptionPrefix: 'Description: ',
      tracker: {
        fields: {
          period: 'Period {period}',
          budget: 'Budget',
          costsApproved: 'Costs Approved',
          usedPercent: 'Used %',
          remainingBudget: 'Remaining Budget',
          workInProgress: 'Work in Progress',
          completeUninvoiced: 'Complete Uninvoiced',
        },
        title: 'Live Budget Tracker',
        cannotAllocate: {
          period: {
            overspent: 'You cannot allocate {amount} to ' +
              'this budget because the financial period ' +
              'ending {date} is overspent.',
            willBeOverspent: 'You cannot allocate {amount} to ' +
              'this budget because the financial period ' +
              'ending {date} will be overspent.',
          },
          financialYear: {
            overspent: 'You cannot allocate {amount} to ' +
              'this budget because the financial year ' +
              'ending {date} is overspent.',
            willBeOverspent: 'You cannot allocate {amount} to ' +
              'this budget because the financial year ' +
              'ending {date} will be overspent.',
          },
        },
        warning: {
          period: {
            overspent: 'The financial period ending {date} is overspent.',
            willBeOverspent: 'Allocating {amount} to this budget ' +
              'will result in an overspend against the financial period ' +
              'ending {date}',
          },
          financialYear: {
            overspent: 'The financial year ending {date} is overspent.',
            willBeOverspent: 'Allocating {amount} to this budget ' +
              'will result in an overspend against the financial year ' +
              'ending {date}',
          },
        },
      },
    },
    categoriesList: {
      title: 'Categories',
      noData: 'This part is not displayed in any categories yet.',
    },
    partsList: {
      title: 'Parts',
      noData: 'This category does not have any parts yet.',
    },
    reportFilters: {
      workOrderType: 'Work Order Type',
      workOrderStateStatus: 'Work Order Status',
      site: 'Site',
      contractor: 'Contractor',
      dateFrom: 'Date From',
      dateTo: 'Date To',
      user: 'Engineer',
      servicePriority: 'Contractor SLA',
      reportFilters: 'Report Filters',
    },
    documentManager: {
      documentType: 'Type',
      noDocumentTypes: 'There are no documents associated with this',
      intro: 'The following documents have been associated with this',
      noData: 'No documents available.',
      clickToUpload: 'Click to upload a document',
      upload: 'Upload',
      expired: 'Expired',
      uploadDocument: 'Upload a Document',
      validUntil: 'Valid until',
      save: 'Save',
      saving: 'Saving',
      files: 'Files',
      name: 'Name',
      valid_from: 'Valid From Date',
      addTitle: 'Add Document',
      edit: 'Edit Document',
      delete: 'Are you sure you want to delete this document?',
      view: 'View Document',
      valid_until: 'Valid Until Date',
      validDatesDescription: 'You must enter both dates to give this document a validity period',
      details: 'Details',
      noPermission: 'You do not have permission to manage documents.',
    },
    serviceType: {
      ServiceTypeSelect: {
        add: 'Add Service Type',
        remove: 'Remove Service Type',
      },
    },
    auth: {
      otp: {
        disable: {
          button: 'Disable 2FA',
          header: 'Disable Two-Factor Authentication',
          explanation: `Two-Factor Authentication is enabled for your account, when logging in you will be asked
            for your 6-digit code generated from your registered authentication device.`,
          instruction: `Should you wish to disable Two-Factor Authentication you can do so by entering your
            password below and clicking Disable 2FA.`,
          confirmationMessageHeader: 'Success!',
          confirmationMessage: `Two-Factor Authentication has been disabled for your account. You will no longer be required
            to enter your 6-digit code generated from your authenticator device when logging in. Any
            backup codes available for your account have been deleted and will no longer work.`,
        },
        setup: {
          header: 'Enable Two-Factor Authentication',
          backupCodes: {
            confirmationMessageHeader: 'Success!',
            confirmationMessage:
              'Two-Factor Authentication has been successfully enabled for your account.',
            instruction: `Please copy the following backup codes which can be used as an alternative
              verification method should you lose your authentication device.`,
          },
          form: {
            instruction: 'Enter the 6-digit code generated by your authenticator app.',
          },
          qrCode: {
            instruction: 'Use your authenticator app to scan the following QR code.',
            prompt: 'Once complete click Next.',
          },
        },
      },
      changePassword: {
        passwordChanged: 'Your password was changed.',
      },
      password: {
        password: 'Password',
        recommendations: 'Recommendations',
        validation: {
          weak: 'Password does not meet minimum strength requirement (score: {score} out of 4).',
        },
      },
      login: {
        poweredBy: 'powered by',
        email: {
          label: 'Email / Username',
          placeholder: 'Enter email or username',
        },
        password: {
          label: 'Password',
          placeholder: 'Enter password',
          oldPassword: 'Old Password',
          newPassword: 'New Password',
          passwordConfirmation: 'Confirm New Password',
          passwordConfirmation2: 'Password confirmation',
          changePassword: 'Change Password',
          password: 'Password',
          forgot: 'Forgot',
          reset: 'Reset Password',
          resetDescription: 'Lost your password? Please enter your username. ' +
            'You will receive a link to create a new password via email.',
          resetRequest: 'Request Password Reset',
          resetToastMessage: 'A password reset email has been sent to the email address for {username}' +
            ' with instructions on how to set a new password.',
          resetToastTitle: 'Password reset instructions sent!',
        },
        rememberMe: 'Keep me logged in',
        buttons: {
          login: 'Log in',
          loggingIn: 'Logging in',
        },
        failedCredentials: 'These credentials do not match our records.',
      },
    },

    app: {
      Navigation: {
        expandedInfo: 'Collapse sub items',
        collapsedInfo: 'Expand sub items',
        closeNavigation: 'Close Navigation',
        changePassword: 'Change Password',
        exitSwitchUser: 'Exit Switch User',
        logOut: 'Log Out',
        enable2fa: 'Enable 2FA',
        disable2fa: 'Disable 2FA',
        accountSettings: 'Account Settings',
      },

      Header: {
        searchLabel: 'Search for Work Orders',
        searchPlaceholder: 'Search WOs',
        search: 'Search',
      },

      MobileHeader: {
        openNavigation: 'Open Navigation',
        search: 'Search',
        translate: 'Translate',
      },

      toast: {
        workOrder: {
          createdTitle: 'Work Order Created',
          createdMsg: 'Your new work order was created successfully with the reference',
        },
        basket: {
          add: {
            title: 'Item Added to Basket',
            message: '{name} ({quantity}) has been added to your basket',
          },
          remove: {
            title: 'Item Removed from Basket',
            message: '{name} ({quantity}) has been removed from your basket',
          },
        },
      },
    },

    pwa: {
      RefreshBanner: {
        update: 'Update',
        updatingMsg: 'Updating...',
        updateAvailable: 'Update Available: ',
        autoUpdateIn: 'Automatically updating in {time}',
      },
    },

    site: {
      SiteAccessPermissionsField: {
        none: 'None',
        all: 'All',
        tags: 'Selected Tags',
        sites: 'Selected Sites',
      },
    },

    workOrder: {
      dueLabel: 'Due {relative}',
      summary: {
        WorkOrderSummary: {
          title: 'Work Orders',
          dueDate: 'Due',

          scheduled: 'Scheduled',
          overdue: 'Overdue',
          complete: 'Complete',
          cancelled: 'Cancelled',
          planned: 'Planned',
          requested: 'Requested',

          emptyText: 'There are no { status } work orders',
        },
      },
      transitions: {
        shareAttachmentsLabel: 'Share Attachments',
        shareAttachmentsNote:
          'Allow contractors to see attachments (photos, etc) that have been uploaded to the work order. Internal attachments are never shared with contractors.',
        shareWithContractor: 'Share with contractor',

        AbandonForm: {
          title: 'Abandon',
          loadingMsg: 'Transitioning...',
          reasonLabel: 'Reason',
          transition: 'Transition',
          genericError: 'There was an error while transitioning this Work Order',
        },
        CheckoutEngineersForm: {
          title: 'Check Out Engineers',
          closureReasonLabel: 'What is the resolution?',
          visitNotesLabel: 'Visit Notes',
        },
        CreateRFSForm: {
          title: 'Create Service Request',
          serviceLabel: 'Service',
          messageLabel: 'Message',
          genericError: 'There was an error while creating the Service Request',
        },
        RejectRFSForm: {
          title: 'Reject Service Request',
          loadingMsg: 'Reject',
          reasonLabel: 'Reason',
          transition: 'Transition',
          genericError: 'There was an error while rejecting the Service Request',
        },
        AddRams: {
          genericError: 'There was an error while adding the RAMS',
        },
        AssignEngineerForm: {
          title: 'Assign Engineer',
          engineerLabel: 'Engineer',
          genericError: 'There was an error while allocating the engineer',
        },
        AssignContractorForm: {
          title: 'Assign Contractor',
          contractorLabel: 'Contractor',
          genericError: 'There was an error while assigning the contractor',
        },
        AssignServiceTypeForm: {
          title: 'Assign Service Type',
          serviceTypeLabel: 'Please confirm service type is correct',
          genericError: 'There was an error while confirming the service type',
        },
        AssignServiceForm: {
          title: 'Assign Service',
          serviceLabel: 'Service',
          genericError: 'There was an error while assigning the service',
          budgetSpendInfo: 'A real-time view of your budget spend so far'
        },
        AddTransitionMessageForm: {
          title: 'Add Message',
          messageLabel: 'Message',
          genericError: 'There was an error while transitioning the work order',
        },
        CreateCPRForm: {
          title: 'Create Quote Request',
          contractorLabel: 'Contractor',
          typeLabel: 'Type',
          messageLabel: 'Message',
          dueByLabel: 'Due By',
          genericError: 'There was an error while creating the Quote Request',
        },
        SetCompliantDateForm: {
          title: 'Mark Compliant',
          compliantDateLabel: 'Compliant Date',
          genericError: 'There was an error while marking as compliant',
          realignNote: 'Based on the template interval period of {interval}, your next due by date is expected to be {due_date}, which is different to your next due by date.',
          noMoreSpawnNote: 'Note: Your template has an End Date of {date}, therefore no more future work orders will be created.',
          realignInstruction: 'Click the button below if you wish to re-align your schedule.',
          realignButton: 'Re-align schedule',
          nextScheduledDueDateLabel: 'Next Scheduled Due by Date',
        },
        SubmitForComplianceReviewForm: {
          documentUpload: 'Document Upload',
          ensureComplianceDocumentsUploaded:
            'Please ensure you have uploaded all relevant documents and information to the work order using the comments function before submitting this work order for review.',
          genericError: 'There was an error while submitting for review',
        },
        AssignWorkflowAndServiceForm: {
          workOrderTypeLabel: 'Work Order Type',
          resourceLabel: 'Resource',
          engineerLabel: 'Engineer',
          genericError: 'There was an error while transitioning this Work Order',
          noContractors:
            "There are no contractors available, or you don't have the required permissions to see such contractors.",
        },
        ShowDialog: {
          genericError: 'An error occurred while attempting to transition the work order.',
        },
        transitionErrors: {
          findingLocation: {
            title: 'Searching for Location...',
            body:
              'We are currently trying locate your position - this could take up to a minute. If your location is found and is within the correct area, this box will close automatically.',
          },
          missing: {
            title: 'Something went wrong- no coordinates supplied.',
            body: 'Please provide your coordinates.',
          },
          invalid: {
            title: 'Something went wrong- incorrect area.',
            body: `It seems you are not currently within the specified area in
            which you can perform this action. If you think you are then please contact your helpdesk for further assistance.`,
          },
          location: {
            title: 'Something went wrong- browser not given permission',
            body: 'Please allow the browser to detect your location and try again.',
          },
        },
      },
      duplicate: {
        DuplicateWorkOrderList: {
          title: 'Similar Requests ({ count })',
          intro:
            'Please check that this is not a duplicate work request or a problem resulting from a recent callout.',
        },
        DuplicateWorkOrder: {
          createdAt: 'Created At',
          closedAt: 'Closed At',
        },
      },
    },

    spendLimitIncreaseRequest: {
      requestSpendLimitIncrease: 'Request Uplift',
      desiredSpendLimitLabel: 'Desired Spend Limit',
      increaseRequestedSummary: '{user} has requested an increase to the spend limit on the work order from {from}, to {to}',
      confirmApprove: 'Are you sure you want to approve the spend limit increase request?',
      confirmCancel: 'Are you sure you want to cancel the spend limit increase request?',
      rejectionModalTitle: 'Reject Spend Limit Increase Request',
      rejectionModalReasonLabel: 'Reason for Rejection',
    },

    cost: {
      ItemForm: {
        addTitle: 'Add Cost',
        editTitle: 'Edit Cost',
        costType: 'Cost Type',
        description: 'Description',
        quantity: 'Quantity',
        unitPrice: 'Unit Price (ex. Tax)',
        taxRate: 'Tax Rate',
        issue_date: 'Issue Date',

        save: 'Save',

        deleteConfirm: 'Are you sure you want to delete this item?',
      },
      Items: {
        quantity: 'Quantity',
        none: 'None',
        addItem: 'Add Item',
      },
    },

    costRequest: {
      RejectModal: {
        title: 'Reject',
        doneButton: 'Reject & Send',
        comments: 'Comments',
        commentsPlaceholder: 'Please add any reasons here...',
      },
    },

    costProposal: {
      CostProposalModal: {
        addTitle: 'Add Quote',
        type: 'Type',
        contractor: 'Contractor',
        reference: 'Reference',
        comments: 'Comments',
        commentsPlaceholder: 'Please add any comments here...',
        expiry_date: 'Expiry Date',
        saveAndSend: 'Save & Send',
        subTotal: 'Subtotal (ex. Tax)',
        tax: 'Plus Tax',
        totals: 'Totals',
        items: 'Items',
        saving: 'Saving...',
        detailsTitle: 'Details',
        none: 'None',
        rejectedByWithMessage: '{user} rejected this on {date}:',
        rejectedBy: '{user} rejected this on {date}',
        leadDaysLabel: 'Lead Time (working days)',
        leadDaysSubtitle: 'Following approval of the quote, this is how long the Contractor requires before starting work',
        completionDaysLabel: 'Time to Complete (working days)',
        completionDaysSubtitle: 'This is how long it will take to complete the job once started',
      },

      AuthorisationInfoModal: {
        title: 'Authorise Quote',
      },

      Approval: {
        viewTitle: 'View Quote',
        reviewTitle: 'Review Quote',
        cannotReviewWarning:
          'You are not authorised to review this Quote. Please assign the Work Order to another approver.',
        requestAuthorisationConfirm: 'Are you sure you wish to submit the quote for authorisation?',
        reject: 'Reject',
        requestAuthorisation: 'Request Authorisation',
        contractorAttendance: 'Contractor attendance expected no later than {attendance} and completion expected no later than {completion}.',
        authorise: 'Authorise',
        approve: 'Approve',
      },

      RejectModal: {
        title: 'Reject',
        doneButton: 'Reject & Send',
        comments: 'Comments',
        commentsPlaceholder: 'Please add any reasons here...',
        genericError: 'An error occurred while rejecting this quote. Please try again',
      },
    },

    expense: {
      ItemForm: {
        quantity: 'Quantity',
        unitPrice: 'Unit Price (ex Tax)',
        taxRate: 'Tax Rate',
      },
      ExpenseTable: {
        amount: 'Amount',
        description: 'Description',
        supplier: 'Supplier'
      },
      ExpenseModal: {
        addTitle: 'Add Expense',
        viewTitle: 'View Expense',
        editTitle: 'Edit Expense',
        supplier: 'Supplier',
        description: 'Description',
        purchased_at: 'Purchase Date',
        saveAndSend: 'Save & Send',
        subTotal: 'Subtotal (ex. Tax)',
        tax: 'Plus Tax',
        totals: 'Totals',
        saving: 'Saving...',
        detailsTitle: 'Details',
        none: 'None',

        deleteConfirm: 'Are you sure you want to delete this item?',

        genericError: 'An error occurred while submitting the Expense. Please try again',
      },
    },

    invoice: {
      InvoiceModal: {
        title: 'Add Invoice',
        detailsTitle: 'Details',

        reference: 'Reference',
        referencePlaceholder: 'Your reference',
        description: 'Description',
        descriptionPlaceholder: "What's this for?",
        dateIssued: 'Date Issued',
        dueDate: 'Date Due',
        items: 'Items',
        save: 'Save',
        saving: 'Saving...',
      },
      Approval: {
        viewTitle: 'View Invoice',
        reviewTitle: 'Review Invoice',
        cannotReviewWarning:
          'You are not authorised to review this Invoice. Please assign the Work Order to another approver.',
        approvalConfirm: 'Are you sure you wish to approve the invoice?',
        reject: 'Reject',
        approve: 'Approve',
      },
      RejectModal: {
        title: 'Reject',
        doneButton: 'Reject',
        comments: 'Comments',
        commentsPlaceholder: 'Please add any reasons here...',
        genericError: 'An error occurred while rejecting this invoice. Please try again',
      },
      InvoiceForm: {
        none: 'None',
      },
    },

    invoiceLine: {
      SubmitModal: {
        title: 'Submit Costs',
        intro:
          'Please enter the reference and description you would like to appear on the invoice for these costs.',
        reference: 'Reference',
        description: 'Description',
      },
      ApproveModal: {
        title: 'Approve Costs',
        doneButton: 'Approve',
        usingInvoicingType: 'This work order is using { invoicingType }',
        switchInvoicingType: 'Switch to { invoicingType }',
        consolidatedInvoicing: 'consolidated invoicing',
        standaloneInvoicing: 'standalone invoicing',
        assignToWhichInvoice: 'Which invoice should the costs be assigned to?',
        selectAnExistingInvoice: 'Select an existing invoice',
        createANewInvoice: 'Create a new invoice',
        reference: 'Reference',
        description: 'Description',
        closingDate: 'Closing Date',
        approveInvoice: 'Approve Invoice',
        missingInvoiceApprovePermission:
          'You cannot automatically approve this invoice because your role does not have the { perm } permission.',
        invoiceApprovePermission: 'Invoice Approve',
        insufficientInvoiceApprovalLimit:
          'You cannot automatically approve this invoice because it has a net value of { netTotal }, which exceeds your invoice approval limit of { approvalLimit }.',
        errorRetrievingInvoices:
          'An error occurred while retrieving existing invoices. Please try again or create a new invoice.',
        poNumber: 'PO Number',
        financialPeriod: 'Financial Period',
      },
      RejectModal: {
        title: 'Reject',
        doneButton: 'Reject',
        comments: 'Comments',
        commentsPlaceholder: 'Please add any reasons here...',
      },
      QueryModal: {
        title: 'Query',
        doneButton: 'Query',
        comments: 'Comments',
        commentsPlaceholder: 'Please add any reasons here...',
      },
    },

    maintainableEntity: {
      locationFilter: {
        selectSite: 'Select site',
        selectLocations: 'Select locations',
      },
    },

    contractor: {
      contractorPOGeneratorField: {
        intro: 'Enable custom PO numbers for this contractor?',
        poNumberPrefix: 'PO Prefix',
        poNumberNext: 'Next Sequence Number',
        poNumberPrefixNumberHelp: 'This value must be unique',
        poNumberNextHelp: 'Leave empty to use the Work Order number',
      },
    },

    attachment: {
      AttachmentPreview: {
        zoomIn: 'Zoom In',
        zoomOut: 'Zoom Out',
        rotateAnticlockwise: 'Rotate Anticlockwise',
        rotateClockwise: 'Rotate Clockwise',
        closePreview: 'Close Preview',
        deleteAttachment: 'Delete Attachment',
      },
      AttachmentList: {
        uploadPhotoOrVideo: 'Upload a Photo or Video',
        uploadDocument: 'Upload a Document',
        removeAttachment: 'Remove Attachment',
        removingAttachment: 'Removing Attachment',

        removeError: 'An error occurred while removing this attachment. Please try again.',

        tooLarge: '{name} is too large. Maximum file size is {fileSize}MB.',

        uploadProgress: 'Uploading file {complete} of {total}',
        uploadComplete: 'Upload complete!',

        minimumAttachmentsError: 'You must upload another file before this one can be removed.',

        files: {
          complete: 'Upload complete',
          uploading: 'Uploading',
          pending: 'Upload pending',
        },
      },

      AttachmentListView: {
        deleteAttachment: 'Delete Attachment',
        confirmDeleteAttachment: 'Are you sure you wish to delete this attachment?',

        actions: {
          preview: 'Preview',
          remove: 'Remove',
        },
      },
    },

    dashboardWidgets: {
      SpendWidget: {
        allSites: 'All Sites',
        status: 'Status',
        total: 'Total',
        other: 'Other',
        totalForYear: 'Total for Year',
        expand: 'Expand Toolbar',
        collapse: 'Collapse Toolbar',
      },
      SpendByPeriodWidget: {
        title: 'Spend by Period',
        totalAmount: 'Total Amount',
        amountForPeriod: 'Amount for Period',
      },
      SpendByCostTypeWidget: {
        title: 'Current Spend',
        totalAmount: 'Total Amount',
        amountForPeriod: 'Amount for Period',
        period: 'Period',
        ytd: 'YTD',
        noFPAvailable: 'No Financial Period available',
        approved: 'Costs Approved',
        submitted: 'Costs Submitted',
        uninvoiced: 'Work In Progress',
      },
      PivotTableWidget: {
        metrics: {
          count: 'Count',
          invoiceTotal: 'Invoiced (Legacy)',
          approvedCostsTotal: 'Approved Costs Total',
        },
      },
    },

    rams: {
      selector: {
        title: 'Manage RAMS',
        intro: 'If the appropriate RAMS have been uploaded previously, choose them below.',
        noRams: 'There are no previously uploaded RAMS to select',
        uploadRams: 'Upload New RAMS',
        buttonLabel: 'Save',
        ramsUploadedMessage: 'RAMS uploaded successfully',
      },
      uploader: {
        title: 'Upload RAMS',
        type: 'Type',
        site: 'Site',
        hazard: 'Hazard',
        buttonLabel: 'Upload RAMS',
      },
    },

    workPermits: {
      selector: {
        title: 'Issue Permits to Work',
        intro: 'Please select the relevant permits.',
        noWorkPermits: 'There are no permits to select',
        errorTitle: 'Error - Unable to issue permits',
        noContractor: 'There is no contractor associated with this work order',
        noContractorEmail: 'There is no email address associated with this contractor',
        send: 'Send',
      },
    },

    bulk: {
      update: {
        title: 'Bulk Update',
        details: {
          title: 'Bulk Update Details',
          description: 'Choose the bulk update(s) you wish to perform on the selected {count} items.',
          fieldItem: {
            update: 'Update this field',
          }
        },
        confirm: {
          title: 'Bulk Update Confirmation',
          description: 'Please review and confirm that you wish to proceed with the following bulk update changes.',
          detailsTitle: 'Details',
          detailsDescription: 'You are about to update the following fields:',
          itemsTitle: 'Items',
          itemsDescription: 'These updates will be applied to:',
        },
        processing: {
          title: 'Bulk Update Processing',
          description: 'Your bulk update request is being processed below. If you close this screen the request will continue to be processed in the background.',
        },
        completed: {
          title: 'Bulk Update Completed',
          description: 'Your bulk update request has completed.',
          successfulMessage: '{count} items were updated successfully',
          failedMessage: '{count} items failed to update',
        }
      }
    }
  },

  ux: {
    moreRows: {
      more: 'Show {count} items',
      modalTitle: 'See All',
      collapse: 'Hide {count} items',
    },
    pagination: {
      firstPage: 'Go to first page',
      lastPage: 'Go to last page',
      previousPage: 'Go to previous page',
      nextPage: 'Go to next page',
      summary: 'Showing {from} - {to} of {total}',
    },
    grid: {
      loading: 'Loading...',
      emptyText: 'There are no records to show',
      selected: 'Selected',
      notSelected: 'Not selected',
      viewItem: 'View "{title}"',
      selectAllItems: 'Select all rows',
      cols: {
        name: 'Name',
        details: 'Details',
      },
      pagination: {
        firstPage: 'Go to first page',
        lastPage: 'Go to last page',
        previousPage: 'Go to previous page',
        nextPage: 'Go to next page',
        summary: 'Showing {from} - {to} of {total}',
      },
    },
    gridToolbar: {
      sortButton: 'Sort By',
      selectedItems: '{count} item selected | {count} items selected',
      filterButton: 'Filter',
      removeFilter: 'Remove Filter',
      others: 'others',
      addFilter: 'Add Filter',
      deletedEntity: 'Deleted {entity} (#{id})',
      enabledSwitch: 'Show disabled records',
    },
    gridFilterPanel: {
      title: 'Filter',
      done: 'Apply Filter',
    },
    form: {
      TreeFieldModal: {
        defaultTitle: 'Top Level',
      },

      list: {
        ListCard: {
          topLevel: 'Top Level',
          thisLevel: 'This Level',
        },
        ListSearchCard: {
          emptyText: 'No results matching your search.',
        },
      },

      DateRange: {
        any: 'Any',
        range: 'Range',
        relative: 'Relative',

        date: 'Date',

        fromDatePlaceholder: 'From date',
        toDatePlaceholder: 'To date',

        relativeOptions: {
          pleaseSelect: 'Please Select',
          today: 'Today',
          last24Hours: 'Last 24 hours',
          last72Hours: 'Last 72 hours',
          last7Days: 'Last 7 days',
          last30Days: 'Last 30 days',
          last90Days: 'Last 90 days',
          weekToDate: 'Week To Date',
          monthToDate: 'Month To Date',
          yearToDate: 'Year To Date',
          afterToday: 'After Today',
          afterNow: 'After Now',
          beforeToday: 'Before Today',
          beforeNow: 'Before Now',
          tomorrow: 'Tomorrow',
          next24Hours: 'Next 24 hours',
          next72Hours: 'Next 72 hours',
          next7Days: 'Next 7 days',
          next14Days: 'Next 14 days',
          next30Days: 'Next 30 days',
          next90Days: 'Next 90 days',
          restOfWeek: 'Rest Of Week',
          nextWeek: 'Next Week',
          restOfMonth: 'Rest Of Month',
          nextMonth: 'Next Month',
          restOfYear: 'Rest Of Year',
        },
      },
      TimeRange: {
        minutes: 'minutes',
        hours: 'hours',
        days: 'days',
        weeks: 'weeks',
        minutes_ago: 'minutes ago',
        hours_ago: 'hours ago',
        days_ago: 'days ago',
        weeks_ago: 'weeks ago',
        within: 'Within the last',
        more_than: 'More than',
        between: 'Between',
        ago: 'ago',
        and: 'and',
      },
    },
    error: {
      title: 'An error occurred:',
    },

    ActionSheet: {
      cancel: 'Cancel',
    },

    calendar: {
      Calendar: {
        title: 'Work Order Category',
        showEmpty: 'Show Empty',
        hideEmpty: 'Hide Empty',

        totals: 'Totals',

        statusItems: '{ status } items',

        statuses: {
          overdue: 'Overdue',
          scheduled: 'Scheduled',
          complete: 'Complete',
          cancelled: 'Cancelled',
          requested: 'Requested',
          planned: 'Planned',
        },
      },
    },

    QuickFilterPopover: {
      done: 'Filter',
    },

    AddComment: {
      addComment: 'Add Comment',
      writeCommentPlaceholder: 'Write a comment...',
      writeCommentToPlaceholder: 'Write a comment to {name}...',
      attachFile: 'Attach File',
      internal: 'INTERNAL',
      savingMsg: 'Saving...',
      send: 'Send',
      filesRemaining: 'files remaining',
      uploadComplete: 'Upload complete!',
    },

    EntitySummary: {
      summary: 'Summary',
    },

    tree: {
      typeFlags: {
        ASSET_CATEGORY: 'Category',
        ASSET_TYPE: 'Type',
        ASSET_MODEL: 'Model',
      }
    },

    barcodeScanner: {
      title: 'Barcode Scanner',
      instructions: 'Scan a Barcode using the camera on your device',
      barcodeFound: 'Barcode found',
      use: 'Use',
      cancel: 'Cancel',
      error: "No Camera Detected.",
      errorHelp: "Please ensure that:",
      errorHelpItems: {
        camera: 'There is a suitable camera on this device',
        permission: 'You have allowed access to the camera',
        inUse: 'The camera is not in use by another application',
      }
    },
  },

  enums: {
    BudgetSiteSelectionType: {
      all: 'All',
      tag: 'Tag',
      site: 'Site',
    },
    CostRequestStatus: {
      open: 'Open',
      accepted: 'Accepted',
      cancelled: 'Cancelled',
      rejected: 'Rejected',
    },
    ApplicableEntityType: {
      workOrder: 'Work Order',
      contractor: 'Contractor',
    },
    MaintainableEntityType: {
      floor: 'Floor',
      building: 'Building',
      site: 'Site',
      space: 'Space',
      system: 'System',
      asset: 'Asset',
    },
    CommentVisibility: {
      public: 'Public',
      internal: 'Internal',
      requester_only: 'Requester Only',
    },
    CostProposalStatus: {
      submitted: 'Submitted',
      authorising: 'Authorising',
      authorised: 'Authorised',
      approved: 'Approved',
      rejected: 'Rejected',
    },
    CostItemType: {
      callout: 'Callout',
      labour: 'Labour',
      parts: 'Parts',
      travel: 'Travel',
      other: 'Other',
    },
    InvoiceStatus: {
      submitted: 'Submitted',
      approved: 'Approved',
      rejected: 'Rejected',
      exported: 'Exported',
      void: 'Void',
      draft: 'Draft',
      paid: 'Paid',
    },
    LinkType: {
      relates_to: 'Relates to',
      blocks: 'Blocks',
      blocked_by: 'Blocked by',
      duplicates: 'Duplicates',
      duplicated_by: 'Duplicated by',
    },
    UserType: {
      admin: 'Admin',
      contractor: 'Contractor',
    },
    ExpenseStatus: {
      submitted: 'Submitted',
      approved: 'Approved',
    },
    ContractorHoursDays: {
      weekdays: 'Weekdays',
      saturday: 'Saturday',
      sunday: 'Sunday',
    },
    ContractorBillingRounding: {
      roundUpToTheNearestXMinutes: 'Round up to the nearest {count} minute',
      roundUpToTheNearestXMinutes_plural: 'Round up to the nearest {count} minutes', // TODO: roundUpToTheNearestXMinutes_plural: 'Round up to the nearest {count} minutes' does not work,
      roundUpToTheNearestHour: 'Round up to the nearest hour',
    },
    WorkOrderCostStatus: {
      draft: 'Draft',
      pending: 'Pending',
      approved: 'Approved',
      queried: 'Queried',
      rejected: 'Rejected',
      submitted: 'Submitted',
      triaging: 'Triaging',
      closed: 'Closed',
      sourcing: 'Sourcing',
      inProgress: 'In Progress',
      review: 'Reviewing',
      invoicing: 'Invoicing',
    },
    WorkOrderBillingStatus: {
      nonBillable: 'Non-Billable',
      draft: 'Draft',
      submitted: 'Submitted',
      approved: 'Approved',
      queried: 'Queried',
      rejected: 'Rejected',
    },
    OpenQuoteRequestsFilterOptions: {
      none: 'None',
      noResponse: 'No Response',
      partialResponse: 'Partial Response',
      fullResponse: 'Full Response',
    },
    ComplianceSubmissionApprovalStatus: {
      awaiting: 'Not Submitted',
      submitted: 'Submitted',
      approved: 'Approved',
      rejected: 'Rejected',
    },
    CostStatus: {
      uninvoiced: 'Work in Progress',
      pending: 'Complete Uninvoiced',
      approved: 'Approved',
    },
    ComplianceSubmissionStatus: {
      pass: 'Pass',
      pass_with_recommendations: 'Pass With Recommendations',
      fail: 'Fail',
    },
    ContractorBillingType: {
      none: 'None',
      fixed: 'Fixed',
      asset: 'Asset',
      time: 'Time Based',
    },
    RamsType: {
        generic: 'Generic',
        siteSpecific: 'Site Specific',
    },
    EngineerType: {
      main: 'Main Engineer',
      assistant: 'Assistant Engineer',
    }
  },

  backendEnums: {
    slaType: {
      response_time: 'Response Time',
      time_to_attend: 'Time to Attend',
      time_to_fix: 'Time to Fix',
    },
    slaState: {
      hit: 'Hit',
      at_risk: 'At Risk',
      breached: 'Breached',
      pending: 'Pending',
    },
  },

  titles: {
    ResetPassword: 'Reset Password',
    ReleaseNotes: 'Release Notes',
    Workflows: 'Workflows',
    ReportsLoading: 'Loading Report',
    ReportsHome: 'Reports',
    DocumentTypes: 'Document Types',
    DocumentList: 'Documents',
    ComplianceDocumentList: 'Compliance Documents',
    WorkOrderList: 'Work Orders',
    WorkOrderPPM: 'Work Order Calendar',
    WorkOrderDisplay: 'Work Order #{id}: {summary}',
    WorkOrderCreate: 'Create Work Order',
    WorkOrderLoading: 'Loading Work Order...',
    DashboardDisplay: 'Dashboard',
    WorkOrderCreationRules: 'Work Order Creation Rules',
    InvoiceNamingConventions: 'Invoice Naming Conventions',
    RecurringWorkOrderList: 'Recurring Work Orders',
    RecurringWorkOrderCreate: 'Create Recurring Work Order',
    RecurringWorkOrderLoading: 'Loading Recurring Work Order...',
    RecurringWorkOrderDisplay: 'Recurring Work Order #{id}',
    Dashboards: 'Dashboards',
    RequestForServicesHome: 'Service Requests',
    RequestForServiceDisplay: 'RFS For Work Order {id}',
    RequestForServiceLoading: 'Loading Request for Service...',
    BulkCostApproval: 'Bulk Cost Approval',
    CostRequestsHome: 'Quote Requests',
    CostRequestLoading: 'Loading Quote Request...',
    CostRequestDisplay: 'Quote Request for Work Order {id}',
    DashboardLoading: 'Loading...',
    Settings: 'Settings',

    InvoiceGroup: 'Invoice Groups',

    UsersHome: 'Users',
    UserDisplay: '{name}',
    UserCreate: 'Create User',
    UserLoading: 'Loading User...',

    PartsOrderCategoryCreate: 'Create',
    PartsOrderCategoryLoading: 'Loading category...',
    PartsOrderCategoryDisplay: '{name}',

    PartsOrderParts: 'Parts',
    PartsOrderPartCreate: 'Create',
    PartsOrderPartLoading: 'Loading part...',
    PartsOrderPartDisplay: '{name}',
    RecentPartOrders: 'Recent Orders',

    ServicePriorities: 'Contractor SLAs',

    Suppliers: 'Suppliers',

    SitesHome: 'Sites',
    SiteDisplay: '{name}',
    SiteCreate: 'Create Site',
    SiteLoading: 'Loading Site...',

    ContractorsHome: 'Contractors',
    ContractorDisplay: '{name}',
    ContractorCreate: 'Create Contractor',
    ContractorLoading: 'Loading Contractor..',

    MaintainableEntityHome: 'Assets',
    MaintainableEntityDisplay: '{name}',
    MaintainableEntityCreate: 'Create Location/Asset',
    MaintainableEntityLoading: 'Loading Location/Asset ..',

    CostCode: 'Cost Codes',

    Landlords: 'Landlords',

    WorkOrderType: 'Work Order Types',

    Roles: 'Roles',

    Notifications: 'Notifications',

    Tags: 'Tags',

    TagTypes: 'Tag Types',

    FinancialPeriods: 'Financial Periods',

    Organisation: 'Organisations',

    ServiceType: 'Service Types',

    Budget: 'Budgets',

    BudgetTypes: 'Budget Types',

    ChangePassword: 'Change Password',

    CostProposalsHome: 'Quotes',

    InvoicesHome: 'Invoices',

    ForgotPassword: 'Forgot Password',

    OtpVerify: 'One Time Password',

    Login: 'Login',

    AuditLogHome: 'Audit Log',
    AuditLogDisplay: 'Log #{id}',
    AuditLogLoading: 'Loading Audit',

    AccountSettings: 'Account Settings',

    PartsOrderCategories: 'Parts Order Categories',

    Hazards: 'Hazards',

    AssetCategory: 'Asset Categories',
    AssetTypes: 'Asset Types',
    AssetSupplier: 'Asset Suppliers',
    AssetManufacturers: 'Asset Manufacturers',
    AssetModels: 'Asset Models',
    AssetModelsLoading: 'Loading Model...',
  },

  validation: {
    required: 'The {attribute} field is required',
    minLength: 'The {attribute} field must be at least {min} characters long',
  },

  attributes: {
    name: 'Name',
    engineer_type: 'Engineer Type',
    enabled: 'Enabled',
    currency: 'Currency',
    standard_hours_callout: 'Standard Hours - Callout',
    standard_hours_labour: 'Standard Hours - Labour',
    out_of_hours_callout: 'Out of Hours - Callout',
    out_of_hours_labour: 'Out of Hours - Labour',
  },

  auditLog: {
    title: 'Audit Log',
    events: {
      created: 'Created',
      updated: 'Updated',
      deleted: 'Deleted',
      invited: 'Invited',
      spawner: {
        spawned: 'Spawned',
      },
    },
    description: {
      changedFrom: "changed from '{from}' to '{to}'",
      invited: 'A welcome email process was triggered to the user',
      spawner: {
        spawned: 'Generated Work Order',
        error: 'Spawner Error',
      },
    },
  },

  confirmationModal: {
    title: 'Confirmation Required',
    body: 'Please confirm that you want to continue with this action',
    cancelLabel: 'Cancel',
    confirmLabel: 'Continue',
  },

  breadcrumbs: {
    settings: {
      audit: 'Log #{id}',
    },
    costRequests: {
      costRequestForWorkOrder: 'Quote Request for Work Order #{id}'
    },
    requestForService: {
      rfsForWorkOrder: 'Service Request For Work Order #{id}',
    },
  },

  events: {
    wasChangedFrom: 'was changed from',
    to: 'to',
    the: 'the',
    wasAttachedToThe: 'was attached to the',
    wasRemovedFromThe: 'was removed from the',
    removed: 'removed',
    models: {
      'App\\Models\\Asset': {
        // keeping the below in place as examples when needed
        // name: 'ABC',
        // fields: {
        //   warranty_parts: 'XYZ',
        // }
        relationships: {
          media: 'File',
          maintainableEntity: 'Location',
          conditionGrade: 'Condition',
        }
      },
      'App\\Models\\PlannedWorkTemplate': {
        relationships: {
          maintainableEntities: 'Location',
        }
      },
      'App\\Models\\RecurringWorkOrder': {
        name: 'Schedule',
        relationships: {
          Owner: 'Owner',
          Watchers: 'Watcher',
          calloutTaxRate: 'Callout Tax Rate',
          labourTaxRate: 'Labour Tax Rate',
          partsTaxRate: 'Parts Tax Rate',
          travelTaxRate: 'Travel Tax Rate',
          otherTaxRate: 'Other Tax Rate',
        },
        fields: {
          next_due_date: 'Next Due by Date',
          next_unspawned_due_date: 'Next Unspawned Due by Date',
        }
      },
      'App\\Models\\RemedialAction': {
        relationships: {
          assignee: 'Assignee',
          maintainableEntity: 'Location',
        }
      },
    }
  }
};

export default EN;
// module.exports = EN;
