import Base from './Base.js';

export default function WorkOrderLinkTypeCreator(i18n) {
  class WorkOrderBillingStatus extends Base {}

  WorkOrderBillingStatus.initEnum({
    BLOCKING: {
      value: 'NON_BLOCKING',
      display: i18n.global.t('views.work-orders.LinkedWorkOrderTypes.recommendedWorks'),
    },
    DRAFT: {
      value: 'BLOCKING',
      display: i18n.global.t('views.work-orders.LinkedWorkOrderTypes.requiredWorks'),
    },
    RECALL:
    {
      value: 'RECALL',
      display: 'Recall',
    },
  });

  return WorkOrderBillingStatus;
}
