import {FontAwesomeIcon, FontAwesomeLayers} from '@fortawesome/vue-fontawesome';
import {library, dom} from '@fortawesome/fontawesome-svg-core';
import {faTachometerAlt} from '@fortawesome/pro-regular-svg-icons/faTachometerAlt';
import {faWrench} from '@fortawesome/pro-regular-svg-icons/faWrench';
import {faUndo} from '@fortawesome/pro-regular-svg-icons/faUndo';
import {faRedo} from '@fortawesome/pro-regular-svg-icons/faRedo';
import {faMoneyBill} from '@fortawesome/pro-regular-svg-icons/faMoneyBill';
import {faHome} from '@fortawesome/pro-regular-svg-icons/faHome';
import {faProjectDiagram} from '@fortawesome/pro-regular-svg-icons/faProjectDiagram';
import {faBuilding} from '@fortawesome/pro-regular-svg-icons/faBuilding';
import {faDollarSign} from '@fortawesome/pro-regular-svg-icons/faDollarSign';
import {faPlus} from '@fortawesome/pro-regular-svg-icons/faPlus';
import {faPlusCircle} from '@fortawesome/pro-regular-svg-icons/faPlusCircle';
import {faReceipt} from '@fortawesome/pro-regular-svg-icons/faReceipt';
import {faBox} from '@fortawesome/pro-regular-svg-icons/faBox';
import {faTruck as faTruckHollow} from '@fortawesome/pro-regular-svg-icons/faTruck';
import {faCog} from '@fortawesome/pro-regular-svg-icons/faCog';
import {faStepForward} from '@fortawesome/pro-regular-svg-icons/faStepForward';
import {faCogs} from '@fortawesome/pro-regular-svg-icons/faCogs';
import {faPenSquare} from '@fortawesome/pro-regular-svg-icons/faPenSquare';
import {faKey} from '@fortawesome/pro-regular-svg-icons/faKey';
import {faSignOutAlt} from '@fortawesome/pro-regular-svg-icons/faSignOutAlt';
import {faTimes} from '@fortawesome/pro-regular-svg-icons/faTimes';
import {faTimesCircle} from '@fortawesome/pro-regular-svg-icons/faTimesCircle';
import {faLink} from '@fortawesome/pro-regular-svg-icons/faLink';
import {faUnlink} from '@fortawesome/pro-regular-svg-icons/faUnlink';
import {faBars} from '@fortawesome/pro-regular-svg-icons/faBars';
import {faSearch} from '@fortawesome/pro-regular-svg-icons/faSearch';
import {faSearchPlus} from '@fortawesome/pro-regular-svg-icons/faSearchPlus';
import {faSearchMinus} from '@fortawesome/pro-regular-svg-icons/faSearchMinus';
import {faSpinner} from '@fortawesome/pro-regular-svg-icons/faSpinner';
import {faUser} from '@fortawesome/pro-regular-svg-icons/faUser';
import {faChevronDoubleRight} from '@fortawesome/pro-regular-svg-icons/faChevronDoubleRight';
import {faChevronRight} from '@fortawesome/pro-regular-svg-icons/faChevronRight';
import {faChevronLeft} from '@fortawesome/pro-regular-svg-icons/faChevronLeft';
import {faChevronDoubleLeft} from '@fortawesome/pro-regular-svg-icons/faChevronDoubleLeft';
import {faChevronDown} from '@fortawesome/pro-regular-svg-icons/faChevronDown';
import {faSort} from '@fortawesome/pro-regular-svg-icons/faSort';
import {faLongArrowAltDown} from '@fortawesome/pro-regular-svg-icons/faLongArrowAltDown';
import {faLongArrowAltUp} from '@fortawesome/pro-regular-svg-icons/faLongArrowAltUp';
import {faArrowToBottom} from '@fortawesome/pro-regular-svg-icons/faArrowToBottom';
import {faArrowsAltV} from '@fortawesome/pro-regular-svg-icons/faArrowsAltV';
import {faFilter} from '@fortawesome/pro-regular-svg-icons/faFilter';
import {faPhone} from '@fortawesome/pro-regular-svg-icons/faPhone';
import {faPhone as faPhoneSolid} from '@fortawesome/pro-solid-svg-icons/faPhone';
import {faMapMarkerAlt} from '@fortawesome/pro-regular-svg-icons/faMapMarkerAlt';
import {faEnvelope} from '@fortawesome/pro-regular-svg-icons/faEnvelope';
import {faEye} from '@fortawesome/pro-regular-svg-icons/faEye';
import {faEyeSlash} from '@fortawesome/pro-regular-svg-icons/faEyeSlash';
import {faDownload} from '@fortawesome/pro-regular-svg-icons/faDownload';
import {faPencil} from '@fortawesome/pro-regular-svg-icons/faPencil';
import {faPencilAlt} from '@fortawesome/pro-regular-svg-icons/faPencilAlt';
import {faPencilRuler} from '@fortawesome/pro-regular-svg-icons/faPencilRuler';
import {faSave} from '@fortawesome/pro-regular-svg-icons/faSave';
import {faCaretLeft} from '@fortawesome/pro-regular-svg-icons/faCaretLeft';
import {faCircle} from '@fortawesome/pro-regular-svg-icons/faCircle';
import {faCircle as faCircleSolid} from '@fortawesome/pro-solid-svg-icons/faCircle';
import {faCheck} from '@fortawesome/pro-regular-svg-icons/faCheck';
import {faGripVertical} from '@fortawesome/pro-regular-svg-icons/faGripVertical';
import {faCheckCircle} from '@fortawesome/pro-regular-svg-icons/faCheckCircle';
import {faMinus} from '@fortawesome/pro-regular-svg-icons/faMinus';
import {faTrash} from '@fortawesome/pro-regular-svg-icons/faTrash';
import {faSignInAlt} from '@fortawesome/pro-regular-svg-icons/faSignInAlt';
import {faExclamationTriangle} from '@fortawesome/pro-regular-svg-icons/faExclamationTriangle';
import {faExclamationCircle} from '@fortawesome/pro-regular-svg-icons/faExclamationCircle';
import {faMoneyBillAlt} from '@fortawesome/pro-regular-svg-icons/faMoneyBillAlt';
import {faYenSign} from '@fortawesome/pro-regular-svg-icons/faYenSign';
import {faFolder} from '@fortawesome/pro-regular-svg-icons/faFolder';
import {faFolderOpen} from '@fortawesome/pro-regular-svg-icons/faFolderOpen';
import {faFile} from '@fortawesome/pro-regular-svg-icons/faFile';
import {faFileDownload} from '@fortawesome/pro-regular-svg-icons/faFileDownload';
import {faFileUpload} from '@fortawesome/pro-regular-svg-icons/faFileUpload';
import {faFileAlt} from '@fortawesome/pro-regular-svg-icons/faFileAlt';
import {faChevronCircleUp} from '@fortawesome/pro-regular-svg-icons/faChevronCircleUp';
import {faChevronCircleRight} from '@fortawesome/pro-regular-svg-icons/faChevronCircleRight';
import {faChevronCircleDown} from '@fortawesome/pro-regular-svg-icons/faChevronCircleDown';
import {faEdit} from '@fortawesome/pro-regular-svg-icons/faEdit';
import {faFlagCheckered} from '@fortawesome/pro-regular-svg-icons/faFlagCheckered';
import {faBullseye} from '@fortawesome/pro-regular-svg-icons/faBullseye';
import {faCalendarDay} from '@fortawesome/pro-regular-svg-icons/faCalendarDay';
import {faConciergeBell} from '@fortawesome/pro-regular-svg-icons/faConciergeBell';
import {faConciergeBell as faConciergeBellSolid} from '@fortawesome/pro-solid-svg-icons/faConciergeBell';
import {faQuoteRight} from '@fortawesome/pro-regular-svg-icons/faQuoteRight';
import {faCalendarAlt} from '@fortawesome/pro-regular-svg-icons/faCalendarAlt';
import {faInfo} from '@fortawesome/pro-regular-svg-icons/faInfo';
import {faRetweetAlt} from '@fortawesome/pro-regular-svg-icons/faRetweetAlt';
import {faClock} from '@fortawesome/pro-regular-svg-icons/faClock';
import {faUserTag} from '@fortawesome/pro-regular-svg-icons/faUserTag';
import {faShield} from '@fortawesome/pro-regular-svg-icons/faShield';
import {faShieldCheck} from '@fortawesome/pro-regular-svg-icons/faShieldCheck';
import {faLock} from '@fortawesome/pro-regular-svg-icons/faLock';
import {faLockOpen} from '@fortawesome/pro-regular-svg-icons/faLockOpen';
import {faArrowRight} from '@fortawesome/pro-regular-svg-icons/faArrowRight';
import {faCalendarCheck} from '@fortawesome/pro-regular-svg-icons/faCalendarCheck';
import {faComment} from '@fortawesome/pro-regular-svg-icons/faComment';
import {faCommentAltDots} from '@fortawesome/pro-regular-svg-icons/faCommentAltDots';
import {faCommentAltDollar} from '@fortawesome/pro-regular-svg-icons/faCommentAltDollar';
import {faPaperPlane} from '@fortawesome/pro-regular-svg-icons/faPaperPlane';
import {faCamera} from '@fortawesome/pro-regular-svg-icons/faCamera';
import {faTasks} from '@fortawesome/pro-regular-svg-icons/faTasks';
import {faListUl} from '@fortawesome/pro-regular-svg-icons/faListUl';
import {faBorderAll} from '@fortawesome/pro-regular-svg-icons/faBorderAll';
import {faClipboardUser} from '@fortawesome/pro-regular-svg-icons/faClipboardUser';
import {faUserShield} from '@fortawesome/pro-regular-svg-icons/faUserShield';
import {faUserHardHat} from '@fortawesome/pro-regular-svg-icons/faUserHardHat';
import {faEnvelopeOpenDollar} from '@fortawesome/pro-regular-svg-icons/faEnvelopeOpenDollar';
import {faPlayCircle} from '@fortawesome/pro-regular-svg-icons/faPlayCircle';
import {faPauseCircle} from '@fortawesome/pro-regular-svg-icons/faPauseCircle';
import {faRedoAlt} from '@fortawesome/pro-regular-svg-icons/faRedoAlt';
import {faUserCog} from '@fortawesome/pro-regular-svg-icons/faUserCog';
import {faUsersCog} from '@fortawesome/pro-regular-svg-icons/faUsersCog';
import {faBell} from '@fortawesome/pro-regular-svg-icons/faBell';
import {faMoneyCheckEdit} from '@fortawesome/pro-regular-svg-icons/faMoneyCheckEdit';
import {faImage} from '@fortawesome/pro-regular-svg-icons/faImage';
import {faVideo} from '@fortawesome/pro-regular-svg-icons/faVideo';
import {faFileWord} from '@fortawesome/pro-regular-svg-icons/faFileWord';
import {faFileExcel} from '@fortawesome/pro-regular-svg-icons/faFileExcel';
import {faFilePdf} from '@fortawesome/pro-regular-svg-icons/faFilePdf';
import {faFileExclamation} from '@fortawesome/pro-regular-svg-icons/faFileExclamation';
import {faUserTie} from '@fortawesome/pro-regular-svg-icons/faUserTie';
import {faFileInvoiceDollar} from '@fortawesome/pro-regular-svg-icons/faFileInvoiceDollar';
import {faFileInvoice} from '@fortawesome/pro-regular-svg-icons/faFileInvoice';
import {faBallot} from '@fortawesome/pro-regular-svg-icons/faBallot';
import {faHandHoldingUsd} from '@fortawesome/pro-regular-svg-icons/faHandHoldingUsd';
import {faClipboardList} from '@fortawesome/pro-regular-svg-icons/faClipboardList';
import {faClipboardListCheck} from '@fortawesome/pro-regular-svg-icons/faClipboardListCheck';
import {faPlusSquare} from '@fortawesome/pro-regular-svg-icons/faPlusSquare';
import {faMinusSquare} from '@fortawesome/pro-regular-svg-icons/faMinusSquare';
import {faListOl} from '@fortawesome/pro-regular-svg-icons/faListOl';
import {faHourglassHalf} from '@fortawesome/pro-regular-svg-icons/faHourglassHalf';
import {faMinusCircle} from '@fortawesome/pro-regular-svg-icons/faMinusCircle';
import {faTruckLoading} from '@fortawesome/pro-regular-svg-icons/faTruckLoading';
import {faAddressCard} from '@fortawesome/pro-regular-svg-icons/faAddressCard';
import {faSitemap} from '@fortawesome/pro-regular-svg-icons/faSitemap';
import {faTags} from '@fortawesome/pro-regular-svg-icons/faTags';
import {faTag} from '@fortawesome/pro-regular-svg-icons/faTag';
import {faTag as faTagSolid} from '@fortawesome/pro-solid-svg-icons/faTag';
import {faAbacus} from '@fortawesome/pro-regular-svg-icons/faAbacus';
import {faStopwatch} from '@fortawesome/pro-regular-svg-icons/faStopwatch';
import {faStopwatch as faStopwatchSolid} from '@fortawesome/pro-solid-svg-icons/faStopwatch';
import {faClipboardCheck} from '@fortawesome/pro-regular-svg-icons/faClipboardCheck';
import {faQuestionCircle} from '@fortawesome/pro-regular-svg-icons/faQuestionCircle';
import {faFileSpreadsheet} from '@fortawesome/pro-regular-svg-icons/faFileSpreadsheet';
import {faLayerGroup} from '@fortawesome/pro-regular-svg-icons/faLayerGroup';
import {faLayerPlus} from '@fortawesome/pro-regular-svg-icons/faLayerPlus';
import {faClone} from '@fortawesome/pro-regular-svg-icons/faClone';
import {faExchange} from '@fortawesome/pro-regular-svg-icons/faExchange';
import {faFileCertificate} from '@fortawesome/pro-regular-svg-icons/faFileCertificate';
import {faClock as faClockSolid} from '@fortawesome/pro-solid-svg-icons/faClock';
import {faCog as faCogSolid} from '@fortawesome/pro-solid-svg-icons/faCog';
import {faTimesCircle as faTimesCircleSolid} from '@fortawesome/pro-solid-svg-icons/faTimesCircle';
import {faSearch as faSearchSolid} from '@fortawesome/pro-solid-svg-icons/faSearch';
import {faTrash as faTrashSolid} from '@fortawesome/pro-solid-svg-icons/faTrash';
import {faExclamationTriangle as fasExclamationTriangle} from '@fortawesome/pro-solid-svg-icons/faExclamationTriangle';
import {faCaretDown as fasCaretDown} from '@fortawesome/pro-solid-svg-icons/faCaretDown';
import {faCaretUp as fasCaretUp} from '@fortawesome/pro-solid-svg-icons/faCaretUp';
import {faCheckCircle as fasCheckCircle} from '@fortawesome/pro-solid-svg-icons/faCheckCircle';
import {faQuestion as fasQuestion} from '@fortawesome/pro-solid-svg-icons/faQuestion';
import {faEllipsisV} from '@fortawesome/pro-regular-svg-icons/faEllipsisV';
import {faPaperclip} from '@fortawesome/pro-regular-svg-icons/faPaperclip';
import {faHammer} from '@fortawesome/pro-regular-svg-icons/faHammer';
import {faKeyboard} from '@fortawesome/pro-regular-svg-icons/faKeyboard';
import {faClipboard} from '@fortawesome/pro-regular-svg-icons/faClipboard';
import {faBolt} from '@fortawesome/pro-regular-svg-icons/faBolt';
import {faExternalLink} from '@fortawesome/pro-regular-svg-icons/faExternalLink';
import {faPen} from '@fortawesome/pro-regular-svg-icons/faPen';
import {faPrint} from '@fortawesome/pro-regular-svg-icons/faPrint';
import {faMoneyCheckEditAlt} from '@fortawesome/pro-regular-svg-icons/faMoneyCheckEditAlt';
import {faRandom} from '@fortawesome/pro-regular-svg-icons/faRandom';
import {faVoteYea} from '@fortawesome/pro-regular-svg-icons/faVoteYea';
import {faTypewriter} from '@fortawesome/pro-regular-svg-icons/faTypewriter';
import {faAlarmClock} from '@fortawesome/pro-regular-svg-icons/faAlarmClock';
import {faCopy} from '@fortawesome/pro-regular-svg-icons/faCopy';
import {faSatelliteDish} from '@fortawesome/pro-regular-svg-icons/faSatelliteDish';
import {faRobot} from '@fortawesome/pro-regular-svg-icons/faRobot';
import {faQrcode} from '@fortawesome/pro-regular-svg-icons/faQrcode';
import {faHandPointRight} from '@fortawesome/pro-regular-svg-icons/faHandPointRight';
import {faCube} from '@fortawesome/pro-regular-svg-icons/faCube';
import {faMagic} from '@fortawesome/pro-regular-svg-icons/faMagic';
import {faConveyorBelt, faEmptySet} from '@fortawesome/pro-regular-svg-icons';
import {faShapes} from '@fortawesome/pro-regular-svg-icons';
import {faThumbtack} from "@fortawesome/pro-regular-svg-icons";
import {faThumbtack as faThumbtackSolid} from "@fortawesome/pro-solid-svg-icons";
import {faFileImport} from "@fortawesome/pro-regular-svg-icons";
import {faListAlt} from "@fortawesome/pro-regular-svg-icons";
import {faPercent} from '@fortawesome/pro-regular-svg-icons/faPercent';
import {faThumbsUp} from '@fortawesome/pro-regular-svg-icons';
import {faThumbsDown} from '@fortawesome/pro-regular-svg-icons';
import {
  faGlobe,
  faHeart as faHeartHollow,
  faInfoCircle,
  faInfoSquare,
  faShuttleVan as faShuttleVanHollow,
  faFilePlus,
  faArrowSquareRight,
  faTruckMoving,
  faConveyorBeltAlt,
  faMedal,
  faHandHoldingBox,
  faSyncAlt,
  faBarcodeRead,
  faCalendarExclamation,
  faCalendarTimes,
  faCalendarEdit,
  faTasksAlt,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faShuttleVan as faShuttleVanFilled,
  faExpand,
  faExpandAlt,
  faExpandArrows,
  faExpandArrowsAlt,
  faShoppingBasket,
  faHeart as faHeartFilled,
  faBarcode,
  faTruck as faTruckFilled,
  faStar,
  faEnvelopeSquare,
  faExclamation,
  faAlarmClock as faAlarmClockFilled,
  faTasksAlt as faTasksAltSolid,
} from '@fortawesome/pro-solid-svg-icons';

library.add(
  faEmptySet,
  faTachometerAlt,
  faWrench,
  faLayerGroup,
  faLayerPlus,
  faDollarSign,
  faInfoCircle,
  faPlus,
  faPlusCircle,
  faReceipt,
  faBuilding,
  faBox,
  faTruckHollow,
  faTruckFilled,
  faCog,
  faCogs,
  faKey,
  faConveyorBelt,
  faSignOutAlt,
  faCopy,
  faTimes,
  faTimesCircle,
  faLink,
  faUnlink,
  faBars,
  faShapes,
  faSearch,
  faGripVertical,
  faMoneyBill,
  faSpinner,
  faUser,
  faChevronRight,
  faChevronDoubleRight,
  faChevronLeft,
  faChevronDoubleLeft,
  faChevronDown,
  faCheck,
  faCheckCircle,
  faCircle,
  faCircleSolid,
  faSort,
  faArrowsAltV,
  faLongArrowAltDown,
  faLongArrowAltUp,
  faArrowToBottom,
  faFilter,
  faPhone,
  faPhoneSolid,
  faMapMarkerAlt,
  faEnvelope,
  faEye,
  faEyeSlash,
  faDownload,
  faPencil,
  faPencilAlt,
  faPencilRuler,
  faRedo,
  faUndo,
  faSave,
  faCaretLeft,
  faMinus,
  faPenSquare,
  faTrash,
  faSignInAlt,
  faExclamation,
  faExclamationTriangle,
  faExclamationCircle,
  faMoneyBillAlt,
  faYenSign,
  faFolder,
  faFolderOpen,
  faFile,
  faFileAlt,
  faSearchPlus,
  faSearchMinus,
  faChevronCircleUp,
  faChevronCircleRight,
  faChevronCircleDown,
  faEdit,
  faFlagCheckered,
  faBullseye,
  faCalendarDay,
  faFileInvoice,
  faConciergeBell,
  faConciergeBellSolid,
  faQuoteRight,
  faCalendarAlt,
  faInfo,
  faRetweetAlt,
  faClock,
  faUserTag,
  faShield,
  faShieldCheck,
  faLock,
  faLockOpen,
  faArrowRight,
  faFileDownload,
  faFileUpload,
  faCalendarCheck,
  faComment,
  faCommentAltDots,
  faCommentAltDollar,
  faPaperPlane,
  faCamera,
  faTasks,
  faListUl,
  faBorderAll,
  faClipboardUser,
  faUserShield,
  faUserHardHat,
  faEnvelopeOpenDollar,
  faPlayCircle,
  faPauseCircle,
  faRedoAlt,
  faUserCog,
  faUsersCog,
  faBell,
  faMoneyCheckEdit,
  faImage,
  faVideo,
  faFileWord,
  faFilePdf,
  faFileExcel,
  faFileExclamation,
  faUserTie,
  faFileInvoiceDollar,
  faStepForward,
  faBallot,
  faClockSolid,
  faHandHoldingUsd,
  faClipboardList,
  faClipboardListCheck,
  faPlusSquare,
  faMinusSquare,
  faListOl,
  faTimesCircleSolid,
  faSearchSolid,
  faTrashSolid,
  faHourglassHalf,
  faMinusCircle,
  faTruckLoading,
  faAddressCard,
  faSitemap,
  faTags,
  faTag,
  faAbacus,
  faStopwatch,
  faStopwatchSolid,
  faClipboardCheck,
  fasQuestion,
  faQuestionCircle,
  faFileSpreadsheet,
  faClone,
  faTagSolid,
  faFileCertificate,
  faHome,
  faProjectDiagram,
  fasExclamationTriangle,
  fasCaretDown,
  fasCaretUp,
  faExchange,
  faEllipsisV,
  faPaperclip,
  faHammer,
  faCogSolid,
  faKeyboard,
  faClipboard,
  fasCheckCircle,
  faBolt,
  faExternalLink,
  faPen,
  faPrint,
  faMoneyCheckEditAlt,
  faVoteYea,
  faRandom,
  faTypewriter,
  faAlarmClock,
  faAlarmClockFilled,
  faSatelliteDish,
  faRobot,
  faExpand,
  faExpandAlt,
  faExpandArrows,
  faExpandArrowsAlt,
  faHandPointRight,
  faShoppingBasket,
  faShuttleVanFilled,
  faShuttleVanHollow,
  faStar,
  faHeartFilled,
  faHeartHollow,
  faBarcode,
  faBarcodeRead,
  faInfoSquare,
  faFilePlus,
  faEnvelopeSquare,
  faGlobe,
  faQrcode,
  faArrowSquareRight,
  faTruckMoving,
  faConveyorBeltAlt,
  faMedal,
  faHandHoldingBox,
  faSyncAlt,
  faCube,
  faMagic,
  faThumbtack,
  faThumbtackSolid,
  faFileImport,
  faListAlt,
  faPercent,
  faCalendarExclamation,
  faCalendarTimes,
  faCalendarEdit,
  faTasksAlt,
  faTasksAltSolid,
  faThumbsUp,
  faThumbsDown,
);

dom.watch();

export default {
  install(app) {
    app.component('font-awesome-icon', FontAwesomeIcon);
    app.component('font-awesome-layers', FontAwesomeLayers)
  }
};
