<template>
  <div class="text-left">
    <template v-if="workOrder">
      <b-alert v-if="allErrors && allErrors.length > 0" variant="danger" show>
        <h4>{{ $t('views.login.error') }}</h4>

        <ul class="ml-4">
          <li v-for="(error, index) in allErrors" :key="index">
            <span :key="error">{{ error }}</span>
          </li>
        </ul>
      </b-alert>

      <b-form-group v-if="form.parent_work_order_id" label-for="link-type">
        <template #label>
          <Required>
            {{ $t('views.work-orders.LinkedWorkOrderTypes.title') }}
          </Required>
        </template>
        <b-form-radio-group
          id="link-type"
          v-model="form.link_type"
          class="border rounded p-3 single-radio bg-white"
          stacked
          :options="linkTypeOptions"
          aria-describedby="link-type"
          name="link-type-options"
          @change="onFieldUpdate('link_type', $event)"
        ></b-form-radio-group>
      </b-form-group>

      <b-form-group v-if="!form.parent_work_order_id" label-for="wo-edit-site_id-input">
        <template #label>
          <Required>
            {{ $t('views.work-orders.WorkOrderDisplay.site') }}
          </Required>
        </template>
        <CustomDropdown
          id="wo-edit-site-input"
          v-model="form.site_id"
          data-ref="wo-edit-site-input"
          :placeholder="$t('common.forms.emptyOption')"
          aria-describedby="site_id-feedback"
          :initial-options="sites"
          :multiple="false"
          :reposition="true"
          value-field="id"
          text-field="name"
          translate="no"
        />
      </b-form-group>

      <b-form-group
        v-if="locationRoot && locationRoot.children.length"
        label-for="wo-edit-primary_location_id-input"
      >
        <template #label>
          <Required>
            {{ $t('views.work-orders.WorkOrderDisplay.location') }}
          </Required>
        </template>

        <TreeField
          id="wo-edit-primary_location_id-input"
          v-model="v$.form.primary_location_id.$model"
          :items="locationRoot.children"
          :compare-nodes="compareTreeNodes"
          :empty-text="$t('common.forms.emptyOption')"
          :disabled="loading"
          :root-node-name="`${selectedSiteName} (${$t(
            'views.work-orders.WorkOrderEdit.locationWholeSite',
          )})`"
          :root-node-read-only="locationRoot.read_only"
          :state="!v$.form.primary_location_id.$error && null"
          aria-describedby="wo-edit-primary_location_id-feedback"
          @blur="v$.form.primary_location_id.$touch()"
          @input="onFieldUpdate('primary_location_id', $event)"
        />

        <b-form-invalid-feedback
          v-if="v$.form.primary_location_id.$invalid"
          id="wo-edit-primary_location_id-feedback"
          :state="v$.form.primary_location_id.$invalid"
        >
          <p v-if="!v$.form.primary_location_id.required">
            {{
              $t('common.forms.validation.required', {
                field: this.$t('views.work-orders.WorkOrderDisplay.location'),
              })
            }}
          </p>
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group label-for="wo-edit-service_type_id-input">
        <template #label>
          <Required>
            {{ $t('views.work-orders.WorkOrderDisplay.serviceType') }}
          </Required>
        </template>

        <TreeField
          id="wo-edit-service_type_id-input"
          v-model="v$.form.service_type_id.$model"
          :items="serviceTypes"
          :compare-nodes="compareTreeNodes"
          :root-node-name="$t('views.service-types.modals.ServiceTypeModal.entityName')"
          :parent-select="false"
          :root-select="false"
          :empty-text="$t('common.forms.emptyOption')"
          :disabled="loading"
          :state="!v$.form.service_type_id.$error && null"
          aria-describedby="wo-edit-service_type_id-feedback"
          @blur="v$.form.service_type_id.$touch()"
          @input="onFieldUpdate('service_type_id', $event)"
          @input-full-name="serviceTypeFullName=$event"
        />

        <b-form-invalid-feedback
          id="wo-edit-service_type_id-feedback"
          :state="v$.form.service_type_id.$invalid"
        >
          <p v-if="!v$.form.service_type_id.required">
            {{
              $t('common.forms.validation.required', {
                field: this.$t('views.work-orders.WorkOrderDisplay.serviceType'),
              })
            }}
          </p>
        </b-form-invalid-feedback>
      </b-form-group>

      <template v-if="$featureFlag.WORK_ORDER_DUPLICATE_FRONTEND || true">
        <DuplicateWorkOrderList
          v-if="form.service_type_id && effectivePrimaryLocationId"
          data-ref="duplicate-work-orders"
          :service-type-id="form.service_type_id"
          :primary-location-id="effectivePrimaryLocationId"
        />
      </template>

      <b-form-group v-if="locationRoot && storeAssets.length" label-for="asset_id-input">
        <template #label>
          {{ $t('views.work-orders.WorkOrderDisplay.assets') }}
          ({{ $t('common.forms.optional') }})
        </template>
        <AssetSelector
          id="wo-edit-asset_id-input"
          v-model="v$.form.asset_ids.$model"
          :location="v$.form.primary_location_id.$model"
          :maintainable-entities-tree="maintainableEntitiesTree"
          :assets="storeAssets"
          :empty-text="$t('common.forms.emptyOption')"
          :state="!v$.form.asset_ids.$error && null"
          @input="onFieldUpdate('asset_ids', $event)"
        ></AssetSelector>
      </b-form-group>

      <b-form-group label-for="wo-edit-work_order_request_type_id-input">
        <template #label>
          <Required>
            {{ $t('views.work-orders.WorkOrderDisplay.requestType') }}
          </Required>
        </template>

        <CustomDropdown
          id="wo-edit-work_order_request_type_id-input"
          v-model="v$.form.work_order_request_type_id.$model"
          :disabled="loading"
          :state="!v$.form.work_order_request_type_id.$error && null"
          data-ref="wo-edit-work_order_request_type_id-input"
          :placeholder="$t('common.forms.emptyOption')"
          aria-describedby="wo-edit-work_order_request_type_id-feedback"
          :initial-options="workOrderRequestTypes"
          :close-on-select="true"
          :multiple="false"
          value-field="id"
          text-field="name"
          @selected="updateDefaultAttachments($event)"
        />

        <b-form-invalid-feedback
          id="wo-edit-work_order_request_type_id-feedback"
          :state="v$.form.work_order_request_type_id.$invalid"
        >
          <p v-if="!v$.form.work_order_request_type_id.required">
            {{
              $t('common.forms.validation.required', {
                field: this.$t('views.work-orders.WorkOrderDisplay.requestType'),
              })
            }}
          </p>
        </b-form-invalid-feedback>
      </b-form-group>

      <template v-if="site && selectedRequestType && selectedRequestType.emergency">
        <b-form-group label-for="emergency-input">
          <b-form-checkbox
            id="emergency-input"
            :checked="v$.form.emergency.$model"
            :value="true"
            :unchecked-value="false"
            @input="onFieldUpdate('emergency', $event)"
          >
            {{ $t('views.work-orders.WorkOrderDisplay.isThisAnEmergencyWorkOrder') }}
          </b-form-checkbox>
        </b-form-group>

        <WorkOrderCreateEmergency
          v-if="v$.form.emergency.$model"
          :work-order="v$.form.$model"
          :site="site"
          :service-type-id="v$.form.service_type_id.$model"
          :work-order-type-id="workOrderTypeId"
          @modify-work-order="onFieldUpdate"
          @formvalidate="emergencyFormValidity = $event"
          @set-creation-permitted="emergencyCreationPermitted = $event"
        />
      </template>

      <template v-if="!v$.form.emergency.$model || emergencyCreationPermitted">
        <b-form-group
          v-if="$featureFlag.WORK_ORDER_PRIORITY_FRONTEND"
          label-for="wo-edit-work_order_priority_id-input"
        >
          <template #label>
            <Required>
              {{ $t('views.work-orders.WorkOrderDisplay.workOrderPriority') }}
            </Required>
          </template>

            <CustomDropdown
            id="wo-edit-work_order_priority_id-input"
            v-model="v$.form.work_order_priority_id.$model"
            :disabled="loading"
            :state="!v$.form.work_order_priority_id.$error && null"
            aria-describedby="wo-edit-work_order_priority_id-feedback"
            :initial-options="workOrderPriorities"
            value-field="id"
            text-field="name"
            :multiple="false"
            :close-on-select="true"
            data-ref="wo-edit-work_order_priority_id-input"
            :placeholder="$t('common.forms.selectAnOption')"
            @onClosed="v$.form.work_order_priority_id.$touch()"
            @selected="onFieldUpdate('work_order_priority_id', $event)"
            />

          <b-form-invalid-feedback
            id="wo-edit-work_order_priority_id-feedback"
            :state="v$.form.work_order_priority_id.$invalid"
          >
            <p v-if="!v$.form.work_order_priority_id.required">
              {{
                $t('common.forms.validation.required', {
                  field: this.$t('views.work-orders.WorkOrderDisplay.workOrderPriority'),
                })
              }}
            </p>
          </b-form-invalid-feedback>
        </b-form-group>

        <ServiceSelector
          v-if="!v$.form.emergency.$model && availableServices.length > 0"
          :currency="site.Organisation.currency"
          :options="availableServices"
          :value="v$.form.requested_service_id.$model"
          :force-specification="true"
          @input="onFieldUpdate('requested_service_id', $event)"
          @selected-sla-type-changed="onFieldUpdate('sla_type_id', $event)"
        />

        <b-form-group label-for="wo-edit-summary-input">
          <template #label>
            <Required>
              {{ $t('views.work-orders.WorkOrderDisplay.summary') }}
            </Required>
          </template>
          <b-form-input
            id="wo-edit-summary-input"
            v-model="v$.form.summary.$model"
            :disabled="loading"
            :state="!v$.form.summary.$error && null"
            aria-describedby="wo-edit-summary-feedback"
            trim
            required
            @blur="v$.form.summary.$touch()"
            @input="onFieldUpdate('summary', $event)"
          />
          <b-form-invalid-feedback
            v-if="v$.form.summary.$error"
            id="wo-edit-summary-feedback"
            :state="v$.form.summary.$error"
          >
            <p v-if="!v$.form.summary.required">
              <!-- eslint-disable-next-line max-len -->
              {{
                $t('common.forms.validation.required', {
                  field: $t('views.work-orders.WorkOrderDisplay.summary'),
                })
              }}
            </p>
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group label-for="wo-edit-description-input">
          <template #label>
            <Required>
              {{ $t('views.work-orders.WorkOrderDisplay.description') }}
            </Required>
          </template>
          <b-form-textarea
            id="wo-edit-description-input"
            v-model="v$.form.description.$model"
            :disabled="loading"
            :state="!v$.form.description.$error && null"
            aria-describedby="wo-edit-description-feedback"
            :rows="5"
            trim
            required
            @blur="v$.form.description.$touch()"
            @input="onFieldUpdate('description', $event)"
          />
          <b-form-invalid-feedback
            v-if="v$.form.description.$error"
            id="wo-edit-description-feedback"
            :state="v$.form.description.$error"
          >
            <p v-if="!v$.form.description.required">
              <!-- eslint-disable-next-line max-len -->
              {{
                $t('common.forms.validation.required', {
                  field: $t('views.work-orders.WorkOrderDisplay.description'),
                })
              }}
            </p>
          </b-form-invalid-feedback>
          <div v-if="!v$.form.emergency.$model && $featureFlag.AI_WORK_ORDER_SUGGESTIONS">
            <div
              v-if="v$.form.summary.$model
                && v$.form.description.$model
                && (serviceTypeFullName || selectedAssets.length)"
            >
              <SpinButton
                loading-msg="Thinking..."
                :loading="suggestionGenerating"
                variant="primary"
                class="mt-2 get-help-button"
                @click="suggestSolution"
              >
                <font-awesome-icon :icon="['far', 'magic']" size="sm" />
                &nbsp; AI Helper
              </SpinButton>
              <div v-if="suggestionGenerating || suggestionGenerated">
                <Card class="mt-3 mb-1 suggestion-box">
                  <template #content>
                    <div v-if="suggestionGenerating">
                      <span class="font-weight-bold">Generating suggestions...</span>
                      <span><b-spinner class="ml-2" variant="primary" /></span>
                    </div>
                    <div v-else>
                      <div v-if="suggestions.length">
                        <span class="font-weight-bold mb-2">Before raising this work order you might want to try the following:</span>
                        <ul class="mt-2">
                          <li v-for="suggestion in suggestions" :key="suggestion" class="mt-2">
                            <span class="font-weight-bold">{{ suggestion.title }}:</span>
                            <span>&nbsp;{{ suggestion.description }}</span>
                          </li>
                        </ul>
                      </div>
                      <div v-else>
                        No suggestions found.
                      </div>
                    </div>
                  </template>
                </Card>
                <span class="small text-muted">
                  <em>These steps are generated automatically by a third party. Do not attempt any repairs that require a skilled technician.</em>
                </span>
                <div v-if="suggestions.length">
                  <strong>Was this helpful?</strong>
                  <div v-if="suggestionHelpfulSubmitted">
                    Thanks for letting us know!
                  </div>
                  <div v-else>
                    <span class="mr-2">
                      <b-button
                        variant="primary"
                        @click="submitHelpful(true)"
                      >
                        <font-awesome-icon
                          :icon="['far', 'thumbs-up']"
                        />
                      </b-button>
                    </span>
                    <span>
                      <b-button
                        variant="primary"
                        @click="submitHelpful(false)"
                      >
                        <font-awesome-icon
                          :icon="['far', 'thumbs-down']"
                        />
                      </b-button>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-form-group>

        <b-form-group
          v-if="hasPermission('WorkOrder_Manage')"
          label-for="wo-edit-reporting_user_id-form-dropdown"
        >
          <template #label>
            <Required>
              {{ $t('views.work-orders.WorkOrderDisplay.requestingUser') }}
            </Required>
          </template>

          <CustomDropdown
            id="wo-edit-reporting_user_id-form-dropdown"
            v-model="v$.form.reporting_user_id.$model"
            :disabled="loading"
            aria-describedby="wo-edit-reporting_user_id-feedback"
            :initial-options="reporterUsers"
            :allow-empty="false"
            :search="true"
            :class="{ 'is-invalid': v$.form.reporting_user_id.$error }"
            :placeholder="$t('common.forms.emptyOption')"
            translate="no"
            @onClosed="v$.form.reporting_user_id.$touch()"
            @selected="onFieldUpdate('reporting_user_id', $event)"
          />
          <b-form-invalid-feedback
            v-if="v$.form.reporting_user_id.$error"
            id="wo-edit-reporting_user_id-feedback"
            :state="v$.form.reporting_user_id.$error"
          >
            <p v-if="!v$.form.reporting_user_id.required">
              <!-- eslint-disable-next-line max-len -->
              {{
                $t('common.forms.validation.required', {
                  field: $t('views.work-orders.WorkOrderDisplay.requestingUser'),
                })
              }}
            </p>
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group v-if="!v$.form.emergency.$model" label-for="wo-edit-point_of_contact-input">
          <template #label>
            <Required v-if="mandatoryPointOfContact">
              {{ $t('views.work-orders.WorkOrderDisplay.pointOfContact') }}
            </Required>
            <span v-else>
              {{ $t('views.work-orders.WorkOrderDisplay.pointOfContact') }}
            </span>
          </template>

          <b-form-input
            id="wo-edit-point_of_contact-input"
            v-model="v$.form.point_of_contact.$model"
            :disabled="loading"
            :state="!v$.form.point_of_contact.$error && null"
            aria-describedby="wo-edit-point_of_contact-feedback"
            trim
            required
            @blur="v$.form.point_of_contact.$touch()"
            @input="onFieldUpdate('point_of_contact', $event)"
          />

          <b-form-invalid-feedback
            v-if="v$.form.point_of_contact.$error"
            id="wo-edit-point_of_contact-feedback"
            :state="v$.form.point_of_contact.$error"
          >
            <p v-if="!v$.form.point_of_contact.required">
              <!-- eslint-disable-next-line max-len -->
              {{
                $t('common.forms.validation.required', {
                  field: $t('views.work-orders.WorkOrderDisplay.pointOfContact'),
                })
              }}
            </p>
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          v-if="form.parent_work_order_id && !v$.form.emergency.$model"
          label-for="wo-edit-external_reference-input"
        >
          <template #label>
            {{ $t('views.work-orders.WorkOrderDisplay.externalReference') }}
            <div class="small text-muted">
              {{ $t('views.work-orders.WorkOrderDisplay.externalReferenceSubText') }}
            </div>
          </template>

          <b-form-input
            id="wo-edit-external_reference-input"
            v-model="form.external_reference"
            :disabled="loading"
            trim
            @input="onFieldUpdate('external_reference', $event)"
          />
        </b-form-group>

        <AttachmentList v-model="attachments" :can-delete-existing="false"/>
      </template>
    </template>
  </div>
</template>

<script>
import axios from 'axios';
import Card from "primevue/card";
import {
  BAlert,
  BButton,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormCheckbox,
  BFormTextarea,
  BFormRadioGroup,
  BSpinner,
} from 'bootstrap-vue';
import SpinButton from '../../ux/SpinButton.vue';
import { required, requiredIf } from '@vuelidate/validators';
import { mapGetters, mapState } from 'vuex';
import AttachmentList from '@/components/attachment/AttachmentList.vue';
import ServiceSelector from '@/ux/form/ServiceSelector.vue';
import Required from '@/ux/form/Required.vue';
import TreeField from '@/ux/form/TreeField.vue';
import WorkOrderPriorityAPI from '@/services/WorkOrderPriorityAPI.js';
import types from '@/store/types/workOrderAvailableServices.js';
import MaintainableEntityAPI from '@/services/MaintainableEntityAPI.js';
import AssetSelector from '@/components/workOrder/assetSelector/AssetSelector.vue';
import flattenTree from '@/util/flattenTree.js';
import DuplicateWorkOrderList from '@/components/workOrder/duplicate/DuplicateWorkOrderList.vue';
import WorkOrderCreateEmergency from '@/components/workOrder/WorkOrderCreateEmergency.vue';
import IndexAPI from '@/services/v2/IndexAPI.js';
import IndexConfig from '@/services/v2/IndexConfig.js';
import GetAPI from '@/services/v2/GetAPI.js';
import GetConfig from '@/services/v2/GetConfig.js';
import useVuelidate from '@vuelidate/core';
import CustomDropdown from '@/ux/CustomDropdown.vue';
import {useAssetStore} from '@/stores/asset';
import {mapState as mapPiniaState} from "pinia";
import organisation from '../../store/types/organisation';

export default {
  name: 'WorkOrderEdit',
  setup() {
    return { v$: useVuelidate() };
  },
  components: {
    WorkOrderCreateEmergency,
    DuplicateWorkOrderList,
    BAlert,
    BButton,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormCheckbox,
    BFormTextarea,
    BFormRadioGroup,
    BSpinner,
    Card,
    AssetSelector,
    AttachmentList,
    TreeField,
    Required,
    CustomDropdown,
    ServiceSelector,
    SpinButton,
  },
  props: {
    workOrder: {
      type: Object,
      default: null,
    },
    phantom: {
      type: Boolean,
      default: false,
    },
    status: {
      type: Object,
      default: null,
    },
    errors: {
      type: Array,
      default: () => [],
    },
  },
  validations() {
    const validations = {
      form: {
        work_order_priority_id: this.$featureFlag.WORK_ORDER_PRIORITY_FRONTEND ? { required } : {},
        site_id: {},
        service_type_id: {
          required,
        },
        work_order_request_type_id: {
          required,
        },
        sla_type_id: {
          required: requiredIf((f) => !this.form.emergency && this.availableServices.length),
        },
        requested_service_id:
            !this.form.emergency &&
            this.form.sla_type_id &&
            this.form.sla_type_id !== 'NONE'
        ? { required }
        : {},
        primary_location_id: {
          required,
        },
        asset_ids: {},
        summary: {
          required,
        },
        description: {
          required,
        },
        emergency: {
          valid: (v) => !v || this.emergencyCreationPermitted,
        },
        point_of_contact: {},
        external_reference: {},
      },
    };
    if (this.mandatoryPointOfContact) {
      validations.form.point_of_contact = { required };
    }
    if (this.canEditReportingUser) {
      validations.form.reporting_user_id = { required };
    }

    return validations;
  },
  data() {
    return {
      sites: [],
      site: null,
      formPopulate: false,

      form: {},
      attachments: [],
      locationRoot: null,
      workOrderPriorities: [],
      workOrderRequestTypes: [],
      servicePriorities: [],
      reporterUsers: [],
      maintainableEntitiesTree: [],

      availableServices: [],
      linkTypeOptions: [
        {
          text: this.$t('views.work-orders.LinkedWorkOrderTypes.recommendedWorks'),
          value: 'NON_BLOCKING',
        },
        {
          text: this.$t('views.work-orders.LinkedWorkOrderTypes.requiredWorks'),
          value: 'BLOCKING',
        },
      ],
      // set to true to disable the field being 'touched' (i.e. validation being triggered)
      // when an update is made. used to avoid it when the form is reset
      disableFieldTouch: false,

      localErrors: [],

      emergencyCreationPermitted: true,
      emergencyFormValidity: true,
      serviceTypeFullName: null,
      suggestionGenerating: false,
      suggestionGenerated: false,
      suggestions: [],
      suggestionsRequestId: null,
      suggestionHelpfulSubmitted: false,
    };
  },
  computed: {
    ...mapState({
      serviceTypes: (state) => state.serviceType.serviceTypes,
      userId: (state) => state.authorization.user_id,
    }),
    ...mapGetters(['hasPermission']),
    allErrors() {
      return [...this.errors, ...this.localErrors];
    },
    ...mapPiniaState(useAssetStore, {
      storeAssets: 'assets',
    }),
    validity() {
      return !this.v$.$invalid && (!this.form.emergency || this.emergencyFormValidity);
    },
    canEditReportingUser() {
      return this.hasPermission('WorkOrder_Manage');
    },
    loading() {
      return (
        this.$enums.API_STATUS.SAVING.eql(this.status) ||
        this.$enums.API_STATUS.DELETING.eql(this.status)
      );
    },
    workOrderTypeId() {
      const woRequestTypeId = this.form.work_order_request_type_id;
      const woRequestType = this.workOrderRequestTypes.find((t) => t.id === woRequestTypeId);
      return woRequestType ? woRequestType.work_order_type_id : null;
    },
    showAvailableServices() {
      const siteId = this.v$.form.site_id.$model;
      // eslint-disable-next-line max-len
      const serviceTypeId = !this.v$.form.service_type_id.$invalid;
      // eslint-disable-next-line max-len
      const woTypeId = !this.v$.form.work_order_request_type_id.$invalid;

      return siteId && serviceTypeId && woTypeId;
    },
    flatTree() {
      return [...flattenTree(this.maintainableEntitiesTree)];
    },
    mandatoryPointOfContact() {
      return (
        window.appConfig?.WORK_ORDER_REQUIRE_POINT_OF_CONTACT &&
        (window.appConfig.WORK_ORDER_REQUIRE_POINT_OF_CONTACT.value === null
          ? window.appConfig.WORK_ORDER_REQUIRE_POINT_OF_CONTACT.default_value
          : window.appConfig.WORK_ORDER_REQUIRE_POINT_OF_CONTACT.value)
      );
    },
    effectivePrimaryLocationId() {
      return this.form.primary_location_id === -1
        ? this.form.site_id
        : this.form.primary_location_id;
    },
    selectedRequestType() {
      return this.workOrderRequestTypes.find(
        (wort) => wort.id === this.form.work_order_request_type_id,
      );
    },

    selectedSiteName() {
      return this.site?.name;
    },
    selectedAssets() {
      return this.storeAssets.filter((asset) => this.form.asset_ids.includes(asset.id)).map((asset) => asset.assetModel.name);
    },
  },
  watch: {
    workOrder() {
      this.createFormData();
    },
    attachments() {
      this.onFieldUpdate(
        'files',
        this.attachments.filter((f) => !f.id),
      );
    },
    // eslint-disable-next-line object-shorthand
    async 'v$.form.site_id.$model'(siteId) {
      // Don't do anything if you've not selected a site
      if (!siteId) {
        return;
      }

      await this.loadSite(siteId);

      // if we're creating a child work order we don't need to load the
      // location since they're already set for us based on the parent
      if (!this.workOrder.parent_work_order_id) {
        await this.loadLocations();
      }

      await this.loadAssets(siteId);
      await this.loadAssetTree();
      // reset the service_id value if we change Site
      await this.loadAvailableServices();

      if (!this.formPopulate) {
        // check if the provided location id is in the list of locations
        const validPrimaryLocationId = flattenTree(this.locationRoot.children).some(
          (location) => location.id === this.form.primary_location_id
        );

        // if it isn't, reset it to null
        if (!validPrimaryLocationId) {
          this.form.primary_location_id = this.locationRoot.children.length ? null : siteId;
          this.$emit('input', 'primary_location_id', this.form.primary_location_id);
        }
      }
    },
    // eslint-disable-next-line object-shorthand
    'v$.form.service_type_id.$model'() {
      const serviceTypeId = this.form.service_type_id;

      // reset the work_order_request_type_id value if we change Service Type
      this.v$.form.work_order_request_type_id.$model =
        this.workOrder?.work_order_request_type_id ?? null;
      this.v$.form.work_order_request_type_id.$reset();

      if (serviceTypeId) {
        this.loadWorkOrderRequestTypes(serviceTypeId);
        return;
      }

      this.workOrderRequestTypes = [];
    },
    // eslint-disable-next-line object-shorthand
    'v$.form.work_order_request_type_id.$model'() {
      // reset the service_id value if we change Work Order Type
      this.loadAvailableServices();
      this.v$.form.requested_service_id.$model = null;
      this.v$.form.requested_service_id.$reset();
      this.v$.form.sla_type_id.$model = null;
      this.v$.form.sla_type_id.$reset();
    },
    validity() {
      this.$emit('formvalidate', this.validity);
    },
  },
  created() {
    this.loadLookups();
  },
  mounted() {
    this.createFormData();
    this.$emit('formvalidate', this.validity);
  },
  methods: {
    async createFormData() {
      this.formPopulate = true;

      // we don't want updates to trigger a field touch (i.e. show validation)
      this.disableFieldTouch = true;

      const workOrder = { ...(this.workOrder || {}) };

      if (!this.canEditReportingUser && workOrder.reporting_user_id) {
        delete workOrder.reporting_user_id;
      } else if (this.canEditReportingUser && this.phantom) {
        workOrder.reporting_user_id = this.userId;
      }

      if (this.sites.length === 1) {
        workOrder.site_id = this.sites[0].id;
      }

      this.form = workOrder;

      await this.loadLocations();

      if (this.sites.length === 1 && !workOrder.primary_location_id && this.locationRoot && this.locationRoot.children.length === 0) {
        this.onFieldUpdate('primary_location_id', {
          id: this.sites[0].id,
        });
      }

      await this.$nextTick();

      // re-enable field touch
      this.disableFieldTouch = false;

      // reset any field validation
      this.v$.form.$reset();

      this.formPopulate = false;
    },
    onFieldUpdate(field, value) {
      // touch the field so it is marked dirty (only if not disabled)
      if (this.v$.form[field] && !this.disableFieldTouch) {
        this.v$.form[field].$touch();
      }

      if (value && field === 'primary_location_id') {
        // eslint-disable-next-line no-param-reassign
        value = value === -1 ? this.v$.form.site_id.$model : value;
      } else {
        this.form[field] = value;
      }

      this.$emit('input', field, value);
    },
    updateDefaultAttachments(value) {
      this.onFieldUpdate('work_order_request_type_id', value);
      const manuallyAddedAttachments = this.attachments.filter((f) => f instanceof File);
      this.attachments = (this.selectedRequestType?.media ?? []).concat(manuallyAddedAttachments);
    },
    async loadLookups() {
      try {
        await Promise.all([
          this.loadWorkOrderPriorities(),
          this.loadReporterUsers(),
          this.loadSites(),
          this.loadAssets(this.workOrder.site_id),
        ]);
      } catch (e) {
        // eslint-disable no-empty-block
        // todo handle errors: refactor to centralise lookups
      }
    },

    async loadAssets(siteId) {
      if (siteId) {
        await useAssetStore().fetch(
          (new IndexConfig())
            .setFields({
              'assets': ['label', 'assetModel', 'warranty_labour', 'warranty_parts', 'serial_number', 'maintainableEntity', 'assetStatus', 'conditionGrade'],
              'asset-statuses': ['status', 'color'],
              'condition-grades': ['name', 'color'],
              'asset-models': ['name', 'media', 'assetType'],
              'asset-types': ['name', 'assetCategory'],
              'asset-categories': ['name'],
              'media': ['collection_name', 'url', 'file_exists'],
            })
            .setIncludes(['maintainableEntity', 'assetStatus', 'conditionGrade', 'assetModel.assetType.assetCategory', 'assetModel.media'])
            .setFilters({
              'maintainableEntity.site_id': siteId,
              'selectable': true
            })
        );
      }
    },

    async loadSite(id) {
      const response = await GetAPI.get('sites',
          (new GetConfig(id))
              .setFields({
                sites: ['Organisation', 'name', 'enabled'],
                organisations: ['currency'],
                currencies: ['code', 'name'],
              })
              .setIncludes(['Organisation', 'Organisation.currency']),
      );

      this.site = response.data;
    },
    async loadSites() {
      const response = await IndexAPI.fetch('sites',
          (new IndexConfig())
            .setFields({
              sites: ['name'],
            })
            .setFilters({
              'MaintainableEntity.enabled': true,
            }),
      );

      this.sites = response.data;
    },
    async loadWorkOrderRequestTypes(serviceTypeId) {
      const response = await IndexAPI.fetch('work-order-request-types',
        (new IndexConfig())
          .setSort('name')
          .setFilters({
            service_type_id: serviceTypeId,
            enabled: true,
            recurring: 0,
          })
          .setFields({
            'work-order-request-types': ['name', 'work_order_type_id', 'emergency', 'media'],
            'media': [
                'file_name',
                'conversions',
                'created_at',
                'collection_name',
                'url',
                'file_exists',
                'size',
                'mime_type'
              ],
          }).setIncludes([
            'media'
          ]),
      );

      this.workOrderRequestTypes = response.data;
    },
    async loadWorkOrderPriorities() {
      this.workOrderPriorities = (await WorkOrderPriorityAPI.getWorkOrderPriorities()).data;
    },
    async loadReporterUsers() {
      if (this.canEditReportingUser) {
        const response = await IndexAPI.fetch('users',
          (new IndexConfig())
            .setSort('name')
            .setFilters({
              user_type: 'ADMIN',
              enabled: true,
            })
            .setFields({
              users: ['name'],
            }),
        );

        this.reporterUsers = response.data;
      }
    },
    async loadAvailableServices() {
      const siteId = this.form.site_id;
      const serviceTypeId = this.form.service_type_id;

      if (this.showAvailableServices) {
        this.availableServices = await this.$store.dispatch(
          types.actions.GET_WORK_ORDER_AVAILABLE_SERVICES,
          {
            page: 'all',
            sort: '',
            direction: '',
            filter: {
              site_id: siteId,
              service_type_id: serviceTypeId,
              work_order_type_id: this.workOrderTypeId,
            },
          },
        );
      } else {
        this.availableServices = [];
      }
    },
    async loadLocations() {
      if (this.v$.form.site_id.$model) {
        this.locationRoot = (
          await MaintainableEntityAPI.getMaintainableEntitiesTree({
            ancestor_id: this.v$.form.site_id.$model,
            deepest_type: 'SPACE',
          })
        )?.data?.[0];
      } else {
        this.locationRoot = null;
      }
    },
    async loadAssetTree() {
      if (this.v$.form.site_id.$model) {
        try {
          const data = await MaintainableEntityAPI.getMaintainableEntitiesTree({
            ancestor_id: this.v$.form.site_id.$model,
          });

          this.maintainableEntitiesTree = data?.data;
        } catch (error) {
          let message = this.$t('services.genericErrors.read', {
            type: this.$t('services.entities.maintainableEntity'),
          });

          if (error.response && error.response.data && error.response.data.message) {
            message = error.response.data?.message;
          } else {
            message = error.message || message;
          }

          this.localErrors = Array.isArray(message) ? message : [message];
        }
      }
    },
    compareTreeNodes(n, o) {
      const newId = Number.isInteger(n) ? n : n && n.id;
      const oldId = Number.isInteger(o) ? o : o && o.id;

      return newId === oldId;
    },
    async suggestSolution() {
      try {
        this.suggestions = [];
        this.suggestionGenerated = false;
        this.suggestionGenerating = true;

        const response = await axios({
            method: 'POST',
            url: `ai/work_order/suggest_solution`,
            data: {
              summary: this.v$.form.summary.$model,
              description: this.v$.form.description.$model,
              assets: this.selectedAssets,
              service: this.serviceTypeFullName,
            }
        });

        this.suggestions = response.data.response.suggestions ?? [];
        this.suggestionsRequestId = response.data.request_id;
        this.suggestionGenerated = true;
        this.suggestionGenerating = false;
        this.suggestionHelpfulSubmitted = false;
      }
      catch(e) {
        this.suggestionGenerating = false;
        this.suggestionGenerated = true;
      }
    },
    async submitHelpful(helpful)
    {
      await axios({
          method: 'POST',
          url: `ai/helpful`,
          data: {
            request_id: this.suggestionsRequestId,
            helpful: helpful,
          }
      });

      this.suggestionHelpfulSubmitted = true;
    }
  }
};
</script>

<style lang="scss" scoped>
  .suggestion-box {
    background-color: $gray-100;
  }
</style>