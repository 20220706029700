<template>
  <div
    :class="{ 'is-invalid': state === false, 'is-valid': state === true }"
    class="tree-field text-left form-control"
  >
    <span :key="label" class="label">{{ label }}</span>
    <button
      class="d-flex align-items-center"
      :disabled="disabled"
      @click="onFieldClick"
      @blur="onBlur"
    >
      <div class="value-wrap flex-fill text-left">
        <span v-if="displayValue" :key="displayValue" class="value">{{ displayValue }}</span>
        <span v-else :key="emptyText" class="value none">{{ emptyText }}</span>
      </div>
      <font-awesome-icon :icon="['far', 'chevron-right']" size="lg" />
    </button>

    <TreeFieldModal
      ref="modal"
      :value="innerValue"
      :items="items"
      :title="label"
      :parent-select="parentSelect"
      :root-select="rootSelect"
      :children-field="childrenField"
      :root-node-name="rootNodeName"
      :root-node-read-only="rootNodeReadOnly"
      :show-disabled="showDisabled"
      :show-as-side-panel="showAsSidePanel"
      @input="onModalInput"
      @cancel="onEditCancel"
      @hidden="onBlur"
    />
  </div>
</template>

<script>
import { VBModal } from 'bootstrap-vue';
import findNodePath from '@/util/findNodePath.js';
import TreeFieldModal from '@/ux/form/TreeFieldModal.vue';

/**
 * A button styled like a text field that displays the path to a selected tree node.
 * @exports src/ux/form/TreeField
 * @property {object[]} items The tree items.
 * @property {number[]} value An array of ids representing the selected tree nodes.
 * @property {string} valueField The field of the items to use as the value. Defaults to `id`
 * @property {string} displayField The field of the items to use for display
 * @proerty {string} label The label to display above the field
 * @property {string} rootNodeName The name we want to call the root node
 */
export default {
  name: 'TreeField',
  components: {
    TreeFieldModal,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    // eslint-disable-next-line vue/require-prop-types
    value: {
      type: [Number, String, Object],
      default: () => null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
    displayField: {
      type: String,
      default: () => 'name',
    },
    label: {
      type: String,
      default: () => '',
    },
    emptyText: {
      type: String,
      default: () => '',
    },
    parentSelect: {
      type: Boolean,
      default: true,
    },
    rootSelect: {
      type: Boolean,
      default: true,
    },
    childrenField: {
      type: [String, Function],
      default: () => 'children',
    },
    compareNodes: {
      type: Function,
      default: (n, o) => (o && o.id) === (n && n.id),
    },
    state: {
      type: Boolean,
      default: () => null,
    },
    rootNodeName: {
      type: String,
      default: 'Root',
    },
    rootNodeReadOnly: {
      type: Boolean,
      default: false,
    },
    showDisabled: {
      type: Boolean,
      default: false,
    },
    emitFullValue: {
      type: Boolean,
      default: false
    },
    showAsSidePanel: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // mirrors the `value` prop and is used internally.
      innerValue: null,
    };
  },
  computed: {
    // this is a pseudo root node so we can act on the
    // items collection like a tree consistently.
    rootNode() {
      return {
        isRoot: true,
        id: -1,
        name: this.rootNodeName,
        children: this.items,
      };
    },
    displayValue() {
      // find the path to the node for each value
      const path = findNodePath(this.value, this.rootNode, {
        valueField: this.compareNodes || 'id',
        childrenField: this.childrenField || 'children',
      });
      return path ? path.map((n) => n[this.displayField]).join(' / ') : '';
    },
  },
  watch: {
    // if the `value` prop changes we sync the `innerValue`
    value() {
      this.cloneValue();
    },
    displayValue() {
      this.$emit('inputFullName', this.displayValue);
    }
  },
  created() {
    this.cloneValue();
  },
  methods: {
    cloneValue() {
      let val = null;

      if (Number.isInteger(this.value)) {
        val = this.value;
      } else if (this.value) {
        val = { ...this.value };
      }

      this.innerValue = val;
    },
    onFieldClick() {
      this.showModal();
    },
    onEditCancel() {
      this.$emit('cancel');
    },
    onModalInput(val) {
      if (this.emitFullValue) {
        this.$emit('input', val);
      } else {
        this.$emit('input', Number.isInteger(val) ? val : val?.id);
      }

      this.hideModal();
    },
    showModal() {
      this.$refs.modal.show();
    },
    hideModal() {
      this.$refs.modal.hide();
    },
    onBlur() {
      this.$emit('blur');
    },
  },
};
</script>

<style lang="scss" scoped>
.tree-field {
  padding: 0;

  &.is-invalid,
  &.is-valid {
    background-position: right calc(1.75rem) center !important;
  }

  button {
    width: 100%;
    background: none;
    border: none;
    height: 100%;
    padding: 0.375rem 0.75rem;
    color: #495057;
  }

  .value-wrap {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-right: 1rem;

    .none {
      color: #777;
      font-style: italic;
    }
  }
}
</style>
